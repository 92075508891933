<ngb-accordion activeIds="cargo-info">
    <ngb-panel id="cargo-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>IMO FAL 2 - Cargo information</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row" *ngIf="!editMode && ['D','A','W','R'].includes(model.statusId)">
                <div class="col-2">
                    <app-control label='Port of Loading' type="codelist" codelist="Location"
                        [isDisabled]="false" [(ngModel)]="filter.portOfLoadingId" [entity]="filter" property="portOfLoadingId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Port of Discharge' type="codelist" codelist="Location"
                        [isDisabled]="false" [(ngModel)]="filter.portOfDischargeId" [entity]="filter" property="portOfDischargeId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Bill of Lading' [isDisabled]="false" [(ngModel)]="filter.billOfLading" [entity]="filter" property="billOfLading"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Package Type' type="codelist" codelist="PackagingType"
                        [isDisabled]="false" [(ngModel)]="filter.packageTypeId" [entity]="filter" property="packageTypeId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Desciption of Goods' [isDisabled]="false" [(ngModel)]="filter.goodsDescription" [entity]="filter" property="goodsDescription"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Gross Weight (kg)' type="number" [isDisabled]="false" [(ngModel)]="filter.grossWeight" [entity]="filter" property="grossWeight"></app-control>
                </div>
            </div>
            <div class="row mt-3 mb-3" *ngIf="!editMode && ['D','A','W','R'].includes(model.statusId)">
                <div class="col">
                    <div class="float-right">
                        <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
                            <fa-icon class="ml-1" icon="trash"></fa-icon> {{'Clear' | translate}}
                        </button>
                        <button class="btn btn-sm btn-primary" (click)="search()">
                            <fa-icon class="ml-1" icon="search"></fa-icon> {{'Search' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <ngb-accordion activeIds="cargo-totals" *ngIf="!editMode && data?.length > 0">
                <ngb-panel id="cargo-totals">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Cargo Gross Mass Totals</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <kendo-grid [pageable]="false" [data]="cargoData">
                            <ng-template ngFor let-unit [ngForOf]="units">
                                <kendo-grid-column [field]="unit" [title]="unit" *ngIf="!!cargoData[0][unit]"></kendo-grid-column>
                            </ng-template>
                        </kendo-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mt-3 mb-3">
                <span class="h3">{{'Cargo List' | translate}}</span>
            </div>
            <app-entity-form [options]="formOptions" [grid]="cargoGrid"></app-entity-form>
            <hr class="my-3"/>
            <div class="row">
                <div class="col">
                    <app-grid #cargoGrid [data]="editMode ? model.cargo : data">
                        <kendo-grid-column field="sequenceNumber" title="{{'Sequence Number' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="portOfLoadingId" title="{{'Port of Loading' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.portOfLoadingId | codelist:row:'portOfLoadingId'|async}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="portOfDischargeId" title="{{'Port of Discharge' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.portOfDischargeId | codelist:row:'portOfDischargeId'|async}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="billOfLading" title="{{'Bill of Lading' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="transportUnitNumber" title="{{'Marks & Numbers' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="quantity" title="{{'Number of Packages' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="packagingTypeId" title="{{'Type of Packages' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.packagingTypeId | codelist:row:'packagingTypeId'|async}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="description" title="{{'Description' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="grossWeight" title="{{'Gross Mass' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="unitOfMeasureId" title="{{'Unit of Measurement' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.unitOfMeasureId | codelist:row:'unitOfMeasureId'|async}}
                            </ng-template>
                        </kendo-grid-column>
                    </app-grid>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>

