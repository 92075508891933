import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, } from '@angular/router';
import { DialogService } from '@common/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, isObservable, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';

export interface IDeactivableComponent {
    hasChanges(): boolean;
    rejectChanges(): void;
    saveChanges(): Promise<any> | Observable<any> | boolean;
}

// export function canDeactivateViewFactory<T extends IHasChanges>(x: T&Function) {
//     return {
//         name: 'CanDeactivate' + x.name,
//         provider: {
//             provide: 'CanDeactivate' + x.name,
//             useFactory: (dialogService: DialogService, translateService: TranslateService, toastrService) => new CanDeactivateView<T>(dialogService, translateService, toastrService),
//             deps: [DialogService, TranslateService, ToastrService]
//         }
//     };
// }

@Injectable()
export class CanDeactivateView<T extends IDeactivableComponent | AbstractBreezeViewComponent> implements CanDeactivate<T> {
    constructor(private dialogService: DialogService,
        private translateService: TranslateService,
        private toastrService: ToastrService ) { }

    canDeactivate(
        component: T,
    ) {
        return new Promise<boolean>((resolve, reject) => {
            if (!component.hasChanges()) {
                resolve(true);
            } else {
                this.dialogService.changes(this.translateService.instant('Unsaved changes'), this.translateService.instant('There are some unsaved changes. Do you want to save them?'))
                    .then(async (value) => {
                        if (value === true) {
                            let saveResult = component.saveChanges();

                            if (isObservable(saveResult)) saveResult = firstValueFrom(saveResult);

                            try {
                                await saveResult;
                                this.toastrService.success(this.translateService.instant('Save successful'));
                                resolve(true);
                            } catch (error) {
                                this.toastrService.error(this.translateService.instant('An error occurred while saving changes'));
                                resolve(false);
                            }
                        } else {
                            component.rejectChanges();
                            resolve(true);
                        }
                    })
                    .catch(() =>  resolve(false));
            }
        });
    }
}
