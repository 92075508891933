import _ from 'lodash';

export class VesselPermit {
    validFromDate: any;
    validToDate: any;

    getRemainingHours() {
        const currentDate = new Date() as any;
        const validityPeriod = this.roundDateToHours(this.validToDate - this.validFromDate);
        const hoursPassed = this.roundDateToHours(currentDate - this.validFromDate);

        if (currentDate < this.validFromDate) return '/';
        if (currentDate > this.validToDate) return 0;
        return validityPeriod - hoursPassed;
    }

    private roundDateToHours(date) {
        return Math.round(date / 1000 / 60 / 60);
    }
}
