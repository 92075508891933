<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"
            [titleColor]="(isBanned() && mode !== 'create') ? 'orange' : (isDetained() && mode !== 'create') ? 'red' : null"></app-content-header>
        <app-vessel-notification-view-content
            (confirm)="confirm()"
            (tabSelected)="updateTabQueryParameter($event)"
            [initialSelectedTabIndex]="initialSelectedTabIndex"
            [showHeader]="true"
            [entityManager]="entityManager"
            [user]="user"
            [mode]="mode"
            [canEdit]="canEdit()"
            [model]="model"
            [enableSteps]="enableSteps">
        </app-vessel-notification-view-content>
        <kendo-floatingactionbutton
            icon="comment"
            size="large"
            [offset]="{ x: '30px', y: '30px' }"
            positionMode="absolute"
            style="z-index: 100"
            (click)="openChatNotesModal()">
        </kendo-floatingactionbutton>
    </ng-template>
</app-loader>
