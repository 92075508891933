import { environment as base, serverUrl } from './environment.base';
import * as _ from 'lodash';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    production: true,
    name: 'development',
    apiUrl: `${serverUrl}/be/api`
});
