import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getVessel',
    pure: true
})
export class GetVesselPipe implements PipeTransform {

    transform(vtsVesselVisit: any, args?: any): any {
        return vtsVesselVisit.vessel || vtsVesselVisit.vtsVessel || vtsVesselVisit.serviceVessel || vtsVesselVisit.serviceVtsVessel;
    }
}
