import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[appPill]'
})
export class PillDirective implements OnChanges {
    // Colors
    dark =      '#222';
    light =     '#fff';
    green =     '#5DD879';
    darkgreen = '#2DBC4E';
    red =       '#E56B77';
    darkred =   '#D44B59';
    blue =      '#59A9FF';
    orange =    '#FD9A47';
    yellow =    '#FFC107';
    grey =      '#CED4DA';
    purple =    '#BA55D3';

    // Formatting
    formatting = {
        maxWidth: '10.5em',
        maxHeight: '1.45em',
        lineHeight: '1.45em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        padding: '0 0.5em 0 0.5em',
        borderRadius: '4px',
        color: this.dark,
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'none'
    };

    // Status, module & muted input
    @Input() appPill: any;

    el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;

        // Apply formatting
        for (const prop in this.formatting) {
            if (Object.prototype.hasOwnProperty.call(this.el.nativeElement.style, prop))
                this.el.nativeElement.style[prop] = this.formatting[prop];
        }
    }

    ngOnChanges() {
        this.el.nativeElement.style.backgroundColor = this.pillColor(this.appPill);

        if (this.appPill.muted)
            this.el.nativeElement.style.cursor = 'default';
    }

    @HostListener('mouseenter') onMouseEnter(): void {
        if (!this.appPill.muted) this.textColor(this.light);
    }

    @HostListener('mouseleave') onMouseELeave(): void {
        this.textColor(this.dark);
    }

    private textColor(color: string): void {
        this.el.nativeElement.style.color = color;
    }

    private pillColor(input: any): string {
        const status = input.status;
        const module = input.module;

        if (module === 'vesselNotification') {
            switch (status) {
                case 'AR': case 'DP':
                    return this.purple;
                case 'R': case 'Z':
                    return this.red;
                case 'A':
                    return this.green;
                case 'U':
                    return this.blue;
                case 'W':
                    return this.yellow;
                case 'X':
                    return this.orange;
            }
        }

        if (module === 'vesselShift') {
            switch (status) {
                case 'X': case 'R':
                    return this.red;
                case 'S':
                    return this.purple;
                case 'A':
                    return this.green;
                case 'W':
                    return this.yellow;
                case 'C':
                    return this.orange;
                case 'U':
                    return this.blue;
            }
        }

        if (module === 'vesselVisit') {
            switch (status) {
                case 'AR': case 'DE':
                    return this.purple;
                case 'EX':
                    return this.green;
                case 'WA':
                    return this.yellow;
                case 'CA':
                    return this.orange;
                case 'ZX':
                    return this.red;
            }
        }

        if (module === 'vesselPermit') {
            switch (status) {
                case 'WAP': case 'WEX':
                    return this.yellow;
                case 'DIS': case 'REJ':
                    return this.red;
                case 'REV':
                    return this.darkred;
                case 'APP':
                    return this.green;
                case 'AMN':
                    return this.blue;
                case 'COM':
                    return this.purple;
                case 'CAN':
                    return this.orange;
            }
        }

        if (module === 'vesselConveyance') {
            switch (status) {
                case 'R': case 'X':
                    return this.red;
                case 'A':
                    return this.green;
                case 'C':
                    return this.purple;
                case 'CN':
                    return this.orange;
                case 'W':
                    return this.yellow;
                case 'U':
                    return this.blue;
            }
        }

        if (module === 'vtsVesselVisit') {
            switch (status) {
                case 'EXP':
                    return this.yellow;
                case 'ARR':
                    return this.darkgreen;
                case 'DEP':
                    return this.purple;
            }
        }

        return this.grey;
    }
}
