<app-grid [data]="query" [selectable]="{enabled: true, checkboxOnly: true }" [selection]="selection"
    [sortable]="true" [sort]="[{ field: 'id', dir: 'asc' }]">
    <kendo-grid-checkbox-column width="45px" [showSelectAll]="true"></kendo-grid-checkbox-column>
    <kendo-grid-column [width]="160" field="id" title="{{'Permit Number' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-permit/view', row.id]">
                <div [appPill]="{ status: row.statusId, module: 'vesselPermit' }"> {{row.referenceNumber}} </div>
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vessel.name" title="{{'Vessel Name' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="isRestricted(row.vessel.statusId)" class="badge box-shadow tight" style="font-size: 16px;"
                ngbTooltip="{{isRestricted(row.vessel.statusId, true)}}" placement="right">
                <fa-icon icon="lock" class="redIcon"></fa-icon>
            </div>
            <a [routerLink]="['/vessels/view', row.vesselId]" class="text-primary" target="_blank">
                {{row.vesselId | codelist:row:'vesselId'|async}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="type.name" title="{{'Permit Type' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.typeId | codelist:row:'typeId'|async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="145" field="status.name" title="{{'Status' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <div [appPill]="{ status: row.statusId, module: 'vesselPermit', muted: true }">
                {{row.statusId | codelist:row:'statusId'|async}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vesselNotification.vesselVisit.number" title="{{'Vessel Visit' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-visit/view', row.vesselNotification?.vesselVisitId]" class="text-primary"
                target="_blank">
                {{row.vesselNotification?.vesselVisit?.yearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="berth.name" title="{{'Location' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.berthId | codelist:row:'berthId'|async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="createdDate" title="{{'Created Date' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.createdDate | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="approvalDate" title="{{'Approved Date' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.approvalDate | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="validFromDate" title="{{'Validity from' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.validFromDate | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="validToDate" title="{{'Validity to' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.validToDate | date:'short'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="requestingOrganization.name" title="{{'Agent' | translate}}">
    </kendo-grid-column>
    <kendo-grid-column [width]="40" class="text-center" title="{{'TM' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="" moduleType="VesselPermit"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
</app-grid>