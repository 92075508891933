<ngb-accordion activeIds="pilot-info">
    <ngb-panel id="pilot-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>Pilot Information</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-3">
                    <app-control label='Order pilot?' type="yesno"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.orderPilot" [entity]="model" property="orderPilot"></app-control>
                </div>
            </div>
            <ngb-accordion activeIds="pilot-list" *ngIf="model.orderPilot">
                <ngb-panel id="pilot-list">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>Pilot List</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="formOptions" [grid]="pilotGrid"></app-entity-form>
                        <app-grid #pilotGrid [data]="model.pilots">
                            <kendo-grid-column title="{{'Pilot Organization' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.pilotOrganizationId | codelist:'PilotOrganization' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Pilot Boat' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.pilotBoatId | codelist:'PilotBoat' | async}}
                                </ng-template>
                            </kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
