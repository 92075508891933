import { Injectable } from '@angular/core';
import { EntityManager } from '@cime/breeze-client';
import { BreezeService } from '@common/services/breeze.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class GridEditService {
    editedRowIndex = null;
    editedRow = null;
    entityName: string;
    entityManager: EntityManager;

    constructor(private breeze: BreezeService,
        private translateService: TranslateService,
        private toastrService: ToastrService) { }

    saveHandler({ sender, rowIndex }) {
        this.closeEditor(sender, rowIndex);
    }

    cancelHandler({ sender, rowIndex }) {
        const row = sender.data.data[rowIndex];
        if (row.id > 0) {
            const entity = this.entityManager.metadataStore.getEntityType(this.entityName);
            entity.getPropertyNames().forEach(prop => {
                if (typeof sender.data.data[rowIndex][prop] !== 'object' && sender.data.data[rowIndex][prop] !== null)
                    row[prop] = this.editedRow[prop];
            });
            row.entityAspect.setUnchanged();
        } else {
            this.removeHandler({ sender, rowIndex }, true);
        }
        this.closeEditor(sender, rowIndex);
    }

    editHandler({ sender, rowIndex, dataItem }, entityName) {
        this.entityName = entityName;
        this.closeEditor(sender);
        this.editedRowIndex = rowIndex;
        this.editedRow = this.breeze.convertToDto(dataItem);
        sender.editRow(rowIndex);
        sender.expandRow(rowIndex);
    }

    addHandler({ sender }, gridData, entityName) {
        this.entityName = entityName;
        this.closeEditor(sender);
        gridData.push(this.entityManager.createEntity(entityName));
        const rowIndex = gridData.length - 1;
        this.editedRowIndex = rowIndex;
        sender.editRow(rowIndex);
        sender.expandRow(rowIndex);
    }

    removeHandler({ sender, rowIndex }, override = false) {
        if (this.editedRowIndex === null || override) {
            const item = sender.data.data[rowIndex];
            if (item.id > 0) item.entityAspect.setDeleted();
            else item.entityAspect.setDetached();
        } else {
            this.toastrService.error(this.translateService.instant('You must finish editing your current row first'));
        }
    }

    closeEditor(sender, rowIndex = this.editedRowIndex) {
        sender.collapseRow(rowIndex);
        sender.closeRow(rowIndex);
        const row = sender.data.data[rowIndex];
        if (row?.id < 0 && !this.hasAnyValues(row))
            this.removeHandler({ sender, rowIndex }, true);
        this.editedRowIndex = null;
    }

    expandAllDetails(sender) {
        sender.data.data.forEach((item, index) => sender.expandRow(index));
    }

    collapseAllDetails(sender) {
        sender.data.data.forEach((item, index) => sender.collapseRow(index));
    }

    defaultDisplayValue(cellValue, type, wasteToBeDelivered): string {
        cellValue ||= wasteToBeDelivered === 'NONE' ? cellValue : type === 'number' ? 0 : '-';
        return cellValue;
    }

    hasAnyValues(row): boolean {
        const entity = this.entityManager.metadataStore.getEntityType(this.entityName);
        return entity.getPropertyNames().filter(p => p !== 'id' && !p.includes('vesselNotification'))
            .some(prop => row[prop]?.hasOwnProperty('length') ? row[prop].length > 0 : !!row[prop]);
    }
}
