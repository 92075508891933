import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
    environment = environment;
    constructor() { }
}
