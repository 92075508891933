import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DataProperty, DataType, EntityManager, EntityType } from '@cime/breeze-client';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { EntityFormOptions, EntityProperty } from '@common/classes/entity-form';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { BreezeService } from '@common/services/breeze.service';
import _ from 'lodash';

export const EmptyEntityProperty = {
    name: null,
    label: null
};

@Component({
    selector: 'app-entity-form',
    templateUrl: './entity-form.component.html',
    styleUrls: ['./entity-form.component.scss']
})
export class EntityFormComponent implements AfterViewInit, OnChanges {
    private localEntityManager: EntityManager;
    private propertyNames: string[];
    private validatePropertyNames: string[];
    private entityName: string;
    private entityType: EntityType;

    public viewMode: ViewMode;
    public parentViewMode: ViewMode; // view mode from parent component

    model: BreezeEntity;
    originalModel: BreezeEntity;

    @Input() options: EntityFormOptions;
    @Input() grid: AppGridComponent;
    @Input() hasButtons = true;

    @ViewChild('removeColumn', { static: false }) removeColumn;
    @ViewChild('editColumn', { static: false }) editColumn;

    constructor(private breezeService: BreezeService,
        private breezeViewService: BreezeViewService,
        private cdRef: ChangeDetectorRef) { }

    confirm() {
        this.model.entityAspect.validateEntity();
        if (_.filter(this.model.entityAspect.getValidationErrors(),
            o => this.validatePropertyNames.includes(o.propertyName)).length > 0) {
            return false;
        }

        if (this.viewMode === ViewMode.create) this.add();
        else this.update();

        this.options.onConfirmed?.();
    }

    add() {
        const data = this.getData(this.model);
        this.options.beforeAdd?.(data);

        const entity = this.grid.entityManager.createEntity(this.entityName, data);
        if (!this.options.getCollection) (<any[]>this.grid.data).push(entity);
        else this.options.getCollection().push(entity);

        this.options.onAdd?.(entity);
        this.clear();
    }

    update() {
        _.each(this.propertyNames, propName => {
            const value = this.model[propName];
            // Global workaround for attachments
            if (value?.entityType) {
                if (value.entityType.shortName === 'Attachment') {
                    this.originalModel[propName] = this.grid.entityManager.createEntity('Attachment', this.model[propName]);
                } else {
                    const originalValue = this.originalModel[propName];
                    _.each(value.entityType.dataProperties, (subProp: DataProperty) => {
                        if (subProp.isPartOfKey) return;

                        originalValue[subProp.name] = value[subProp.name];
                    });
                }
            } else {
                this.originalModel[propName] = value;
            }
        });

        this.options.onUpdate?.(this.originalModel);
        this.clear();
    }

    cancel() {
        this.clear();
        this.options.onCancel?.();
    }

    clear() {
        // Detach the entity and create a new one instead of setting the values to null,
        // in order to prevent validation errors from showing
        this.localEntityManager.clear();
        this.viewMode = null;
        this.originalModel = null;
        this.model = null;
        // In case parent entity has OnPush change detection strategy
        this.cdRef.detectChanges();
    }

    create() {
        this.setupModel();
        this.viewMode = ViewMode.create;
    }

    canEdit() {
        return this.options.canEdit?.() !== false && this.parentViewMode !== 'view';
    }

    canAdd() {
        return this.options.canAdd?.() !== false && this.parentViewMode !== 'view';
    }

    canRemove() {
        return this.options.canRemove?.() !== false && this.parentViewMode !== 'view';
    }

    edit(entity: BreezeEntity) {
        this.setupModel();
        _.each(this.propertyNames, propName => {
            let value = entity[propName];
            // Workaround for attachments
            if (value?.entityType) {
                const data = this.breezeService.convertToDto(value);
                data.id = undefined;
                value = this.localEntityManager.createEntity(value?.entityType.shortName, data);
            }

            this.model[propName] = value;
        });

        this.originalModel = entity;
        this.viewMode = ViewMode.edit;
        this.options.beforeUpdate?.(); // Detain Vessel filter codelist
    }

    remove(entity: BreezeEntity) {
        entity.entityAspect.setDeleted();
        this.options.onRemove?.(entity);
    }

    initialize(): void {
        this.parentViewMode = this.breezeViewService.activatedRoute.snapshot.data.mode;
        this.entityName = this.options.entityName;
        this.propertyNames = _.chain(this.options.propertyGroups).flatten().map(o => o.name).value();
        this.localEntityManager = this.grid.entityManager.createEmptyCopy();
        this.localEntityManager.setProperties({ validationOptions: this.localEntityManager.validationOptions.using({ validateOnAttach: false }) });
        this.entityType = <EntityType>this.localEntityManager.metadataStore.getEntityType(this.entityName);
        this.validatePropertyNames = _.chain(this.propertyNames)
            .map(o => {
                const property = this.entityType.getNavigationProperty(o);
                return property ? property.foreignKeyNames[0] : o;
            }).value();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options?.currentValue) this.initialize();
    }

    ngAfterViewInit(): void {
        if (!this.canEdit()) return;

        if (this.hasButtons) {
            this.grid.addColumn(this.editColumn);
            if (this.canRemove()) this.grid.addColumn(this.removeColumn);
        }
    }

    getName(property: EntityProperty) {
        return property.name;
    }

    getDisplayedProperties(propertyGroup: EntityProperty[]) {
        return _.filter(propertyGroup, g => this.isVisible(g));
    }

    isDisabled(property: EntityProperty) {
        return property.isDisabled?.(this.model) === true;
    }

    isVisible(property: EntityProperty) {
        return property.name && property.isVisible?.(this.model) !== false;
    }

    private setupModel() {
        this.model = this.localEntityManager.createEntity(this.entityName, this.getData(null));
    }

    private getData(entity) {
        const data = {};
        _.each(this.propertyNames, propName => {
            if (!entity && this.entityType.getDataProperty(propName)?.dataType === DataType.Boolean) {
                return; // Do not set default value for boolean
            }

            data[propName] = !entity ? null : this.getPropertyValue(entity, propName);
        });

        return data;
    }

    private getPropertyValue(entity, propertyName: string) {
        const value = entity[propertyName];
        return value?.entityAspect
            ? this.breezeService.convertToDto(value) // For attachments
            : value;
    }

    onPropertyChange(property: EntityProperty, value) {
        if (property.onChange) property.onChange(this.model, value);
    }
}
