import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { Component, Input, AfterViewInit, Output, EventEmitter, OnInit } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import _ from 'lodash';
import { DialogService } from '@common/services/dialog.service';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { CodelistService } from '@common/services/codelist.service';
import { User } from '@common/models/User';
import { AppControlType } from '@common/components/app-control/app-control.component';

@Component({
    selector: 'app-vessel-notification-cargo',
    templateUrl: './vessel-notification-cargo.component.html',
    styleUrls: ['./vessel-notification-cargo.component.scss']
})
export class VesselNotificationCargoComponent extends AbstractBreezeListComponent implements AfterViewInit, OnInit {
    queryName = 'VesselNotificationCargo';
    persistFilters = false;
    data = [];
    units = [];
    cargoData = [];

    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() canOpenModal: boolean;
    @Input() editMode: any;
    @Input() user: User;

    @Output() public modalClose = new EventEmitter<any>();

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationCargo',
        getCollection: () => this.model.cargo,
        canEdit: () => this.canEdit,
        beforeAdd: (data) => {
            data.ownerId = 1; // TODO: Remove when update to new breeze
            data.sequenceNumber = this.model.cargo.length > 0
                ? this.model.cargo[this.model.cargo.length - 1].sequenceNumber + 1
                : 1;
        },
        onAdd: () => this.model.entityAspect.validateEntity(),
        onRemove: (item) => {
            this.model.entityAspect.validateEntity();
            if (item.sequenceNumber <= this.model.cargo.length)
                this.updateSequenceNumber(this.model.cargo);
        },
        propertyGroups: [
            [
                { name: 'portOfLoadingId', label: 'Port of Loading', type: AppControlType.CodeList, codelist: 'Location', colSize: 2 },
                { name: 'portOfDischargeId', label: 'Port of Discharge', type: AppControlType.CodeList, codelist: 'Location', colSize: 2 },
                { name: 'billOfLading', label: 'Bill Of Lading', maxlength: 250, colSize: 2 },
                { name: 'transportUnitNumber', label: 'Marks & Numbers', maxlength: 100, colSize: 2 },
                { name: 'quantity', label: 'Number of Packages', type: AppControlType.Number, decimals: 2, colSize: 2 },
            ],
            [
                { name: 'packagingTypeId', label: 'Type of Packages', type: AppControlType.CodeList, codelist: 'PackagingType', colSize: 3 },
                { name: 'description', label: 'Description of Goods', maxlength: 250, colSize: 6 },
                { name: 'grossWeight', label: 'Gross Mass', type: AppControlType.Number, decimals: 2, colSize: 2 },
                { name: 'unitOfMeasureId', label: 'Unit of Measurement', type: AppControlType.CodeList, codelist: 'UnitOfMeasure', colSize: 3 }
            ]
        ]
    };

    constructor(private dialogService: DialogService,
        private codelistService: CodelistService,
        breezeViewService: BreezeViewService) {
        super(breezeViewService);
    }

    ngAfterViewInit(): void {
        if (!this.canOpenModal) return;
        setTimeout(async () => {
            const result = await this.dialogService.yesNo(this.translateService.instant('Cargo'), this.translateService.instant('Does vessel have any cargo on board?'));
            this.modalClose.emit(result);
        });
    }

    async ngOnInit() {
        super.ngOnInit();

        const units = await this.codelistService.getCodelist({ name: 'UnitOfMeasure', useCache: false });
        this.units = units.map(u => u.id);
    }

    override search() {
        const query = this.getQuery();
        this.entityManager.executeQuery(query).then(data => {
            this.data = data.results;
            this.cargoData = [_.chain(this.data)
                .groupBy('unitOfMeasureId')
                .map((value, key) => ({
                    key,
                    value: _.sumBy(value, 'grossWeight')
                }))
                .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
                .value()];
        });
        return query;
    }

    private updateSequenceNumber(items) {
        items.forEach((p: any, i) => p.sequenceNumber = i + 1);
    }

    override getDefaultFilter() {
        return {
            id: this.model?.id
        };
    }
}
