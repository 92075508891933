import { Component } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { VesselShiftPermissions } from '@common/classes/permissions';
import { EntityManager, EntityQuery, EntityState, SaveResult } from '@cime/breeze-client';
import { DialogService } from '@common/services/dialog.service';
import { ViewMode } from '@common/models/view-mode';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { BreezeEntity } from '@common/classes/breeze-entity';

@Component({
    selector: 'app-vessel-shift-view',
    templateUrl: './vessel-shift-view.component.html',
    styleUrls: ['./vessel-shift-view.component.scss'],
    providers: [BreezeViewService]
})
export class VesselShiftViewComponent extends AbstractBreezeViewComponent {
    entityName = 'VesselShift';

    vesselNotificationId: number;
    parentVesselShiftId: number;
    cloneId: number;
    vesselShifts: any;

    breadcrumb = [
        {
            icon: 'file',
            title: 'Vessel Shiftings',
            route: `${this.parentRoute}/list`
        },
        {
            icon: 'file',
            title: 'Vessel Shift'
        }
    ];

    formOptions: EntityFormOptions = {
        entityName: 'VesselShiftAttachment',
        canEdit: () => this.editMode,
        beforeAdd: model => {
            model.createdDate = new Date();
            model.createdById = this.user?.id;
        },
        propertyGroups: [
            [
                { name: 'attachment', label: 'Attachment', type: AppControlType.File, colSize: 4 },
                { name: 'attachmentTypeId', label: 'Attachment Type', type: AppControlType.CodeList, codelist: 'AttachmentType', colSize: 3 },
                { name: 'remarks', label: 'Remarks', type: AppControlType.TextArea, maxlength: 255, colSize: 3 },
                { name: 'private', label: 'Private', type: AppControlType.Boolean }
            ]
        ]
    };

    constructor(breezeViewService: BreezeViewService) {
        super(breezeViewService);
        this.vesselNotificationId = this.breezeViewService.activatedRoute.snapshot.queryParams.vesselNotificationId;
        this.parentVesselShiftId = this.breezeViewService.activatedRoute.snapshot.queryParams?.vesselShiftId;
        this.cloneId = this.breezeViewService.activatedRoute.snapshot.queryParams.cloneId;
        this.editActionBarGroup.items = [
            ...this.editActionBarGroup.items,
            {
                label: 'Send',
                icon: 'share',
                isVisible: () => this.canSend(),
                onClick: () => this.send()
            },
            {
                label: 'Approve',
                icon: 'thumbs-up',
                isVisible: () => this.canApprove(),
                onClick: () => this.approve()
            },
            {
                label: 'Reject',
                icon: 'thumbs-down',
                isVisible: () => this.canReject(),
                onClick: () => this.reject()
            },
            {
                label: 'Cancel shifting',
                icon: 'ban',
                isVisible: () => this.canCancelVesselShift(),
                onClick: () => this.cancelVesselShift()
            },
            {
                label: 'Unconfirm',
                icon: 'times',
                isVisible: () => this.canUnconfirm(),
                onClick: () => this.unconfirm()
            },
            {
                label: 'Clone',
                icon: 'clone',
                isVisible: () => this.canClone(),
                onClick: () => this.clone()
            },
            {
                label: 'Delete',
                icon: 'trash',
                isVisible: () => this.canDiscard(),
                onClick: () => this.discard()
            },
            {
                label: 'Insert ATS',
                icon: 'sign-in-alt',
                isVisible: () => this.canShift(),
                onClick: () => this.shift()
            },
            {
                label: 'Create Shifting',
                icon: 'play',
                isVisible: () => this.canCreateVesselShift(),
                onClick: () => this.createVesselShift()
            }
        ];
    }

    async initialize() {
        if (!this.vesselNotificationId) await super.initialize();
        else {
            const data = await this.executeCommand({
                commandName: 'CreateVesselShift', data: {
                    vesselNotificationId: this.vesselNotificationId,
                    vesselShiftId: this.parentVesselShiftId
                }, tracking: false
            });
            this.model = this.breezeService.importEntityGraph('VesselShift', data.results[0], typeName => typeName === 'VesselShift' ? EntityState.Added : EntityState.Unchanged, this.entityManager)[0];
            this.model.shiftingDate = null;
        }

        const vesselNotification = (await this.breezeViewService.handleQuery('VesselNotification', { id: this.model.vesselNotificationId }))[0];
        this.vesselShifts = vesselNotification.vesselShifts.filter(x => !['C', 'R', 'X'].includes(x.statusId));

        this.isBusy = false;
    }

    override beforeSave() {
        ['hazmat', 'cargoOnBoard', 'wasteToBeDischarged', 'onboardAssistance', 'privateDistribution', 'planned'].forEach(p => {
            if (this.model[p] && !this.model.entityAspect.originalValues[p]) this.model[`${p}SetDate`] = new Date();
        });
    }

    onRequestingAgentChange(value) {
        if (!value) return;

        this.model.requestingAgentId = value;
        this.model.entityAspect.loadNavigationProperty('requestingAgent');
    }

    onContractorAgentChange(value) {
        if (!value) return;

        this.model.contractorAgentId = value;
        this.model.entityAspect.loadNavigationProperty('contractorAgent');
    }

    override canEdit() {
        return (['D', 'W', 'U'].includes(this.model.statusId) ||
            (this.model.statusId === 'A' && (this.user?.isPort() || this.user?.isSystemUser))) &&
            super.canEdit();
    }

    canSend() {
        return this.mode !== ViewMode.create &&
            this.model.statusId === 'D' &&
            this.model.id > 0 &&
            this.user?.hasPermission(VesselShiftPermissions.Action.send);
    }

    async send() {
        const res = await this.dialogService.openConfirmDialog('Shifting Notification',
            this.translateService.instant('You are about to send the shift request to be approved by port authority. Please press confirm to send the request or press Cancel to cancel it.') as string,
            false, 'Cancel', 'Send');
        if (!res) return;

        if (this.editMode && this.hasChanges()) await this.saveChanges({ silent: true, redirectToViewMode: false });

        return this.executeAction('Send');
    }

    canCancelVesselShift() {
        return ['W', 'U', 'A'].includes(this.model.statusId)
            && this.viewMode
            && this.user?.hasPermission(VesselShiftPermissions.Action.cancel)
            && this.model.revisionNumber === this.vesselShifts?.length - 1;
    }

    async cancelVesselShift() {
        await this.executeAction('Cancel', { ids: [this.model.id] });
    }

    canDiscard() {
        return this.model.statusId === 'D'
            && this.viewMode
            && this.user?.hasPermission(VesselShiftPermissions.Action.discard)
            && this.model.revisionNumber === this.vesselShifts?.length - 1;
    }

    async discard() {
        await this.executeAction('Discard', { ids: [this.model.id] });
    }

    canShift() {
        return this.model.statusId === 'A'
            && (this.vesselShifts?.find(v => v.revisionNumber === this.model.revisionNumber - 1)?.statusId === 'S' || this.model.revisionNumber === 0)
            && this.model.vesselNotification.statusId === 'AR'
            && this.viewMode
            && this.user.hasPermission(VesselShiftPermissions.Action.shift);
    }

    async shift() {
        const data = await this.dialogService.form({
            title: 'Insert ATS',
            properties: [
                {
                    name: 'ats',
                    type: AppControlType.DateTime,
                    time: true,
                    label: 'ATS',
                    initialValue: new Date()
                }
            ],
            canConfirm: (model) => model.ats
        }, { size: 'md' });

        if (!data) return;

        data.id = this.model.id;
        await this.executeCommand({ commandName: 'ExecuteVesselShift', data });
        this.router.navigate([`${this.parentRoute}/list/`]);
    }

    canApprove() {
        return this.viewMode &&
            ['W'].includes(this.model.statusId) &&
            this.user?.hasPermission(VesselShiftPermissions.Action.approve) &&
            (this.user?.isPort() || this.user?.isSystemUser);
    }

    async approve() {
        if (!['A', 'AR'].includes(this.model.vesselNotification?.statusId)) {
            return this.dialogService.warning('Cannot approve',
                this.translateService.instant(`The Notification's (${this.model.vesselNotification.yearNumber}) status must first be either: Approved or Arrived.`));
        }

        await this.executeActionWithRemarks('Approve');
    }

    canReject() {
        return this.viewMode &&
            ['W'].includes(this.model.statusId) &&
            this.user?.hasPermission(VesselShiftPermissions.Action.reject) &&
            (this.user?.isPort() || this.user?.isSystemUser);
    }

    async reject() {
        if (!['A', 'AR'].includes(this.model.vesselNotification?.statusId)) {
            return this.dialogService.warning('Cannot reject',
            this.translateService.instant(`The Notification's (${this.model.vesselNotification.yearNumber}) status must first be either: Approved or Arrived.`));
        }

        await this.executeActionWithRemarks('Reject');
    }

    canUnconfirm() {
        return this.viewMode &&
            this.user?.hasPermission(VesselShiftPermissions.Action.unconfirm) &&
            ((this.model.revisionNumber === this.vesselShifts?.length - 1 &&
                ['W'].includes(this.model.statusId)) ||
                ['A', 'R'].includes(this.model.statusId));
    }

    async unconfirm() {
        await this.executeActionWithRemarks('Unconfirm');
    }

    canClone() {
        // Odstranjeno zaradi spremembe logike ustvarjanje shiftov (narejeno veriženje) in ker se noben podatek ne klonira
        // return this.viewMode && !['D', 'C', 'X'].includes(this.model.statusId) && this.user?.hasPermission(VesselShiftPermissions.Action.clone);
        return false;
    }

    clone() {
        this.isBusy = true;
        this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { vesselNotificationId: this.model.vesselNotificationId } });
    }

    canCreateVesselShift() {
        return this.viewMode && ['W', 'A', 'S'].includes(this.model.statusId) && this.user.hasPermission(VesselShiftPermissions.Action.create) && this.model.revisionNumber === this.vesselShifts?.length - 1;
    }

    createVesselShift() {
        this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { vesselNotificationId: this.model.vesselNotificationId, vesselShiftId: this.model.id } });
    }

    onPortOfCallChange($event) {
        // Reset properties because of filter rule
        this.model.berthShiftingToId = null;
    }

    fetchBerths = (filter) => this.breezeViewService.handleQuery('Berths', { filter, portId: this.model.portOfShiftingId })
        .then(this.fetchResultHandler);

    fetchNotifications = (name) => this.breezeViewService.handleQuery('DraftVesselNotifications', { name, isPermit: false })
        .then(this.fetchResultHandler);

    override getIdentifier() {
        return this.model.yearNumber;
    }

    getCheckboxTooltip(propertyName) {
        if (!this.viewMode) return '';

        return (this.model[propertyName] && !!this.model[`${propertyName}SetDate`]) ? this.model[`${propertyName}SetDate`].toDateString() : '';
    }

    isRestricted(statusId: string, showToolTip = false) {
        return showToolTip ? statusId === 'DT' ? 'Detained Vessel' : 'Banned Vessel' : ['DT', 'BN'].includes(statusId);
    }

    async saveChanges(options?: any, entityManager?: EntityManager, entityName?: string, model?: BreezeEntity): Promise<SaveResult> {
        const changes = this.entityManager.getChanges();
        if (changes.some(x => x.entityType.shortName === 'VesselNotification')) {
            const shiftChanges = changes.filter(x => x.entityType.shortName === 'VesselShift');
            const shiftModified = shiftChanges.filter(x => x.entityAspect.entityState === EntityState.Modified);
            const shiftAdded = shiftChanges.filter(x => x.entityAspect.entityState === EntityState.Added);
            const shiftDeleted = shiftChanges.filter(x => x.entityAspect.entityState === EntityState.Deleted);
            const shiftData = shiftChanges.map(x => x.entityAspect.originalValues);
            shiftModified.concat(shiftAdded).concat(shiftDeleted).forEach(x => x.entityAspect.setUnchanged());
            this.model.vesselNotification.entityAspect.setModified();
            await super.saveChanges({ silent: true }, this.model.vesselNotification.entityAspect.entityManager, 'VesselNotification', this.model.vesselNotification);
            shiftModified.forEach((x, i) => x.entityAspect.originalValues = shiftData.at(i));
            shiftModified.forEach(x => x.entityAspect.setModified());
            shiftAdded.forEach(x => x.entityAspect.setAdded());
            shiftDeleted.forEach(x => x.entityAspect.setDeleted());
        }
        return super.saveChanges();
    }

    get canEditTankerInfo() {
        return ['D', 'W'].includes(this.model.statusId) && !this.viewMode;
    }
}
