import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { ViewMode } from '@common/models/view-mode';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import _ from 'lodash';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateDepartureModalComponent } from '../../../vessel-notification/components/create-departure-modal/create-departure-modal.component';
import { DialogService as KendoDialogService } from '@progress/kendo-angular-dialog';
import { BaseVesselNotificationViewComponent } from '@common/classes/base-vessel-notification-view';

@Component({
    selector: 'app-vessel-short-notification-view',
    templateUrl: './vessel-short-notification-view.component.html',
    styleUrls: ['./vessel-short-notification-view.component.scss'],
    providers: [BreezeViewService]
})
export class VesselShortNotificationViewComponent extends BaseVesselNotificationViewComponent {
    entityName = 'VesselNotification';
    createPermission = VesselNotificationPermissions.Action.create;
    editPermission = VesselNotificationPermissions.Action.edit;

    override isShort = true;

    breadcrumb = [
        {
            icon: 'file-alt',
            title: 'Short Vessel Notifications',
            route: `${this.parentRoute}list/`
        },
        {
            icon: 'file-alt',
            title: 'Short Vessel Notification'
        }
    ];

    get title() {
        return this.createMode ? 'New Short Notification' : `Short Notification - ${this.getIdentifier()}`;
    }

    constructor(breezeViewService: BreezeViewService,
        dialogService: DialogService,
        location: Location) {
        super(breezeViewService, dialogService, location);

        this.actionBar.push({
            label: 'Create',
            items: [
                {
                    label: 'Create departure',
                    icon: 'sign-out-alt',
                    isVisible: () => this.canCreateDeparture(),
                    onClick: () => this.createDeparture()
                },
                // {
                //     label: 'Create Vessel Permit',
                //     icon: 'file-alt',
                //     isVisible: () => this.canCreateVesselPermit(),
                //     onClick: () => this.createVesselPermit()
                // }
            ]
        },
        {
            label: 'ATA',
            items: [
                {
                    label: 'Insert ATA',
                    icon: 'sign-in-alt',
                    isVisible: () => this.canArrive(),
                    onClick: () => this.complete('ata')
                },
            ]
        },
        {
            label: 'ATD',
            items: [
                {
                    label: 'Insert ATD',
                    icon: 'sign-out-alt',
                    isVisible: () => this.canDepart(),
                    onClick: () => this.complete('atd')
                },
            ]
        },
        {
            label: 'Upload',
            items: [
                {
                    label: 'Excel upload',
                    icon: 'book',
                    isVisible: () => this.canExcelUpdate(),
                    onClick: () => this.excelUpdate()
                }
            ]
        });
    }

    override getIdentifier() {
        return this.model.yearNumber;
    }

    // Dialog logic in child class
    confirm() {
        this.saveChanges({ redirectToList: true });
    }

    override async initialize() {
        if (!this.cloneId && !this.cloneForDepartureId) {
            await super.initialize();
            return;
        }

        const cloneData = this.cloneData ? JSON.parse(this.cloneData) : null;
        const cloneMethodName = this.cloneId
            ? 'CloneVesselNotification'
            : 'CloneVesselNotificationForDeparture';
        const data = await this.breezeViewService.handleCommand(cloneMethodName, { id: this.cloneId || this.cloneForDepartureId, ...cloneData }, true)
            .catch(e => {
                this.toastrService.error(e?.error?.validationErrors?.[0]?.errorMessage);
                this.location.back();
            });

        if (this.cloneForDepartureId) {
            data.portOfCallEtd = null;

            if (!cloneData.voyage) {
                data.cargoGrossWeight = null;
                data.lastPortEtd = null;
            }
        }
        const entity = data;

        this.model = this.entityManager.createEntity(this.entityName, entity);
        this.mapBreezeCloneProperties();
        this.model.entityAspect.loadNavigationProperty('vessel');
    }

    override canEdit() {
        if (this.model.statusId === 'U') {
            return this.user.hasPermission(this.editPermission) &&
                (this.model.agentId === this.user.organizationId || this.user.isSystemUser);
        }

        if (this.user.isAgent() && this.model.statusId === 'W') return true;

        if (!this.user.hasPermission(VesselNotificationPermissions.Action.editAllStatus) && this.model.statusId !== 'D')
            return false;

        if (['X', 'Z'].includes(this.model.statusId)) return false;

        const vnPermission = _.find(this.model.permissions, o => o.organizationId === this.user.organizationId);
        return vnPermission
            ? _.chain((<BreezeEntity>vnPermission).entityType.dataProperties)
                .filter((p) => p.name.startsWith('edit'))
                .some(p => vnPermission[p.name])
                .value()
            : this.user.hasPermission(this.editPermission);
    }

    canDiscard() {
        return this.viewMode && this.model.statusId === 'D'
            && this.user.hasPermission(VesselNotificationPermissions.Action.discard)
            && (this.user.organizationId === this.model.agentId || (this.user.isPort() || this.user.isSystemUser)); // Only primary agent can discard
    }

    canClone() {
        return this.viewMode && this.model.notificationTypeId !== 'D' &&
            (!this.user.isAgent() || this.user.organizationId === this.model.agentId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.clone);
    }

    send72h() {
        return this.executeCommand({ commandName: 'SendPreArrivalVesselNotification', data: { id: this.model.id } });
    }

    canApprove() {
        return this.viewMode &&
            this.model.statusId === 'W' &&
            (
                this.user.hasPermission(VesselNotificationPermissions.Action.approve) ||
                this.user.hasPermission(VesselNotificationPermissions.Action.approveAll)
            ) &&
            (this.user.isPort() || this.user.isSystemUser); // Začasno za #20073
    }

    disableApproveReject() {
        return (this.user.isPort() && (this.model.portApprovalDate || this.model.portRejectionDate))/* ||Zakomentirano za produkcijske namene (#20073)
            (this.user.isCustoms() && (this.model.customsApprovalDate || this.model.customsRejectionDate)) ||
            (this.user.isPolice() && (this.model.policeApprovalDate || this.model.policeRejectionDate)) ||
            (this.user.isInspection() && (this.model.inspectionApprovalDate || this.model.inspectionRejectionDate))*/;
    }

    approve() {
        if (this.model.notificationTypeId === 'D' && !['A', 'AR'].includes(this.model.vesselVisit?.arrivalVesselNotification?.statusId)) {
            this.dialogService.warning('Cannot approve', `The arrival notification (${this.model.vesselVisit.arrivalVesselNotification.yearNumber}) has not yet been approved.`);
            return;
        }
        this.executeAction('Approve');
    }

    async createDeparture() {
        const res = await this.dialogService.yesNo('Create Departure Notification',
            this.translateService.instant('Would you like to transfer the data from Arrival Notification to Departure Notification?')
        );
        if (!res) return;

        const data = await this.dialogService.open(CreateDepartureModalComponent, { size: 'lg' },
            dialogRef => dialogRef.componentInstance.shortNotification = true);
        if (!data) return;

        return this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { cloneForDeparture: this.model.id, cloneData: JSON.stringify(data) } });
    }

    canCreateDeparture() {
        if (this.user.isAgent() && this.model.agentId !== this.user.organizationId) {
            const vnPermission = _.find(this.model.permissions, o => o.organizationId === this.user.organizationId);
            if (!vnPermission || !vnPermission.createDeparture) return false;
        }

        return this.viewMode &&
            this.model.vesselVisit?.departureVesselNotificationId == null &&
            this.model.notificationTypeId === 'A' &&
            ['A', 'W', 'AR'].indexOf(this.model.statusId) >= 0 &&
            this.user.hasPermission(VesselNotificationPermissions.Action.createDeparture);
    }

    canCreateVesselPermit() {
        return ['D', 'A', 'W', 'AR'].includes(this.model.statusId)
            && this.user.hasPermission(VesselNotificationPermissions.Action.createVesselPermit)
            && this.viewMode;
    }

    canReject() {
        return this.viewMode &&
            this.model.statusId === 'W' &&
            (
                this.user.hasPermission(VesselNotificationPermissions.Action.reject) ||
                this.user.hasPermission(VesselNotificationPermissions.Action.rejectAll)
            ) &&
            (this.user.isPort() || this.user.isSystemUser); // Začasno za #20073
    }

    canExcelUpdate() {
        return this.viewMode &&
            ['D', 'W'].includes(this.model.statusId)
            && this.user.hasPermission(VesselNotificationPermissions.Action.excelUpdate);
    }

    beforeSave(entities: BreezeEntity[]) {
        this.model.numberOfCrew = this.model.workers?.length || this.model.numberOfCrew;
        this.model.numberOfPax = this.model.passengers?.length || this.model.numberOfPax;

        if (!this.model.cargoOnBoard) this.model.cargoDescription = this.model.cargoGrossWeight = null;

        if (!this.model.bunkersOnBoard) this.model.bunkersDescription = null;

        if (!this.model.dpgOnBoard) this.model.dpgDescription = null;

        if (this.model.hasCvc) this.model.cvcRemarks = null;

        if (this.model.notificationTypeId !== 'D') this.model.portOfCallEtd = new Date(9999, 1);

        super.beforeSave(entities);
    }

}
