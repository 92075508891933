import { Component } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { LegendLabels, LegendLabelsContentArgs, LegendTitle, Title } from '@progress/kendo-angular-charts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [BreezeViewService]
})
export class HomeComponent {
    data;
    kotorTitle: Title = {
        text: 'KOTOR',
        font: '18px Arial',
        margin: { left: 290 }
    };

    barTitle: Title = {
        text: 'BAR',
        font: '18px Arial',
        margin: { left: 240 }
    };

    legendTitle: LegendTitle = { //TODO
        text: this.breezeViewService.translateService.instant('Vessel Notification status'),
        font: '20px Arial',
        margin: { bottom: 10 }
    };

    legendLabels: LegendLabels = {
        font: '18px Arial',
        margin: 17,
        content: (e: LegendLabelsContentArgs) => `${this.breezeViewService.translateService.instant(e.text)} (${e.value})`
    };

    colors = {
        'Approved': '#5DD879',
        'Waiting Approval': '#FFC107',
        'Rejected': '#E56B77',
        'Unconfirmed': '#59A9FF',
    };

    vesselVisitTitle: Title = {
        text: this.breezeViewService.translateService.instant('Total vessel visits'),
        font: '26px Arial'
    };

    vesselVisitFilterChanged = new Subject<any>();
    vesselVisitFilter = {
        portId: null,
        berthId: null,
        portFacilityId: null,
        vesselTypeId: null,
        year: null
    };
    vesselVisitData = {
        data: null,
        filteredData: null
    };

    vesselVisitCargoTitle: Title = {
        text: this.breezeViewService.translateService.instant('Total cargo vessel visits'),
        font: '26px Arial'
    };

    vesselVisitCargoFilterChanged = new Subject<any>();
    vesselVisitCargoFilter = {
        portId: null,
        berthId: null,
        portFacilityId: null,
        cargo: true
    };
    vesselVisitCargoData = {
        data: null,
        filteredData: null
    };

    constructor(private breezeViewService: BreezeViewService) {
        breezeViewService.handleQuery('DashboardData', {}).then(results => this.data = results[0]);
        this.fetchVesselVisitData();
        this.fetchVesselVisitCargoData();
        this.vesselVisitFilterChanged
            .pipe(debounceTime(500))
            .subscribe(() => this.fetchVesselVisitData());
        this.vesselVisitCargoFilterChanged
            .pipe(debounceTime(500))
            .subscribe(() => this.fetchVesselVisitCargoData());
    }

    onVesselVisitFilterChange(event) {
        this.vesselVisitFilterChanged.next(this.vesselVisitFilter);
    }

    onVesselVisitCargoFilterChange(event) {
        this.vesselVisitCargoFilterChanged.next(this.vesselVisitCargoFilter);
    }

    fetchVesselVisitData() {
        this.breezeViewService.handleQuery('VesselVisitDashboardData', this.vesselVisitFilter)
            .then(results => this.vesselVisitData = results[0]);
    }

    fetchVesselVisitCargoData() {
        this.breezeViewService.handleQuery('VesselVisitDashboardData', this.vesselVisitCargoFilter)
            .then(results => this.vesselVisitCargoData = results[0]);
    }

    getColor = (point): string => this.colors[point.category];

    getGridColor(): string {
        return JSON.parse(localStorage.getItem('dark')) ? '#4D4D4D' : '#DEE2E6';
    }

    labelContent(args: LegendLabelsContentArgs) {
        return args.dataItem.status;
    }
}
