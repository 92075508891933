<app-loader [isBusy]="!model">
    <ng-template>
        <div class="card mt-3" ng-model-options="{ allowInvalid: false, debounce: 200 }"
            style="border:0px solid !important">
            <div class="card-body">
                <div class="row mb-3 mr-1" *ngIf="mode !== 'create'">
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-md-auto" translate>{{model.notificationTypeId === 'A' ? 'Arrival' : 'Departure'}} </strong>
                            <div class="col-md-auto" [appPill]="{ status: model.statusId, module: 'vesselNotification', muted: true }">
                                {{model.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                            <div class="badge box-shadow tight" ngbTooltip="{{'Vessel has DPG on board' | translate}}" tooltipClass="error-tooltip arrow-bottom">
                                <fa-icon *ngIf="model.dpgOnBoard" class="redIcon" icon="fire"></fa-icon>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-auto">
                                <strong>
                                    <a *ngIf="!!model.vesselVisit && !currentlyOn('vessel-notification')" [routerLink]="['/short-vessel-notification/view', model.notificationTypeId === 'A' ? model.vesselVisit.arrivalVesselNotification.id : model.vesselVisit.departureVesselNotification.id]" target="_blank"> {{ model.yearNumber }} </a>
                                    <span *ngIf="currentlyOn('vessel-notification')"> {{ model.yearNumber }} </span>
                                </strong>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-auto">
                                <strong class="bluify" translate>Agent: </strong> {{model.agentId | codelist:'Organization' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-auto">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'ETA: ' : 'ETD: '}} </strong> {{ model.notificationTypeId === 'A' ? (model.portOfCallEta | date:'short') : (model.portOfCallEtd | date:'short') }}
                            </div>
                            <div class="col-md-auto" *ngIf="model.vesselVisitId">
                                <strong class="bluify">{{model.notificationTypeId === 'A' ? 'ATA: ' : 'ATD: '}} </strong> {{ model.notificationTypeId === 'A' ? (model.vesselVisit.ata | date:'short') : (model.vesselVisit.atd | date:'short') }}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <strong>UPS</strong>
                                <app-rej-icon [item]="model" organizationName="port" moduleType="VesselNotification"></app-rej-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-md-auto" translate> Notification Details </strong>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <strong>{{model.portOfCallId | codelist:'Location' | async}} / {{model.berthId | codelist:'Berth' | async}}</strong>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col">
                                <strong class="bluify" translate>Side at Berth: </strong> {{ model.berthTypeId | codelist:'BerthType' | async}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col">
                                <strong class="bluify" translate>Call purpose: </strong> {{(model.primaryShortCallPurposeId | codelist:'ShortCallPurpose' | async)}}
                            </div>
                        </div>
                        <div class="row mt-1" *ngIf="model.notificationTypeId === 'A'">
                            <div class="col-md-auto">
                                <strong class="bluify" translate>{{model.notificationTypeId === 'A' ? 'Arrival Draught Bow: ' : 'Departure Draught Bow: '}}</strong>
                                {{model.notificationTypeId === 'A' ? model.arrivalDraughtBow : model.departureDraughtBow}} m
                            </div>
                            <div class="col-md-auto">
                                <strong class="bluify" translate>{{model.notificationTypeId === 'A' ? 'Arrival Draught Stern: ' : 'Departure Draught Stern: '}}</strong>
                                {{model.notificationTypeId === 'A' ? model.arrivalDraughtStern : model.departureDraughtStern}} m
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-md-auto" translate>Visit</strong>
                            <div *ngIf="model.vesselVisitId" class="col-md-auto mr-2" [appPill]="{ status: model.vesselVisit.statusId, module: 'vesselVisit', muted: true }">
                                {{model.vesselVisit.statusId | codelist:'VesselVisitStatus' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div *ngIf="model.vesselVisitId" class="col-6">
                                <strong class="bluify" translate> Vessel Visit: </strong>
                                <strong> <a [routerLink]="['/vessel-visit/view', model.vesselVisitId]" target="_blank"> {{ model.vesselVisit.yearNumber }} </a></strong>
                            </div>
                            <div *ngIf="!model.vesselVisitId" class="col">
                                <strong translate> Vessel Visit: V/L does not have a Vessel visit </strong>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="model.notificationTypeId === 'A' && model.vesselVisitId">
                            <div class="col-6" *ngIf="model.vesselVisit.departureVesselNotification">
                                <strong class="bluify" translate>Departure: </strong>
                                <strong><a [routerLink]="['/short-vessel-notification/view', model.vesselVisit.departureVesselNotificationId]" target="_blank"> {{ model.vesselVisit.departureVesselNotification.yearNumber }} </a></strong>
                            </div>
                            <div class="col-md-auto" *ngIf="model.vesselVisit.departureVesselNotification" [appPill]="{ status: model.vesselVisit.departureVesselNotification.statusId, module: 'vesselNotification', muted: true }">
                                {{model.vesselVisit.departureVesselNotification.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="model.notificationTypeId === 'D' && model.vesselVisitId">
                            <div class="col-6">
                                <strong class="bluify" translate>Arrival: </strong>
                                <strong><a [routerLink]="['/short-vessel-notification/view', model.vesselVisit.arrivalVesselNotificationId]" target="_blank"> {{ model.vesselVisit.arrivalVesselNotification.yearNumber }} </a></strong>
                            </div>
                            <div class="col-md-auto" [appPill]="{ status: model.vesselVisit.arrivalVesselNotification.statusId, module: 'vesselNotification', muted: true }">
                                {{model.vesselVisit.arrivalVesselNotification.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="badge box-shadow tight" ngbTooltip="{{model.vessel.countryId | codelist:'Country' | async}}">
                            <span>{{ model.vessel.countryId }}</span>
                            <app-flag-icon [code]="model.vessel.countryId"></app-flag-icon>
                        </div>
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-md-auto" translate>Vessel information</strong>
                            <strong class="col-md-auto"><a [href]="['https://www.marinetraffic.com/en/ais/details/ships/imo:' + model.vessel.iMONumber ]" target="_blank">({{ model.vessel.iMONumber }})</a></strong>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-auto">
                                <strong><a [routerLink]="['/vessels/view', model.vesselId]" target="_blank"> {{ model.vesselId | codelist:'Vessel' | async }} </a></strong>
                                <fa-icon *ngIf="model.vessel?.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                            </div>
                            <div *ngIf="isDetained() || isBanned()" ngbTooltip="{{isDetained() ? 'Detained Vessel' : 'Banned Vessel'}}"
                                placement="right" style="font-size: 16px;">
                                <fa-icon icon="lock" class="redIcon"></fa-icon>
                            </div>
                            <div class="col-md-auto">
                                ({{model.vessel.typeId | codelist:'VesselType' | async}})
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-auto">
                                <strong class="bluify" translate>MMSI: </strong>{{ model.vessel.mmsi }}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-auto">
                                <strong class="bluify" translate>LOA: </strong>{{ model.vessel.length }} m
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-auto">
                                <strong class="bluify" translate>Gross Tonnage: </strong>{{ model.vessel.grossTonnage }}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-auto">
                                <strong class="bluify" translate>Net Tonnage: </strong>{{ model.vessel.netTonnage ? model.vessel.netTonnage : '/' }}
                            </div>
                        </div>
                    </div>
                </div>
                <ngb-accordion *ngIf="model.vessel" activeIds="agent-info">
                    <ngb-panel id="agent-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <div style="display: flex">
                                    <h5 translate>Agent Information</h5>
                                    <strong style="display: inline-block; margin-left: 4px; margin-top: 3px;" translate>
                                        - Mandatory in order to submit the vessel notification.</strong>
                                </div>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-3">
                                    <app-control label='Primary Shipping Agency' type="codelist"
                                        codelist="Agent" [isDisabled]="user.isAgent() || !editMode"
                                        (ngModelChange)="onAgentChange($event)" [(ngModel)]="model.agentId" [entity]="model" property="agentId">
                                    </app-control>
                                </div>
                                <div class="col" *ngIf="model.agent">
                                    <app-control label='Primary Shipping Agency email'
                                        [(ngModel)]="model.agent.email" [entity]="model.agent" property="email" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col" *ngIf="model.agent">
                                    <app-control label='Primary Shipping Agency phone'
                                        [(ngModel)]="model.agent.phone" [entity]="model.agent" property="phone" [isDisabled]="true" pattern="[\d]" prepend="+">
                                    </app-control>
                                </div>
                                <div class="col-2" *ngIf="model.agent">
                                    <app-control label='Primary Shipping Agency address'
                                        [(ngModel)]="model.agent.address" [entity]="model.agent" property="address" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-3" *ngIf="model.agent">
                                    <app-control label='Primary Shipping Agency on duty number'
                                        [(ngModel)]="model.dutyNumber" [entity]="model" property="dutyNumber" pattern="[\d]" prepend="+"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Additional Agents' type="codelist"
                                        codelist="Agent" (ngModelChange)="onAdditionalAgentsChange($event)"
                                        [filter]="excludePrimaryAgent.bind(this)" #additionalAgentsControl multi="true"
                                        [(ngModel)]="additionalAgents" [entity]="" property="additionalAgents"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3" *ngIf="viewMode"></div>
                <div class="row mb-3" *ngIf="isCreate && !model.vessel">
                    <div class="container mt-5 mb-4">
                        <div class="row mb-3">
                            <div class="col">
                                <label class="vessel-select-text" translate>Select a vessel to create a short vessel notification:</label>
                                <kendo-autocomplete #autocomplete [data]="searchData" [filterable]="true"
                                    placeholder="{{'Search criteria: IMO / MMSI / Vessel Name / Flag' | translate}}"
                                    (valueChange)="searchValueChange($event)"
                                    (filterChange)="searchFilterChange($event)" valueField="index"
                                    style="width: 100%; font-size: 16px;">
                                    <ng-template kendoAutoCompleteHeaderTemplate>
                                        <div class="row text-center"
                                            style="width: 100%; margin: 0; padding: 3px 1.5rem">
                                            <ng-container *ngFor="let item of vesselSelectSortFields">
                                                <div class="col h5" (click)="sortVesselSelect(item.field)"
                                                    style="cursor: pointer;"
                                                    [ngClass]="{'text-primary': item.sort, 'text-error': item.sort === false}">
                                                    <fa-icon *ngIf="item.sort !== null"
                                                        [icon]="item.sort ? 'arrow-down' : 'arrow-up'"></fa-icon>
                                                    {{item.columnName | translate}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                    <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                        <div class="row text-center align-items-center" style="width: 100%; margin: 0">
                                            <div class="col">{{ dataItem.vessel.iMONumber }}</div>
                                            <div class="col">{{ dataItem.vessel.mmsi }}</div>
                                            <div class="col">{{dataItem.vessel.name}}
                                                <div *ngIf="dataItem.vessel.statusId === 'DT'" style="color: red;"> (Detained)</div>
                                                <div *ngIf="dataItem.vessel.statusId === 'BN'" style="color: orange;"> (Banned)</div>
                                            </div>
                                            <div class="col">{{ dataItem.vessel.countryId }}</div>
                                        </div>
                                    </ng-template>
                                </kendo-autocomplete>
                            </div>
                        </div>
                        <div class="row mb-3 text-center" *ngIf="selectedVessel">
                            <div class="col">
                                <div class="card mt-3" style="min-height: 150px;">
                                    <div class="card-body">
                                        <div class="h5">
                                            <a [routerLink]="['/vessels/view', selectedVessel.vessel.id]"
                                                class="text-primary">
                                                {{selectedVessel.vessel.name}}
                                            </a>
                                            <fa-icon *ngIf="selectedVessel.vessel.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                                            <!--
                                            <span *ngIf="selectedVessel.vessel.grayList">
                                                ( <fa-icon icon="flag" style="color: gray"></fa-icon> )
                                            </span>
                                            <span *ngIf="selectedVessel.vessel.greenList">
                                                ( <fa-icon icon="flag" style="color: green"></fa-icon> )
                                            </span>
                                            -->
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span translate>MMSI</span>: {{selectedVessel.vessel.mmsi}}
                                            </div>
                                            <div class="col">
                                                <span translate>Flag</span>:
                                                <app-flag-icon [code]="selectedVessel.vessel.countryId"
                                                    codelist="Country"></app-flag-icon>
                                            </div>
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span translate>Call Sign</span>: {{selectedVessel.vessel.callSign}}
                                            </div>
                                            <div class="col">
                                                <span translate>Year built</span>: {{selectedVessel.vessel.yearBuild}}
                                            </div>
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span translate>IMO</span>: {{selectedVessel.vessel.iMONumber }}
                                            </div>
                                            <div class="col">
                                                <span translate>Deadweight tonnage</span>: {{selectedVessel.vessel.deadWeight}}
                                            </div>
                                        </div>
                                        <div class="row mt-1 h6">
                                            <div class="col">
                                                <span>Type</span>: {{selectedVessel.vessel.typeId |
                                                codelist:'VesselType' | async}}
                                            </div>
                                            <div class="col">
                                                <span>Gross tonnage</span>: {{selectedVessel.vessel.grossTonnage}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div *ngIf="mode === 'create' && !model.vessel" class="row mb-3 text-center">
                            <div class="col">
                                <button *ngIf="selectedVessel" type="button" class="btn btn-success mr-1"
                                    (click)="createArrival()" translate>Create arrival</button>
                                <button *ngIf="!selectedVessel" type="button" class="btn btn-primary mr-1" [hidden]="!canImportIHSVessel()"
                                    (click)="importIHSVessel()" translate>Import IHS vessel</button>
                                <button *ngIf="!selectedVessel" type="button" class="btn btn-primary" [hidden]="!canImportEMSAVessel()"
                                    (click)="importEMSAVessel()" translate>Import EMSA vessel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="border:0;  height: 4px; background: #009BDA; ">
                <kendo-tabstrip #tabs (tabSelect)="onTabSelect($event)" *ngIf="model.vessel"
                [ngClass]="{'stickyClosed': actionBarStatus?.collapsed, 'stickyOpen': !actionBarStatus?.collapsed, 'stickyEmpty': actionBarStatus?.empty}" class="icon-tabs mt-4 sticky-top">
                    <kendo-tabstrip-tab [selected]="isSelected(0)" *ngIf="isVisible(0)">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="map-signs"></fa-icon>
                                <div>{{'VOYAGE' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-voyage [model]="model" [user]="user" [editMode]="editMode"
                                [viewMode]="mode" [canEdit]="isEditable(0)" [shortNotification]="true"></app-vessel-notification-voyage>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(1)" [cssClass]="{'hidden-tab': !isVisible(1)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="users"></fa-icon>
                                <div>{{'CREW' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-worker [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(1)" [shortNotification]="true"></app-vessel-notification-worker>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(2)" [cssClass]="{'hidden-tab': !isVisible(2)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="restroom"></fa-icon>
                                <div>{{'PASSENGERS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-passenger [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(2)"></app-vessel-notification-passenger>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(3)" [cssClass]="{'hidden-tab': !isVisible(3)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="trash-alt"></fa-icon>
                                <div>{{'WASTE' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-waste [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(3)"></app-vessel-notification-waste>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(4)" [cssClass]="{'hidden-tab': !isVisible(4)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="user-md"></fa-icon>
                                <div>{{'HEALTH' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-health [model]="model" [user]="user" [editMode]="editMode"
                                [canEdit]="isEditable(4)"></app-vessel-notification-health>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(5)" [cssClass]="{'hidden-tab': !isVisible(5)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="book"></fa-icon>
                                <div>{{'ATTACHMENTS' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-attachment [attachments]="model.attachments" [user]="user"
                                [tabs]="!editMode" [editMode]="editMode" [canEdit]="isEditable(5)">
                            </app-vessel-notification-attachment>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(6)" [cssClass]="{'hidden-tab': !isVisible(6)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="list"></fa-icon>
                                <div>{{'LOG' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-log [model]="model" [editMode]="editMode"
                                [canEdit]="isEditable(6)"></app-vessel-notification-log>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab [selected]="isSelected(7)" [cssClass]="{'hidden-tab': !isVisible(7)}">
                        <ng-template kendoTabTitle>
                            <div class="text-center">
                                <fa-icon icon="print"></fa-icon>
                                <div>{{'PRINT' | translate}}</div>
                            </div>
                        </ng-template>
                        <ng-template kendoTabContent>
                            <app-vessel-notification-print [model]="model" [editMode]="editMode"
                                [isTabVisible]="isTabVisible" [shortNotification]="true">
                            </app-vessel-notification-print>
                        </ng-template>
                    </kendo-tabstrip-tab>

                    <kendo-tabstrip-tab cssClass="ml-auto float-right" *ngIf="canGoNext()">
                        <ng-template kendoTabTitle>
                            <div class="text-center" *ngIf="currentStep < finalStepIndex">
                                <fa-icon icon="arrow-circle-right"></fa-icon>
                                <div>{{'Next' | translate}}</div>
                            </div>
                            <div class="text-center" (click)="this.confirmed = true"
                                *ngIf="currentStep === finalStepIndex">
                                <fa-icon icon="check-circle"></fa-icon>
                                <div>{{'Confirm' | translate}}</div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>
