import _ from 'lodash';

export type PermissionType = string | { _: string };

export class User {
    id: number;
    userName: string;
    fullName: string;
    email: string;
    active: true;
    organization: any;
    organizationId: number;
    roles: any[];
    isSystemUser: boolean;
    language: { id: string };
    hasAnyNotifications: boolean;
    data: any;
    portId: string;
    allRoles: string[];
    lastActiveDate: string;

    private readonly allPermissions: string[];
    private readonly cache: { [permission: string]: boolean } = {};

    constructor(data: any) {
        Object.assign(this, data);
        const userRoles = _.flatMap(this.roles, x => x.role);
        const organizationRoles = this.organization ? _.flatMap(this.organization.roles, x => x.role) : [];
        const roles = _.uniq([...userRoles, ...organizationRoles]);
        this.allRoles = _.map(roles, x => x.name);
        this.allPermissions = _.uniq(_.map(_.flatMap(roles, x => x.permissions), x => `${x.permission.module}.${x.permission.namespace}.${x.permission.name}`));
        _.forEach(_.filter(this.allPermissions, x => x), ((x: string) => {
            const temp = x.split('.');
            this.cache[temp[0]] = true;
            this.cache[`${temp[0]}.${temp[1]}`] = true;
            this.cache[x] = true;
        }));
    }

    isAgent() {
        return this.organization?.typeId === 'AG';
    }

    isPort() {
        return this.organization?.typeId === 'UPS';
    }

    isCustoms() {
        return this.organization?.typeId === 'CU';
    }

    isPolice() {
        return this.organization?.typeId === 'PO';
    }

    isInspection() {
        return this.organization?.typeId === 'IN';
    }

    isVTSAgent() {
        return this.organization?.code === 'TMVS';
    }

    isSecurity() {
        return this.organization?.typeId === 'IS';
    }

    isVtsUser() {
        return this.allRoles?.some(x => x.includes('VTS'));
    }

    hasPermission(...permissions: PermissionType[]) {
        if (this.isSystemUser) return this.isSystemUser;

        const perms = _.map(permissions, (x: PermissionType) => _.isString(x) ? x : (x)?._);

        return _.some(perms, y => this.cache[y]);
    }

    hasRole(role: string) {
        return _.includes(this.allRoles, role);
    }
}
