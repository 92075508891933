import { VesselNotificationBallastWatersComponent } from './components/vessel-notification-ballast-waters/vessel-notification-ballast-waters.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { VesselNotificationViewComponent } from './components/vessel-notification-view/vessel-notification-view.component';
import { VesselNotificationBunkerComponent } from './components/vessel-notification-bunker/vessel-notification-bunker.component';
import { VesselNotificationWasteComponent } from './components/vessel-notification-waste/vessel-notification-waste.component';
import { VesselNotificationWasteDepartureComponent } from './components/vessel-notification-waste-departure/vessel-notification-waste-departure.component';
import { VesselNotificationVoyageComponent } from './components/vessel-notification-voyage/vessel-notification-voyage.component';
import { VesselNotificationDangerousGoodsComponent } from './components/vessel-notification-dangerous-goods/vessel-notification-dangerous-goods.component';
import { VesselNotificationStoreProductComponent } from './components/vessel-notification-store-product/vessel-notification-store-product.component';
import { VesselNotificationWorkerComponent } from './components/vessel-notification-worker/vessel-notification-worker.component';
import { VesselNotificationPassengerComponent } from './components/vessel-notification-passenger/vessel-notification-passenger.component';
import { VesselNotificationSecurityComponent } from './components/vessel-notification-security/vessel-notification-security.component';
import { VesselNotificationHealthComponent } from './components/vessel-notification-health/vessel-notification-health.component';
import { VesselNotificationItineraryComponent } from './components/vessel-notification-itinerary/vessel-notification-itinerary.component';
import { VesselNotificationListComponent } from './components/vessel-notification-list/vessel-notification-list.component';
import { VesselNotificationCargoComponent } from './components/vessel-notification-cargo/vessel-notification-cargo.component';
import { VesselNotificationLogComponent } from './components/vessel-notification-log/vessel-notification-log.component';
import { VesselNotificationAttachmentComponent } from './components/vessel-notification-attachment/vessel-notification-attachment.component';
import { VesselNotificationPermissionComponent } from './components/vessel-notification-permission/vessel-notification-permission.component';
import { VesselNotificationViewContentComponent } from './components/vessel-notification-view-content/vessel-notification-view-content.component';
import { VesselNotificationAttachmentGridComponent } from './components/vessel-notification-attachment-grid/vessel-notification-attachment-grid.component';
import { VesselNotificationPrintComponent } from './components/vessel-notification-print/vessel-notification-print.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { VesselNotificationDetailsComponent } from './components/vessel-notification-details/vessel-notification-details.component';
import { UpdateVesselNotificationModalComponent } from './components/update-vessel-notification-modal/update-vessel-notification-modal.component';
import { UploadVesselNotificationModalComponent } from './components/upload-vessel-notification-modal/upload-vessel-notification-modal.component';
import { CreateDepartureModalComponent } from './components/create-departure-modal/create-departure-modal.component';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { CanDeactivateVesselNotification } from '@common/guards/vessel-notification.deactivate';
import { ConfirmSendVesselNotificationModalComponent } from './components/confirm-send-vessel-notification-modal/confirm-send-vessel-notification-modal.component';
import { VesselNotificationDefectsModalComponent } from './components/vessel-notification-defects-modal/vessel-notification-defects-modal.component';
import { VesselNotificationGridComponent } from './components/vessel-notification-grid/vessel-notification-grid.component';
import { VesselNotificationAccompaniedComponent } from './components/vessel-notification-accompanied/vessel-notification-accompanied.component';
import { VesselNotificationAccompaniedGridComponent } from './components/vessel-notification-accompanied-grid/vessel-notification-accompanied-grid.component';
import { ChangeAgencyModalComponent } from './components/change-agency-modal/change-agency-modal.component';
import { VesselNotificationGeneralRemarksComponent } from './components/vessel-notification-general-remarks/vessel-notification-general-remarks.component';
import { CreateGeneralRemarkModalComponent } from './components/create-general-remark-modal/create-general-remark-modal.component';
import { VesselNotificationPilotComponent } from './components/vessel-notification-pilot/vessel-notification-pilot.component';

export const VESSEL_NOTIFICATION_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselNotificationListComponent
    },
    {
        path: 'view/:id',
        component: VesselNotificationViewComponent,
        data: {
            mode: 'view'
        }
    },
    {
        path: 'edit/:id',
        component: VesselNotificationViewComponent,
        data: {
            mode: 'edit'
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'create',
        component: VesselNotificationViewComponent,
        data: {
            mode: 'create'
        },
        canDeactivate: [CanDeactivateVesselNotification]
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    imports: [CommonModule, EditorModule],
    exports: [
        VesselNotificationViewComponent,
        VesselNotificationBunkerComponent,
        VesselNotificationWasteComponent,
        VesselNotificationWasteDepartureComponent,
        VesselNotificationVoyageComponent,
        VesselNotificationDangerousGoodsComponent,
        VesselNotificationStoreProductComponent,
        VesselNotificationWorkerComponent,
        VesselNotificationPassengerComponent,
        VesselNotificationSecurityComponent,
        VesselNotificationHealthComponent,
        VesselNotificationItineraryComponent,
        VesselNotificationListComponent,
        VesselNotificationCargoComponent,
        VesselNotificationLogComponent,
        VesselNotificationAttachmentComponent,
        VesselNotificationPermissionComponent,
        VesselNotificationViewContentComponent,
        VesselNotificationAttachmentGridComponent,
        VesselNotificationPrintComponent,
        VesselNotificationDetailsComponent,
        UpdateVesselNotificationModalComponent,
        UploadVesselNotificationModalComponent,
        CreateDepartureModalComponent,
        ConfirmSendVesselNotificationModalComponent,
        VesselNotificationDefectsModalComponent,
        VesselNotificationGridComponent,
        VesselNotificationAccompaniedComponent,
        VesselNotificationAccompaniedGridComponent,
        VesselNotificationBallastWatersComponent,
        VesselNotificationPilotComponent
    ],
    declarations: [
        VesselNotificationViewComponent,
        VesselNotificationBunkerComponent,
        VesselNotificationWasteComponent,
        VesselNotificationWasteDepartureComponent,
        VesselNotificationVoyageComponent,
        VesselNotificationDangerousGoodsComponent,
        VesselNotificationStoreProductComponent,
        VesselNotificationWorkerComponent,
        VesselNotificationPassengerComponent,
        VesselNotificationSecurityComponent,
        VesselNotificationHealthComponent,
        VesselNotificationItineraryComponent,
        VesselNotificationListComponent,
        VesselNotificationCargoComponent,
        VesselNotificationLogComponent,
        VesselNotificationAttachmentComponent,
        VesselNotificationPermissionComponent,
        VesselNotificationViewContentComponent,
        VesselNotificationAttachmentGridComponent,
        VesselNotificationPrintComponent,
        VesselNotificationDetailsComponent,
        UpdateVesselNotificationModalComponent,
        UploadVesselNotificationModalComponent,
        CreateDepartureModalComponent,
        ConfirmSendVesselNotificationModalComponent,
        VesselNotificationDefectsModalComponent,
        VesselNotificationGridComponent,
        VesselNotificationAccompaniedComponent,
        VesselNotificationAccompaniedGridComponent,
        ChangeAgencyModalComponent,
        VesselNotificationGeneralRemarksComponent,
        CreateGeneralRemarkModalComponent,
        VesselNotificationBallastWatersComponent,
        VesselNotificationPilotComponent
    ],
    providers: [CanDeactivateView, CanDeactivateVesselNotification]
})
export class VesselNotificationComponentModule { }

@NgModule({
    imports: [CommonModule, VesselNotificationComponentModule, RouterModule.forChild(VESSEL_NOTIFICATION_ROUTES)],
})
export class VesselNotificationModule { }
