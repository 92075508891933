import { Component } from '@angular/core';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { EntityFormOptions } from '@common/classes/entity-form';
import { VesselNotificationPermissions, VesselPermitPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { PrintService } from '@common/services/print.service';

@Component({
    selector: 'app-vessel-permit-view',
    templateUrl: './vessel-permit-view.component.html',
    styleUrls: ['./vessel-permit-view.component.css'],
    providers: [BreezeViewService]
})
export class VesselPermitViewComponent extends AbstractBreezeViewComponent {
    entityName = 'VesselPermit';
    cloneId: number;
    vesselNotificationId: number;
    editPermission = VesselPermitPermissions.Action.edit;
    createPermission = VesselPermitPermissions.Action.create;

    breadcrumb = [
        {
            icon: 'file',
            title: 'Vessel Permits',
            route: 'vessel-permit/list/'
        },
        {
            icon: 'file',
            title: 'Vessel Permit'
        }
    ];

    formOptions: EntityFormOptions = {
        entityName: 'VesselPermitAttachment',
        canEdit: () => this.user?.hasPermission(VesselPermitPermissions.Action.edit) && this.editMode,
        beforeAdd: model => {
            model.createdDate = new Date();
            model.createdById = this.user?.id;
        },
        propertyGroups: [
            [
                { name: 'attachment', label: this.translateService.instant('Attachment'), type: AppControlType.File, colSize: 6 },
                { name: 'remarks', label: this.translateService.instant('Remarks'), type: AppControlType.String, colSize: 6 }
            ]
        ]
    };

    constructor(breezeViewService: BreezeViewService,
        private printService: PrintService) {
        super(breezeViewService);
        this.cloneId = this.breezeViewService.activatedRoute.snapshot.queryParams.clone;
        this.vesselNotificationId = this.breezeViewService.activatedRoute.snapshot.queryParams.vesselNotificationId;
        this.editActionBarGroup.items = [
            ...this.editActionBarGroup.items,
            {
                label: this.translateService.instant('Cancel Permit'),
                icon: 'ban',
                isVisible: () => this.canCancelPermit(),
                onClick: () => this.cancelPermit(),
                isDisabled: () => this.disableCancel()
            },
            {
                label: this.translateService.instant('Send'),
                icon: 'paper-plane',
                isVisible: () => this.canSend(),
                onClick: () => this.send(),
            },
            {
                label: this.translateService.instant('Approve'),
                icon: 'thumbs-up',
                isVisible: () => this.canApprove(),
                isDisabled: () => ['APP', 'REJ'].includes(this.model.statusId) || this.isWaitingForExtension,
                onClick: () => this.approve(),
            },
            {
                label: this.translateService.instant('Reject'),
                icon: 'thumbs-down',
                isVisible: () => this.canReject(),
                isDisabled: () => ['APP', 'REJ'].includes(this.model.statusId) || this.isWaitingForExtension,
                onClick: () => this.reject(),
            },
            {
                label: this.translateService.instant('Amend'),
                icon: 'font',
                isVisible: () => this.canAmend(),
                onClick: () => this.amend(),
            },
            {
                label: this.translateService.instant('Extend Validity'),
                icon: 'share',
                isVisible: () => this.canExtend(),
                onClick: () => this.extend(),
            },
            {
                label: this.translateService.instant('Revoke'),
                icon: 'times',
                isVisible: () => this.canRevoke(),
                onClick: () => this.revoke(),
                isDisabled: () => this.isWaitingForExtension
            },
            {
                label: this.translateService.instant('Discard'),
                icon: 'trash',
                isVisible: () => this.canDiscard(),
                onClick: () => this.discard(),
            },
            {
                label: this.translateService.instant('Print'),
                icon: 'print',
                isVisible: () => this.canPrint() && this.model.statusId !== 'DRA',
                onClick: () => this.printService.printDocument(this.parentRoute, [this.model.id]),
                isDisabled: () => this.isWaitingForExtension
            },
            {
                label: this.translateService.instant('Clone'),
                icon: 'clone',
                isVisible: () => this.canClone(),
                onClick: () => this.clone(),
            }];
    }

    getIdentifier() {
        return this.model.number;
    }

    async initialize() {
        if (!this.cloneId) {
            await super.initialize();
            return;
        }

        await this.executeCommand({ commandName: 'CloneVesselPermit', data: { id: this.cloneId }, tracking: false })
            .then(data => {
                const entity = data.results[0];
                this.model = this.entityManager.createEntity(this.entityName, entity);
                this.model.entityAspect.loadNavigationProperty('vessel');
                return this.model;
            });
    }

    canEdit() {
        return (['DRA', 'WAP', 'WEX', 'AMN'].includes(this.model.statusId) && (this.user?.organizationId === this.model.requestingOrganizationId || this.user?.isSystemUser) ||
            (this.model.statusId === 'WAP' && (this.user?.isPort() || this.user?.isSystemUser))) &&
            super.canEdit();
    }

    canEditDescriptions() {
        return (this.model.statusId === 'APP' || (['WAP', 'AMN'].includes(this.model.statusId) && (this.user?.isPort() || this.user?.isSystemUser))) &&
            this.user?.hasPermission(VesselPermitPermissions.Edit.descriptions);
    }

    modelLoaded() {
        super.modelLoaded();
        this.onVesselNotificationChange(this.vesselNotificationId);
    }

    onVesselNotificationChange(value) {
        if (!value || this.viewMode) { return; }

        this.model.vesselNotificationId = value;
        this.model.vesselId = null;
        this.model.berthId = null;
        this.model.typeId = null;
        this.model.proposedWorks = null;
        this.model.entityAspect.loadNavigationProperty('vesselNotification').then((data) => {
            this.model.vesselId = data.results[0].vesselId;
            this.model.entityAspect.loadNavigationProperty('vessel');
        });
    }

    onTypeChange(typeId) {
        if (this.isDangerousCargo) {
            this.model.validFromDate = this.model.vesselVisit?.eta;
            this.model.validToDate = this.model.vesselVisit?.etd;
        }
    }

    canSend() {
        return ['DRA', 'AMN'].includes(this.model.statusId) &&
            (this.user?.organizationId === this.model.requestingOrganizationId || this.user?.isSystemUser) &&
            this.user?.hasPermission(VesselPermitPermissions.Action.send) &&
            this.mode !== ViewMode.create;
    }

    send() {
        if (!['D', 'A', 'W', 'AR'].includes(this.model.vesselNotification?.statusId)) {
            this.dialogService.openConfirmDialog(this.translateService.instant('Vessel Permit') as string,
                this.translateService.instant(`To submit a Permit for approval for Vessel Notification ${this.model.vesselNotification.yearNumber}, the notification's status must be either: Draft, Approved, Waiting for Approval or Arrived.`) as string,
                true);
        } else {
            this.dialogService.openConfirmDialog(this.translateService.instant('Vessel Permit') as string,
                this.translateService.instant('You are about to send the permit request to be approved by the Transport Togo.<br/><br/> Please press Send to send the request or press Cancel to cancel it.') as string,
                false,
                'Cancel',
                'Send')
                .then(async (result) => {
                    if (result === true) {
                        if (this.editMode && this.hasChanges()) {
                            await this.saveChanges({ silent: true });
                        }
                        await this.executeAction('Send');
                        return this.router.navigate([`${this.parentRoute}/list/`]);
                    }
                });
        }
    }

    canClone() {
        return this.viewMode &&
            ['APP', 'REJ', 'REV', 'DIS'].includes(this.model.statusId) &&
            this.user?.hasPermission(VesselPermitPermissions.Action.clone);
    }

    clone() {
        this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { clone: this.model.id } });
    }

    canCancelPermit() {
        return this.viewMode &&
            this.user?.hasPermission(VesselPermitPermissions.Action.cancel) &&
            (this.model.statusId === 'APP' && (this.user?.isAgent() || this.user?.isSystemUser));
    }

    disableCancel() {
        return this.user?.isAgent() && Date.now() >= Date.parse(this.model.validFromDate);
    }

    async cancelPermit() {
        this.dialogService.openConfirmDialog(
            this.translateService.instant('Vessel Permit') as string,
            this.translateService.instant('Are you sure you want to cancel this vessel permit?') as string,
            false,
            'No',
            'Yes').then(async (result) => {
                if (!result) { return; }

                if (this.model.vesselnotification?.statusId === 'D') {
                    await this.executeAction('Discard', { ids: [this.model.id] });
                } else {
                    await this.executeAction('Cancel', { ids: [this.model.id] });
                }

                return this.router.navigate([`${this.parentRoute}/list/`], { queryParams: { refresh: true } });
            });
    }

    canDiscard() {
        return ['DRA', 'WAP', 'WEX'].includes(this.model.statusId) && !this.isNew && this.viewMode &&
            this.user?.hasPermission(VesselPermitPermissions.Action.discard) &&
            (this.user?.isSystemUser || this.model.requestingOrganizationId === this.user?.organizationId);
    }

    async discard() {
        await this.executeAction('Discard', { ids: [this.model.id] });
    }

    canRevoke() {
        return (['APP', 'WAP'].includes(this.model.statusId) &&
            (this.user?.isPort() || this.user?.isSystemUser)) &&
            this.viewMode &&
            this.user?.hasPermission(VesselPermitPermissions.Action.revoke);
    }

    async revoke() {
        const data = await this.dialogService.form(
            {
                title: 'Revoke',
                properties: [
                    {
                        name: 'revokeRemarks',
                        type: AppControlType.TextArea,
                        label: this.translateService.instant('Remarks'),
                    }
                ]
            },
            { size: 'md' });
        if (!data) { return; }

        await this.executeAction('Revoke', { remarks: data.revokeRemarks });
        return this.router.navigate([`${this.parentRoute}/list/`]);
    }

    canExtend() {
        return ['APP', 'WAP'].includes(this.model.statusId) &&
            this.viewMode &&
            this.user?.hasPermission(VesselPermitPermissions.Action.extend);
    }

    async extend() {
        const data = await this.dialogService.form(
            {
                title: this.translateService.instant('Extend Validity'),
                properties: [
                    {
                        name: 'toDate',
                        type: AppControlType.DateTime,
                        time: true,
                        label: this.translateService.instant('New date'),
                        initialValue: this.model.validToDate
                    }
                ]
            },
            { size: 'md' });
        if (!data) { return; }

        return this.executeCommand({
            commandName: 'ExtendVesselPermit', data: {
                id: this.model.id,
                toDate: data.toDate
            }
        }).then((response) => {
            if (response.results?.length > 0) {
                this.toastrService.success(this.translateService.instant('Successfully requested permit extension'));
                this.router.navigate([`${this.parentRoute}/list`]);
            }
        });
    }

    canAmend(): boolean {
        return ['APP', 'REJ'].includes(this.model.statusId) &&
            this.user?.hasPermission(VesselPermitPermissions.Action.amend) &&
            this.viewMode &&
            !this.model.referenceNumber.includes('A') && // Ne moreš amendat amendmenta #24365
            (this.user?.isPort() || this.user?.isSystemUser);
    }

    async amend() {
        const data = await this.dialogService.form(
            {
                title: 'Amend',
                properties: [
                    {
                        name: 'amendRemarks',
                        type: AppControlType.TextArea,
                        label: this.translateService.instant('Remarks'),
                    }
                ]
            },
            { size: 'md' });
        if (!data) { return; }

        return this.executeCommand({
            commandName: 'AmendVesselPermit', data: {
                id: this.model.id,
                remarks: data.amendRemarks
            }
        }).then((response) => {
            this.isBusy = false;

            if (response.results?.length > 0) {
                this.toastrService.success(this.translateService.instant('Successfully requested permit amendment'));
                this.router.navigate([`${this.parentRoute}/list`]);
            }
        });
    }

    canApprove() {
        return ['WAP', 'WEX', 'REJ', 'APP'].includes(this.model.statusId) &&
            this.user?.hasPermission(VesselPermitPermissions.Action.approve) &&
            this.viewMode &&
            (this.user?.isPort() || this.user?.isSystemUser);
    }

    async approve() {
        if (Date.now() >= Date.parse(this.model.validToDate)) {
            this.dialogService.warning('Cannot approve', `The validity of the permit has expired.`);
            return;
        }

        const data = await this.dialogService.form(
            {
                title: 'Approve',
                properties: [
                    {
                        name: 'approveRemarks',
                        type: AppControlType.TextArea,
                        label: this.translateService.instant('Remarks'),
                    }
                ]
            },
            { size: 'md' });
        if (!data) { return; }

        await this.executeAction('Approve', { remarks: data.approveRemarks });
    }

    canReject() {
        return ['WAP', 'WEX', 'REJ', 'APP'].includes(this.model.statusId) &&
            this.user?.hasPermission(VesselPermitPermissions.Action.reject) &&
            this.viewMode &&
            (this.user?.isPort() || this.user?.isSystemUser);
    }

    async reject() {
        const data = await this.dialogService.form(
            {
                title: 'Reject',
                properties: [
                    {
                        name: 'rejectRemarks',
                        type: AppControlType.TextArea,
                        label: this.translateService.instant('Remarks'),
                    }
                ]
            },
            { size: 'md' });
        if (!data) { return; }

        await this.executeAction('Reject', { remarks: data.rejectRemarks });
    }

    canCreateNew() {
        return this.user?.hasPermission(this.createPermission) || this.user?.hasPermission(VesselNotificationPermissions.Action.createVesselPermit);
    }

    createEntity() {
        const entity = super.createEntity();
        entity.validFromDate = null;
        entity.validToDate = null;
        entity.statusId = 'DRA';
        return entity;
    }

    async executeAction(name: string, data = null) {
        this.executeCommand({
            commandName: `${name}VesselPermit`, data: {
                id: this.model.id,
                ...data
            }, refreshData: true
        });
    }

    canPrint() {
        return this.viewMode &&
            this.user?.hasPermission(VesselPermitPermissions.Action.print) &&
            !this.isNew &&
            !(this.model.statusId === 'WAP' && this.user?.isAgent());
    }

    beforeSave(entities: BreezeEntity[]) {
        if (!this.model.statusId) {
            this.model.statusId = 'DRA';
        }
        super.beforeSave(entities);
    }

    get isDangerousCargo() {
        // DG1P, DG7P
        return [6, 7].includes(this.model.typeId);
    }

    get isEditable() {
        return ['DRA', 'WAP', 'AMN', 'WEX'].includes(this.model.statusId) &&
            this.editMode;
    }

    get canEditPermitType() {
        return this.model.statusId === 'DRA' && this.editMode;
    }

    get isCreateMode() {
        return this.mode === ViewMode.create;
    }

    get canEditDateTime() {
        return this.editMode &&
            (['DRA', 'AMN'].includes(this.model.statusId) ||
                this.model.statusId === 'WAP' && !this.user?.isPort() ||
                (this.model.statusId === 'WEX' && (Date.now() <= Date.parse(this.model.validFromDate))));
    }

    get isWaitingForExtension() {
        return this.model.statusId === 'WAP' && (Date.now() > Date.parse(this.model.validToDate));
    }

    isDetained() {
        return this.model.vessel.statusId === 'DT';
    }

    isBanned() {
        return this.model.vessel.statusId === 'BN';
    }
}
