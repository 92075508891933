import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { DialogService as KendoDialogService } from '@progress/kendo-angular-dialog';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { VesselNotificationDefectsModalComponent } from '../vessel-notification-defects-modal/vessel-notification-defects-modal.component';

@Component({
    selector: 'app-vessel-notification-grid',
    templateUrl: './vessel-notification-grid.component.html',
    styleUrls: ['./vessel-notification-grid.component.scss']
})
export class VesselNotificationGridComponent {
    @Input() query;
    @Input() selection = [];
    @Input() user: User;
    @Input() disableGridClickFilter: boolean;
    @Input() sort = [{ field: 'id', dir: 'asc' }] as SortDescriptor[];

    @Output() dataChange = new EventEmitter();
    @Output() rowParameterClicked = new EventEmitter();

    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;

    constructor(private dialogService: DialogService,
        private breezeViewService: BreezeViewService) { }

    canSeeRemarks(vesselNotification) {
        const hasPermission = this.user?.hasPermission(VesselNotificationPermissions.View.remarks);
        if (this.user?.isAgent()) return hasPermission && !!vesselNotification.portRemarks;

        return hasPermission;
    }

    async openDefectsModal(vnId) {
        this.dialogService.open(VesselNotificationDefectsModalComponent, { size: 'md' },
            dialogRef => dialogRef.componentInstance.vesselNotificationId = vnId);
    }

    async openRemarksModal(vesselNotification) {
        const data = await this.dialogService.form(
            {
                title: 'Vessel Notification Remarks',
                properties: [
                    {
                        label: 'Remarks',
                        name: 'portRemarks',
                        type: AppControlType.TextArea,
                        initialValue: vesselNotification.portRemarks,
                        isDisabled: () => !this.user?.hasPermission(VesselNotificationPermissions.Edit.remarks)
                            || (vesselNotification.portRemarks && vesselNotification.portRemarksViewed && !vesselNotification.portRemarksViewedByTM)
                    }
                ],
                confirmText: 'Save',
            }, { size: 'md', keyboard: false });
        if (!data) {
            return;
        }
        this.breezeViewService.handleCommand('SetVesselNotificationPortRemarks', { id: vesselNotification.id, portRemarks: data.portRemarks })
            .then(() => this.dataChange.emit());
    }

    isRunningLate(row): number {
        return (row.notificationTypeId === 'A' && row.statusId !== 'AR') ||
            (row.notificationTypeId === 'D' && row.statusId !== 'DP') ?
                (Date.parse(row[`portOfCallEt${row.notificationTypeId.toLowerCase()}`]) - Date.now()) / 3_600_000 : 9001;
    }

    emitFilterData(value, propertyName) {
        if (this.disableGridClickFilter) return;
        this.rowParameterClicked.emit({ value, propertyName });
    }

    isExpired(date, visitStatus) {
        return Date.parse(date) < Date.now() && visitStatus !== 'DE';
    }

    public getRowClass(context: RowClassArgs) {
        const isExpired = Date.parse(context.dataItem.portOfCallEtd) < Date.now() && context.dataItem?.vesselVisit?.statusId !== 'DE';
        return { expiredETD: isExpired };
    }

    isRestricted(statusId: string, showToolTip = false) {
        return showToolTip ? statusId === 'DT' ? 'Detained Vessel' : 'Banned Vessel' : ['DT', 'BN'].includes(statusId);
    }
}
