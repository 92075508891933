<div class="row" *ngIf="!notification?.isShort || notification?.isShort && notification?.typeId !== 'D'">
    <div class="col mt-5 font-weight-bold">
        <h5 translate>If the vessel's flag is black listed, please add the certificates below</h5>
        <h6 *ngIf="notification?.isShort" translate>* Include Certificates and Documents to be carried in line with the Port Notice
        </h6>
    </div>
</div>
<app-entity-form [options]="certificateFormOptions" [grid]="certificatesGrid"></app-entity-form>
<app-grid #certificatesGrid [data]="model.certificates">
    <kendo-grid-column field="typeId" title="{{'Type' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.typeId | codelist:row:'typeId'|async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="countryId" title="{{'Country' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.countryId | codelist:row:'countryId'|async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="issueDate" title="{{'Issue Date' | translate}}" format="d">
    </kendo-grid-column>
    <kendo-grid-column title="{{'Permanent' | translate}}" field="permanent">
        <ng-template let-row kendoGridCellTemplate>
            <fa-icon [icon]="['far', 'square']" *ngIf="!row.permanent"></fa-icon>
            <fa-icon [icon]="['far', 'check-square']" *ngIf="row.permanent"></fa-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="expirationDate" title="{{'Expiration Date' | translate}}" format="d">
    </kendo-grid-column>
    <kendo-grid-column field="description" title="{{'Description' | translate}}"></kendo-grid-column>
    <kendo-grid-column class="overflow-unset" [hidden]="editMode" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <app-upload-action-buttons [file]="row.attachment" [disabled]="true" [multiple]="false"></app-upload-action-buttons>
        </ng-template>
    </kendo-grid-column>
</app-grid>