import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-accordion-header',
    template: `
    <div class="row">
        <div class="col-11" [ngClass]="{ 'pointer': !onlyClose }" (click)="onlyClose ? close() : toggle()">
            <ng-content></ng-content>
        </div>
        <div class="col-1 pointer" (click)="toggle()">
            <fa-icon class="text-primary" [icon]="panel.isOpen ? 'angle-up' : 'angle-down'"></fa-icon>
        </div>
    </div>
    `,
    styles: [
        `.pointer {
            cursor: pointer;
        }
        `
    ]
})
export class AccordionHeaderComponent implements OnInit {
    storageKey: string;

    @Input() panel: NgbPanel;
    @Input() onlyClose: boolean;
    @Input() rememberPanelState: string;
    @Input() closeOtherPanels: boolean;

    @Output() panelChange = new EventEmitter();

    constructor(public accordion: NgbAccordion) {
        this.panelChange = this.accordion.panelChange;
    }

    ngOnInit() {
        if (this.rememberPanelState) {
            this.storageKey = `panel-${this.rememberPanelState}:`;
            const panelState = localStorage.getItem(this.storageKey);

            if (panelState === 'true') setTimeout(() => this.accordion.expand(this.panel.id)); // Angular change detection workaround
        }
        // Angular change detection workaround
        setTimeout(() => this.accordion.closeOtherPanels = this.closeOtherPanels);
    }

    toggle() {
        this.accordion.toggle(this.panel.id);
        if (this.rememberPanelState)
            localStorage.setItem(this.storageKey, this.panel.isOpen.toString());
    }

    close() {
        if (this.panel.isOpen) this.accordion.toggle(this.panel.id);
    }
}
