import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BreezeEntity } from '@common/classes/breeze-entity';
import {
    NavigationProperty,
    EntityAspect,
    ValidationError,
    EntityError,
    EntityManager,
    Entity,
    EntityType,
    EntityKey,
    MetadataStore,
    EntityState
} from '@cime/breeze-client';

@Injectable({
    providedIn: 'root'
})
export class BreezeService {
    importEntityGraph(entityName: string, entity, getEntityState: (type: string) => EntityState, entityManager: EntityManager) {
        const processedEntites = {};
        const queue = [{ entity, type: entityManager.metadataStore.getEntityType(entityName) }];
        while (queue.length) {
            const item = queue[0];
            queue.shift();
            if (!processedEntites[item.type.shortName])
                processedEntites[item.type.shortName] = new Set();

            processedEntites[item.type.shortName]?.add(item.entity);
            _.each(item.type.navigationProperties, (property: NavigationProperty) => {
                if (!property.isNavigationProperty) return;

                const relatedEntity = item.entity[property.name];
                item.entity[property.name] = null;
                if (!relatedEntity || !processedEntites.hasOwnProperty(item.type.shortName) || processedEntites[item.type.shortName].has(relatedEntity)) return;

                if (property.isScalar) queue.push({ entity: relatedEntity, type: property.entityType });
                else _.each(relatedEntity, child => queue.push({ entity: child, type: property.entityType }));
            });
        }

        const result = [];
        _.each(processedEntites, (set: Set<any>, key) => {
            const entityState = getEntityState(key);
            set.forEach(value => result.push(entityManager.createEntity(key, value, entityState)));
        });

        return result;
    }

    convertToDto(model) {
        return BreezeService._convertToDto(model);
    }

    getEntites(rootEntity: BreezeEntity, predicate: (entity: BreezeEntity) => boolean = null, processedEntities: BreezeEntity[] = []) {
        let results: BreezeEntity[] = [];
        let i: number;
        let navProp: NavigationProperty;
        let relatedEntities: BreezeEntity[];
        const navProps = rootEntity.entityType.navigationProperties;
        if (!processedEntities.length) results.push(rootEntity); // first call

        processedEntities.push(rootEntity);
        for (i = 0; i < navProps.length; i++) {
            navProp = navProps[i];
            relatedEntities = rootEntity[navProp.name];
            if (!_.isArray(relatedEntities)) relatedEntities = [relatedEntities];

            _.each(relatedEntities, (item: any) => {
                if (!item || !item.entityAspect || processedEntities.includes(item)) return;

                if (!predicate || predicate(item)) results.push(item);

                results = results.concat(this.getEntites(item, predicate, processedEntities));
                processedEntities.push(item);
            });
        }

        return results;
    }

    clearServerValidationErrors(entityAspect: EntityAspect) {
        _.forEach(this.getServerErrors(entityAspect), error => entityAspect.removeValidationError(error));
    }

    getServerErrors(entityAspect: EntityAspect): ValidationError[] {
        return _.filter(entityAspect.getValidationErrors(), error => error.isServerError);
    }

    getEntityErrors(entity: BreezeEntity): EntityError[] {
        return _.map(entity.entityAspect.getValidationErrors(), error => ({
            entity,
            errorName: error.key,
            errorMessage: error.errorMessage,
            propertyName: error.propertyName,
            isServerError: error.isServerError
        }));
    }

    processServerErrors(entityManager: EntityManager, error): EntityError[] {
        const entityErrors = BreezeService.getEntityServerErrors(error);

        const metadataStore = entityManager.metadataStore;
        return _.map(entityErrors, entityError => {
            let entity: Entity | null = null;
            let entityType: EntityType | undefined;
            const propertyName = entityError.propertyName
                ? metadataStore.namingConvention.serverPropertyNameToClient(entityError.propertyName)
                : null;
            if (entityError.keyValues) {
                entityType = metadataStore._getStructuralType(MetadataStore.normalizeTypeName(entityError.entityTypeName)) as EntityType;
                const ekey = new EntityKey(entityType, entityError.keyValues);
                entity = entityManager.getEntityByKey(ekey);
            }

            if (entityType && entity) {
                const context = propertyName ?
                    {
                        entity,
                        propertyName,
                        property: entityType.getProperty(propertyName)
                    } : {
                        entity
                    };
                const key = ValidationError.getKey(entityError.errorName || entityError.errorMessage, propertyName);
                const ve = new ValidationError(null, context, entityError.errorMessage, key);
                ve.isServerError = true;
                entity.entityAspect.addValidationError(ve);
            }

            return {
                entity,
                errorName: entityError.errorName,
                errorMessage: entityError.errorMessage,
                propertyName,
                isServerError: true
            };
        });
    }

    // tslint:disable-next-line:member-ordering
    private static getEntityServerErrors(error) {
        if (!error.httpResponse) throw error;

        let data = error.httpResponse.data;
        // some ajax providers will convert errant result into an object ( angular), others will not (jQuery)
        // if not do it here.
        if (typeof data === 'string') {
            try {
                data = JSON.parse(data);
            } catch (e) { }
        }

        if (!data) throw error;

        return data.EntityErrors || data.entityErrors || data.Errors || data.errors;
    }

    // tslint:disable-next-line:member-ordering
    private static _convertToDto(val, opts = null) {
        let dto;
        opts = opts || {};
        if (!opts.hasOwnProperty('refs')) {
            opts.refs = {}; // key: refId, value: entity
            opts.nextRefId = 1;
        }

        if (val?._backingStore) { // is entity
            if (val.hasOwnProperty('entityAspect') &&
                (
                    val.entityAspect.entityState.isDeleted() ||
                    val.entityAspect.entityState.isDetached()
                )) {
                return null; // skip deleted/detached entities
            }

            // check if the entity was already been processed (prevents an infinite loop)
            for (const refId in opts.refs) {
                if (opts.refs[refId] === val)
                    return { $ref: refId.toString() }; // Json.NET convention for references
            }
            dto = { $id: opts.nextRefId.toString() };
            // Save the current entity
            opts.refs[dto.$id] = val;
            opts.nextRefId++;

            if (_.isFunction(opts.interceptEntity)) opts.interceptEntity(val);

            _.forIn(val._backingStore, (v, k) => {
                if (k === 'entityAspect' || k === 'complexAspect') return;

                if (v != null && (v.hasOwnProperty('entityAspect') /*scalar*/ || v.hasOwnProperty('getEntityAspect') /*non-scalar*/) && (opts.skipNavigationProperties === true ||
                    (_.isFunction(opts.skipNavigationProperties) && opts.skipNavigationProperties(k, v)))) return;

                const kVal = BreezeService._convertToDto(v, opts);
                if (kVal != null) dto[k] = kVal; // Skip if the value is null as it can be a relation that was not been yet loaded
            });
            return dto;
        }

        if (_.isArray(val)) {
            dto = [];
            _.forEach(val, (arrItem) => {
                const item = BreezeService._convertToDto(arrItem, opts);
                if (item) dto.push(item);
            });
            return dto;
        }

        return val;
    }
}
