import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject, firstValueFrom } from 'rxjs';

export interface CodeList {
    id: string;
    code: string;
    name: string;
    customText: string;
}
type CacheKey = `${string}-${string}-${number}`;

@Injectable({
    providedIn: 'root'
})
export class CodelistService {
    cachedCodelists = new Map<CacheKey, Observable<Array<CodeList>>>();

    constructor(private http: HttpClient) { }

    getCodelist({ name, selectedIds, filter, take, useCache }: { name: string, selectedIds?: any[], filter?: string, take?: number, useCache: boolean }): Promise<Array<CodeList>> {
        selectedIds = selectedIds?.filter(Boolean) || []; // In case selectedIds contains falsy values
        const codelistObservable = this.http.post('api:///query/CodeList', { name, selectedIds, filter, take });
        const ids = selectedIds.toString() || '';
        take ||= environment.settings.appControl.codelist.take;

        const cacheKey = `${name}-${ids}-${take}` as CacheKey;

        const resultObservable = (
            this.cachedCodelists.has(cacheKey) && useCache
                ? this.cachedCodelists.get(cacheKey)
                : filter
                    ? codelistObservable
                    : this.addToCodelistCache(cacheKey, codelistObservable)
        ) as Observable<Array<CodeList>>;

        return firstValueFrom(resultObservable);
    }

    private addToCodelistCache(codelistCacheKey: CacheKey, codelistObservable: Observable<any>): Observable<Array<CodeList>> {
        const newSubject = new ReplaySubject<Array<CodeList>>();
        const observable = newSubject.asObservable();

        codelistObservable.subscribe(data => newSubject.next(data));
        this.cachedCodelists.set(codelistCacheKey, observable);

        return observable;
    }

}
