import { Component, Input, OnInit } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import _ from 'lodash';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { ExportType } from '@common/classes/export-type';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-notification-worker',
    templateUrl: './vessel-notification-worker.component.html',
    styleUrls: ['./vessel-notification-worker.component.scss']
})
export class VesselNotificationWorkerComponent extends AbstractBreezeListComponent implements OnInit {
    queryName = 'VesselNotificationWorkers';
    exportCommand = 'ExportVesselNotificationCrewList';
    persistFilter = false;

    @Input() model: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;
    @Input() shortNotification = false;
    @Input() user: User;

    workerEffectOptions: EntityFormOptions;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationWorker',
        getCollection: () => this.model.workers,
        canEdit: () => this.canEdit,
        beforeAdd: (data) => data.sequenceNumber = this.model.workers.length + 1,
        onAdd: () => {
            this.workerEffectOptions.propertyGroups[0].find(p => p.name === 'workerGivenName').options = this.mapWorkers(this.model.workers, 'givenName');
            this.workerEffectOptions.propertyGroups[0].find(p => p.name === 'workerFamilyName').options = this.mapWorkers(this.model.workers, 'familyName');
            this.calculateWorkers();
            this.model.entityAspect.validateEntity();
        },
        onRemove: () => {
            this.calculateWorkers();
            this.model.entityAspect.validateEntity();
        },
        propertyGroups: [
            [
                { name: 'givenName', label: 'Given Name', maxlength: 100, pattern: '[^0-9]', colSize: 3 },
                { name: 'familyName', label: 'Family Name', maxlength: 100, pattern: '[^0-9]', colSize: 3 },
                { name: 'rank', label: 'Rank', maxlength: 50, colSize: 2 },
                { name: 'nationalityId', label: 'Nationality', type: AppControlType.CodeList, codelist: 'CountryNationality', colSize: 3 }
            ],
            [
                { name: 'birthDate', label: 'Date of Birth', type: AppControlType.DateTime, colSize: 4 },
                { name: 'birthPlace', label: 'Place of Birth', maxlength: 100, colSize: 4 },
                { name: 'genderId', label: 'Gender', type: AppControlType.CodeList, codelist: 'Gender', colSize: 3 }
            ],
            [
                { name: 'documentTypeId', label: 'Type of ID', type: AppControlType.CodeList, codelist: 'DocumentType', colSize: 3 },
                { name: 'documentNumber', label: 'ID Number', maxlength: 20, colSize: 2 },
                { name: 'documentIssuingCountryId', label: 'ID Issuing Country', type: AppControlType.CodeList, codelist: 'Country', colSize: 3 },
                { name: 'documentExpirationDate', label: 'ID Expiration Date', type: AppControlType.DateTime, colSize: 3 }
            ]
        ]
    };

    constructor(breezeViewService: BreezeViewService) { super(breezeViewService); }

    ngOnInit() {
        this.workerEffectOptions = {
            entityName: 'VesselNotificationWorkerEffect',
            canEdit: () => this.canEdit,
            beforeAdd: (model) => {
                const worker = this.model.workers.find(x => x.givenName === model.workerGivenName && x.familyName === model.workerFamilyName);
                if (!worker) throw new Error(`Worker with full name ${model.workerGivenName} ${model.workerFamilyName} not found`);
                model.vesselNotificationWorkerId = worker.id;
            },
            propertyGroups: [
                [
                    {
                        name: 'workerGivenName',
                        label: 'Crew given name',
                        type: AppControlType.Select,
                        options: this.mapWorkers(this.model.workers, 'givenName')
                    },
                    {
                        name: 'workerFamilyName',
                        label: 'Crew family name',
                        type: AppControlType.Select,
                        options: this.mapWorkers(this.model.workers, 'familyName')
                    },
                    { name: 'quantity', label: 'Quantity', type: AppControlType.Number, decimals: 2 },
                    { name: 'unitOfMeasureId', label: 'UOM', type: AppControlType.CodeList, codelist: 'BunkerUnitOfMeasures' }
                ],
                [
                    { name: 'effect', label: 'Effect', type: AppControlType.TextArea, maxlength: 500 },
                    { name: 'description', label: 'Description', type: AppControlType.TextArea, maxlength: 500 }
                ]
            ]
        };
        super.ngOnInit();
    }

    getDefaultFilter() {
        return {
            id: this.model?.id
        };
    }

    mapWorkers(workers, property) {
        return (workers || []).map((x: any) => ({ label: x[property], value: x[property] }));
    }

    calculateWorkers() {
        this.model.numberOfCrew = this.model.workers?.length;
        this.model.personsOnBoard = this.model.numberOfCrew + this.model.passengers?.length + (this.model.numberOfStowaways || 0);
    }

    override export() {
        return super.export(ExportType.Excel, this.appGrid);
    }

    canExport() {
        return this.user?.hasPermission(VesselNotificationPermissions.Action.exportCrewList);
    }

    get canSeeFilterAndExport() {
        return !this.editMode && ['D', 'A', 'W', 'R', 'AR', 'DP'].includes(this.model.statusId);
    }
}
