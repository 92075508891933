import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-update-vessel-notification-modal',
    templateUrl: './update-vessel-notification-modal.component.html',
    styleUrls: ['./update-vessel-notification-modal.component.scss']
})
export class UpdateVesselNotificationModalComponent {
    model = {
        content: false,
        voyage: false,
        bunker: false,
        ballastWaters: false,
        cargo: false,
        dangerousGoods: false,
        crew: false,
        crewEffect: false,
        pilots: false,
        passenger: false,
        shipStore: false,
        waste: false,
        security: false,
        cruiseItinerary: false,
        mdh: false,
        mdhAttachment: false,
        accompaniedVehicle: false
    };

    isDeparture: boolean = false;
    isCompleted: boolean = false;
    isShort: boolean = false;
    isApproved: boolean = false;

    constructor(public activeModal: NgbActiveModal) { }

    close() {
        this.activeModal.close();
    }

    canConfirm() {
        return this.model.content;
    }

    confirm() {
        this.activeModal.close(this.model);
    }
}
