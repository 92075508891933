import { Directive, ViewContainerRef, Injector, OnDestroy, ComponentRef } from '@angular/core';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appConsoleContent]'
})
export class ConsoleContentDirective implements OnDestroy {
    private componentSubscription: Subscription;
    private componentRef: ComponentRef<any>;

    constructor(public viewContainerRef: ViewContainerRef,
        consoleWindowService: ConsoleWindowService,
        private injector: Injector) {
        this.componentSubscription = consoleWindowService.componentSubject.subscribe((data) => {
            if (!data) this.destroyComponent();
            else this.loadComponent(data.component, data.dataProviders);
        });
    }

    loadComponent(component, dataProviders) {
        this.destroyComponent();

        this.viewContainerRef.clear();
        const injector = Injector.create({providers: dataProviders || [], parent: this.injector});
        this.componentRef = this.viewContainerRef.createComponent(component, { injector });
    }

    destroyComponent() {
        if (this.componentRef) this.componentRef.destroy();
    }

    ngOnDestroy() {
        this.componentSubscription.unsubscribe();
    }
}
