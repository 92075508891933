import { Component, Input, ViewChild } from '@angular/core';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';

@Component({
    selector: 'app-vessel-shift-grid',
    templateUrl: './vessel-shift-grid.component.html',
    styleUrls: ['./vessel-shift-grid.component.scss']
})
export class VesselShiftGridComponent {
    @Input() query;
    @Input() selection = [];
    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;

    isRestricted(statusId: string, showToolTip = false) {
        return showToolTip ? statusId === 'DT' ? 'Detained Vessel' : 'Banned Vessel' : ['DT', 'BN'].includes(statusId);
    }
}
