import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { User } from '../models/User';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isJwtAuthenticated());
    currentUserSubject = new BehaviorSubject<User>(null);
    private refreshDataTimer;

    constructor(private http: HttpClient, private router: Router) {
        new BroadcastChannel('IDLE_LOGOUT').onmessage = () => this.logout();
    }

    async login(username: string, password: string): Promise<boolean> {
        const data = {
            username,
            password
        };

        try {
            const response = await firstValueFrom(this.http.post<any>('api:///command/Login', data));
            this.setAccessToken(response.accessToken);
            this.setRefreshToken(response.refreshToken);
            this.updateIsAuthenticatedSubject(true);

            return true;
        } catch (error) {
            this.updateIsAuthenticatedSubject(false);
            this.updateCurrentUser(null);
            if (error.status === 401) return false; // First login, the user has to change the password

            throw error.status === 400
                ? error.error.validationErrors[0].errorMessage
                : 'Unknown error';
        }
    }

    async firstLogin(username: string, newPassword: string): Promise<boolean> {
        const data = {
            username,
            newPassword
        };

        try {
            const response = await firstValueFrom(this.http.post<any>('api:///command/FirstLogin', data));
            this.setAccessToken(response.accessToken);
            this.setRefreshToken(response.refreshToken);
            this.updateIsAuthenticatedSubject(true);

            return true;
        } catch (error) {
            this.updateIsAuthenticatedSubject(false);
            this.updateCurrentUser(null);
            throw error.status === 400
                ? error.error.validationErrors[0].errorMessage
                : 'Unknown error';
        }
    }

    logout(addReturnUrl = true) {
        if (this.hasAccessToken()) {
            localStorage.removeItem(this.getAccessTokenKey());
            localStorage.removeItem(this.getRefreshTokenKey());
        }

        this.updateIsAuthenticatedSubject(false);
        this.updateCurrentUser(null);

        const routerOptions = addReturnUrl ? { queryParams: { returnUrl: this.router.url } } : {};
        return this.router.navigate(['/login'], routerOptions);
    }

    async getCurrentUser(fetchData = true): Promise<User> {
        try {
            const data = await firstValueFrom(this.http.get('api:///query/CurrentUser'));
            let user = null;
            if (data) user = new User(data);

            if (!fetchData) return user;

            user.hasAnyNotifications = <any>await firstValueFrom(this.http.post('api:///query/HasActiveNotifications', {}));
            user.data = await firstValueFrom(this.http.post('api:///query/CurrentUserData', {}));

            this.updateIsAuthenticatedSubject(!!data);
            this.updateCurrentUser(user);

            return user;
        } catch (error) {
            this.updateIsAuthenticatedSubject(false);
            this.updateCurrentUser(null);
        }
    }

    refreshAccessToken() {
        return this.http
            .post<any>('api:///command/RefreshAccessToken', {
                refreshToken: this.getRefreshToken()
            })
            .pipe(tap(response => {
                this.setAccessToken(response.accessToken);
                this.setRefreshToken(response.refreshToken);
            }));
    }

    getAccessTokenKey() {
        return environment.accessToken;
    }

    getRefreshTokenKey() {
        return environment.refreshToken;
    }

    getAccessToken() {
        return localStorage.getItem(this.getAccessTokenKey());
    }

    getRefreshToken() {
        return localStorage.getItem(this.getRefreshTokenKey());
    }

    hasAccessToken() {
        return !!this.getAccessToken();
    }

    setAccessToken(accessToken: string) {
        return localStorage.setItem(this.getAccessTokenKey(), accessToken);
    }

    setRefreshToken(refreshToken: string) {
        return localStorage.setItem(this.getRefreshTokenKey(), refreshToken);
    }

    private updateIsAuthenticatedSubject(value) {
        if (this.isAuthenticatedSubject.getValue() !== value)
            this.isAuthenticatedSubject.next(value);
    }

    private updateCurrentUser(user) {
        if (this.currentUserSubject.getValue() !== user)
            this.currentUserSubject.next(user);

        clearInterval(this.refreshDataTimer);
        this.refreshDataTimer = null;
        if (user !== null) {
            this.refreshDataTimer = setInterval(async () => {
                try {
                    user.data = await firstValueFrom(this.http.post('api:///query/CurrentUserData', {}));
                } catch { console.error('Failed to obtain user data'); }
            }, 60_000);
        }
    }

    private parseJwt(token): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        return JSON.parse(jsonPayload);
    }

    getJwtPayload() {
        const token = localStorage.getItem(environment.accessToken);
        if (!token) return null;

        return this.parseJwt(token);
    }

    private isJwtAuthenticated() {
        try {
            const payload = this.getJwtPayload();

            if (!payload) return false;

            return new Date(payload.exp * 1000) > new Date();
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }
}
