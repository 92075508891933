import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-upload-single-file',
    template: `
    <div class="k-file-single" *ngIf="!disabled">
        <span [title]="fileName">{{ fileName }}</span>
        <app-upload-action-buttons
            [file]="file"
            (removeFile)="onRemoveFile($event)"
            [disabled]="disabled"
            [multiple]="false">
        </app-upload-action-buttons>
    </div>
    <div *ngIf="disabled" class="input-group input-group-sm">
        <input type="text" class="form-control" disabled="true" [value]="fileName" />
        <app-upload-action-buttons
            class="input-group-append"
            [file]="file"
            (removeFile)="onRemoveFile($event)"
            [disabled]="disabled"
            [multiple]="false">
        </app-upload-action-buttons>
    </div>
    `
})
export class UploadSingleFileComponent {
    @Input() file: any;
    @Input() disabled: boolean;

    @Output() public removeFile = new EventEmitter<any>();

    get fileName() {
        return this.file ? this.file.name : null;
    }

    onRemoveFile(file) {
        this.removeFile.emit(file);
    }
}
