<app-grid [data]="query" [selectable]="{ enabled: true, checkboxOnly: true }" [selection]="selection"
    [sortable]="true" [sort]="sort" [stickyHeader]="true" [rowClass]="getRowClass">
    <kendo-grid-checkbox-column [width]="40" [showSelectAll]="true"></kendo-grid-checkbox-column>
    <kendo-grid-column title="{{'V/L Notification' | translate}}" field="id" [width]="120">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-notification/view', row.id]">
                <div [appPill]="{ status: row.statusId, module: 'vesselNotification' }"> {{row.yearNumber}} </div>
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Name' | translate}}" field="vessel.name">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="isRestricted(row.vessel.statusId)" class="badge box-shadow tight" style="font-size: 16px;"
                ngbTooltip="{{isRestricted(row.vessel.statusId, true)}}" placement="right">
                <fa-icon icon="lock" class="redIcon"></fa-icon>
            </div>
            <a *ngIf="!disableGridClickFilter" (click)="emitFilterData(row.vesselId, 'vesselId')"
                class="text-primary" target="_blank">
                {{row.vesselId | codelist:'Vessel' | async}}
            </a>
            <a *ngIf="disableGridClickFilter" [routerLink]="['/vessels/view', row.vesselId]" class="text-primary"
                target="_blank">
                {{row.vesselId | codelist:'Vessel' | async}}
            </a>
            <div class="badge box-shadow tight" ngbTooltip="{{row.vessel.countryId | codelist:'Country' | async}}"
                placement="right" style="font-size: 16px;">
                <app-flag-icon [code]="row.vessel.countryId"></app-flag-icon>
            </div>
            <span class="float-right">
                <span *ngIf="isRunningLate(row) <= 5">
                    <fa-icon [ngClass]="{'redIcon': isRunningLate(row) <= 0}"
                        ngbTooltip="{{row.notificationTypeId === 'A' ? 'ETA: ' + (row.portOfCallEta | date:'short') : 'ETD: ' + (row.portOfCallEtd | date:'short')}}"
                        placement="right" [tooltipClass]="isRunningLate(row) <= 0 ? 'error-tooltip' : ''"
                        icon="stopwatch" style="cursor: pointer; margin-right: 5px">
                    </fa-icon>
                </span>
                <fa-icon *ngIf="!!row.hasDefects" icon="wrench" style="cursor: pointer;"
                    (click)="openDefectsModal(row.id)">
                </fa-icon>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Notification Type' | translate}}" field="notificationType.name" [width]="105">
        <ng-template kendoGridCellTemplate let-row>
            {{row.notificationTypeId | codelist:'VesselNotificationType' | async}}
            <span class="float-right">
                <fa-icon *ngIf="canSeeRemarks(row)" style="cursor: pointer" icon="comments"
                    [ngClass]="{'redIcon': !row.portRemarksViewed && (user?.isAgent() || user?.isPort()),
                        'greenIcon': row.portRemarksViewed && !row.portRemarksViewedByTM && user?.isPort()}"
                    (click)="openRemarksModal(row)">
                </fa-icon>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Status' | translate}}" field="status.name" [width]="145">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="!disableGridClickFilter" [appPill]="{ status: row.statusId, module: 'vesselNotification' }"
                (click)="emitFilterData(row.statusId, 'statusId')">
                {{row.statusId | codelist:'VesselNotificationStatus' | async}}
            </div>
            <div *ngIf="disableGridClickFilter"
                [appPill]="{ status: row.statusId, module: 'vesselNotification', muted: true }">
                {{row.statusId | codelist:'VesselNotificationStatus' | async}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Visit' | translate}}" field="vesselVisitId" [width]="65">
        <ng-template kendoGridCellTemplate let-row>
            <a class="text-primary" *ngIf="row.vesselVisitId" [routerLink]="['/vessel-visit/view', row.vesselVisitId]">
                {{row.vesselVisit.yearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Berth' | translate}}" field="berth.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.berthId | codelist:'Berth' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Port of Call ETA' | translate}}" field="portOfCallEta" [width]="100" format="d">
    </kendo-grid-column>
    <kendo-grid-column class="expiredETD" title="{{'Port of Call ETD' | translate}}" field="portOfCallEtd" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <div [ngClass]="{ 'expiredETD': isExpired(row.portOfCallEtd, row.vesselVisit?.statusId) }">
                {{row.portOfCallEtd | date:'short'}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Agent / Operator' | translate}}" field="agent.name" [width]="150"></kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'Has DPG' | translate}}" [width]="80">
        <ng-template kendoGridCellTemplate let-row>
            <div style="display: flex">
                <div style="display: inline-block; width: 33.33%">
                    <span *ngIf="row.hasClass1DPG" class="fa-layers fa-fw">
                        <fa-icon icon="circle"></fa-icon>
                        <span class="fa-layers-text fa-inverse">1</span>
                    </span>
                </div>
                <div style="display: inline-block; width: 33.33%">
                    <fa-icon *ngIf="!row.hasHazmat" class="redIcon" icon="times-circle"></fa-icon>
                    <fa-icon *ngIf="row.hasHazmat" class="greenIcon" icon="check-circle"></fa-icon>
                </div>
                <div style="display: inline-block; width: 33.33%">
                    <span *ngIf="row.hasClass7DPG" class="fa-layers fa-fw">
                        <fa-icon icon="circle"></fa-icon>
                        <span class="fa-layers-text fa-inverse">7</span>
                    </span>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'UPS' | translate}}" field="portApprovalDate" [width]="40" sortable="{ initialDirection: 'desc' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="port" moduleType="VesselNotification"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'PO' | translate}}" field="policeApprovalDate" [width]="40" sortable="{ initialDirection: 'desc' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="police" moduleType="VesselNotification"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'CU' | translate}}" field="customsApprovalDate" [width]="40" sortable="{ initialDirection: 'desc' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="customs" moduleType="VesselNotification"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'IN' | translate}}" field="inspectionApprovalDate" [width]="40" sortable="{ initialDirection: 'desc' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="inspection" moduleType="VesselNotification"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'IS' | translate}}" field="iSPSApprovalDate" [width]="40" sortable="{ initialDirection: 'desc' }">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="iSPS" moduleType="VesselNotification"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'72h' | translate}}" [width]="40">
        <ng-template kendoGridCellTemplate let-row>
            <fa-icon *ngIf="row.preArrivalSentDate" class="greenIcon" icon="check-circle"></fa-icon>
            <fa-icon *ngIf="row.preArrival && !row.preArrivalSentDate" class="redIcon" icon="check-circle"></fa-icon>
        </ng-template>
    </kendo-grid-column>
</app-grid>