<div class="filter-wrapper">
    <ngb-accordion>
        <ngb-panel>
            <ng-template ngbPanelHeader let-panel>
                <app-accordion-header [panel]="panel" [onlyClose]="hasMetaFilter">
                    <div *ngIf="!panel.isOpen && hasMetaFilter; else onlyTitle">
                        <ng-content select="[meta-filter]"></ng-content>
                    </div>
                    <ng-template #onlyTitle>
                        <h5 translate><fa-icon icon="filter"></fa-icon>Filters</h5>
                    </ng-template>
                </app-accordion-header>
            </ng-template>
            <ng-template ngbPanelContent>
                <ng-content select="[all-filter]"></ng-content>
                <div class="row mt-3">
                    <div class="col">
                        <div class="float-right">
                            <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter.emit()">
                                <fa-icon class="ml-1" icon="trash"></fa-icon>&nbsp;<span class="btn-text" translate>Clear</span>
                            </button>
                            <button class="btn btn-sm btn-primary" (click)="search.emit()">
                                <fa-icon class="ml-1" icon="search"></fa-icon>&nbsp;<span class="btn-text" translate>Search</span>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>