<ngb-accordion activeIds="waste-info">
    <ngb-panel id="waste-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Waste Information</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row mb-3">
                <div class="col">
                    <app-control label='Planned Depot' type="codelist" codelist="WasteDelivery"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.wasteDeliveryId" [entity]="model" property="wasteDeliveryId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Last port of waste delivered' type="codelist" codelist="Location"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.wasteLastDeliveryPortId" [entity]="model" property="wasteLastDeliveryPortId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Date of last delivery' type="datetime"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.wasteLastDeliveryDate" [entity]="model" property="wasteLastDeliveryDate"></app-control>
                </div>
                <div class="col">
                    <app-control label='Sufficient on board capacity' type="yesno" [isDisabled]="!canEdit || !editMode"
                        [(ngModel)]="model.sufficientWasteOnBoardCapacity" [entity]="model" property="sufficientWasteOnBoardCapacity"></app-control>
                </div>
                <div class="col">
                    <app-control label='Next port of waste delivered' type="codelist" codelist="Location"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.wasteNextDeliveryPortId" [entity]="model" property="wasteNextDeliveryPortId"></app-control>
                </div>
            </div>
            <ngb-accordion activeIds="waste-list">
                <ngb-panel id="waste-list">
                    <ng-template ngbPanelHeader let-panel>
                        <div class="row">
                            <div class="col-md-auto">
                                <h5 style="width: auto;" translate>Waste Disposal Information</h5>
                            </div>
                            <div class="col" style="margin-left: -9px; margin-top: 3px;">
                                <strong style="font-size: 13px;" translate>- Any waste quantity which will not be reported, will
                                    be set as “0”</strong>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <h3 translate>Waste List</h3>
                        <app-editable-grid [data]="model.wastesArrival" entityName="VesselNotificationWaste"
                            [canEdit]="canEdit" [editMode]="editMode">
                            <kendo-grid-column title="{{'Waste Type' | translate}}" [width]="320">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control type="codelist" codelist="WasteType" [disableTooltip]="true"
                                        [(ngModel)]="dataItem.wasteTypeId" [entity]="dataItem" property="wasteTypeId"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.wasteTypeId | codelist:'WasteType' | async }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Description' | translate}}" [width]="200">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control [(ngModel)]="dataItem.description" [entity]="dataItem" property="description"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ defaultDisplayValue(dataItem.description, 'string') }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Waste to be delivered (m3)' | translate}}" format="n3">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control type="number" format="n3" [decimals]="3"
                                        [(ngModel)]="dataItem.wasteToBeDelivered" [entity]="dataItem" property="wasteToBeDelivered"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ defaultDisplayValue(dataItem.wasteToBeDelivered, 'number') |
                                    number:'.3-3' }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Max dedicated storage (m3)' | translate}}" format="n3">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control type="number" format="n3" [decimals]="3"
                                        [(ngModel)]="dataItem.wasteMaximumCapacity" [entity]="dataItem" property="wasteMaximumCapacity"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ defaultDisplayValue(dataItem.wasteMaximumCapacity, 'number') |
                                    number:'.3-3' }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Amount of waste retained on board (m3)' | translate}}" format="n3">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control type="number" format="n3" [decimals]="3"
                                        [(ngModel)]="dataItem.amountOfWasteRetainedOnBoard" [entity]="dataItem" property="amountOfWasteRetainedOnBoard"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ defaultDisplayValue(dataItem.amountOfWasteRetainedOnBoard, 'number') |
                                    number:'.3-3' }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Port remaining waste will be delivered' | translate}}"
                                [width]="200">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control type="codelist" codelist="Location"
                                        [(ngModel)]="dataItem.remainingWasteDeliveryPortId" [entity]="dataItem" property="remainingWasteDeliveryPortId"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.remainingWasteDeliveryPortId | codelist:'Location' | async }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Estimate amount to be generated till next port (m3)' | translate}}">
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                    <app-control type="number" format="n3" [decimals]="3"
                                        [(ngModel)]="dataItem.estimatedGeneratedWaste" [entity]="dataItem" property="estimatedGeneratedWaste"></app-control>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ defaultDisplayValue(dataItem.estimatedGeneratedWaste, 'number') |
                                    number:'.3-3' }}
                                </ng-template>
                            </kendo-grid-column>
                        </app-editable-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>