<ngb-accordion activeIds="hazmat-info">
    <ngb-panel id="hazmat-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>IMO FAL 7 - DPG information</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="mb-3">
                <h3 translate>Dangerous Cargo Contact Information</h3>
            </div>
            <div class="mb-2">
                <strong translate>Address from which detailed information on the polluting and dangerous cargo may be obtained</strong>
            </div>
            <div class="row contactInfo">
                <div class="col">
                    <app-control label='Contact Name' [maxlength]="70" [isDisabled]="!canEdit || !editMode"
                        [(ngModel)]="model.dpgContactName" [entity]="model" property="dpgContactName"></app-control>
                </div>
                <div class="col">
                    <app-control label='Phone Number' pattern="[\d]" prepend="+" [maxlength]="20"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.dpgContactPhone" [entity]="model" property="dpgContactPhone"></app-control>
                </div>
                <div class="col">
                    <app-control label='Email' [maxlength]="70" pattern="[\w\d\.\-\_\@]" [isDisabled]="!canEdit || !editMode"
                        [(ngModel)]="model.dpgContactEmail" [entity]="model" property="dpgContactEmail"></app-control>
                </div>
                <div class="col">
                    <app-control label='Fax' pattern="[\d]" prepend="+" [maxlength]="20" [isDisabled]="!canEdit || !editMode"
                        [(ngModel)]="model.dpgContactFax" [entity]="model" property="dpgContactFax"></app-control>
                </div>
                <div class="col">
                    <app-control label='Location' type="codelist" codelist="Location"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.dpgContactLocationId" [entity]="model" property="dpgContactLocationId"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <app-control label='DPG List on Board' [isDisabled]="!canEdit || !editMode"
                        [(ngModel)]="model.dpgListOnBoard" [entity]="model" property="dpgListOnBoard"></app-control>
                </div>
            </div>
            <hr class="my-3"/>
            <div class="row" *ngIf="!editMode && ['D','A','W','R'].includes(model.statusId)">
                <div class="col">
                    <app-control label='Classification' type="codelist" codelist="CargoRegulation"
                        [isDisabled]="false" [(ngModel)]="filter.cargoRegulationId" [entity]="filter" property="cargoRegulationId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Stowage Position' [isDisabled]="false"
                        [(ngModel)]="filter.stowagePosition" [entity]="filter" property="stowagePosition"></app-control>
                </div>
                <div class="col">
                    <app-control label='Package Type' type="codelist" codelist="PackagingType"
                        [isDisabled]="false" [(ngModel)]="filter.packageTypeId" [entity]="filter" property="packageTypeId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Class' type="codelist" codelist="ImdgClass" [isDisabled]="false"
                        [(ngModel)]="filter.imdgClassId" [entity]="filter" property="imdgClassId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Port of Loading' type="codelist" codelist="Location" [isDisabled]="false"
                        [(ngModel)]="filter.portOfLoadingId" [entity]="filter" property="portOfLoadingId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Port of Discharge' type="codelist" codelist="Location" [isDisabled]="false"
                        [(ngModel)]="filter.portOfDischargeId" [entity]="filter" property="portOfDischargeId"></app-control>
                </div>
            </div>
            <div class="row mt-3 mb-3" *ngIf="!editMode && ['D','A','W','R'].includes(model.statusId)">
                <div class="col">
                    <div class="float-right">
                        <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
                            <fa-icon class="ml-1" icon="trash"></fa-icon>&nbsp;<span class="btn-text" translate>Clear</span>
                        </button>
                        <button class="btn btn-sm btn-primary" (click)="search()">
                            <fa-icon class="ml-1" icon="search"></fa-icon>&nbsp;<span class="btn-text" translate>Search</span>
                        </button>
                    </div>
                </div>
            </div>
            <ngb-accordion activeIds="dpg-cargo-totals" *ngIf="!editMode && dpgData?.length > 0">
                <ngb-panel id="dpg-cargo-totals">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Dangerous Cargo Gross Mass Totals</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col">
                                <kendo-grid [pageable]="false" [data]="dpgData">
                                    <kendo-grid-column field="class" title="{{'Class' | translate}}" ></kendo-grid-column>
                                    <ng-template ngFor let-unit [ngForOf]="units">
                                        <kendo-grid-column [field]="unit" [title]="unit">
                                            <ng-template kendoGridCellTemplate let-row>
                                                {{getUnitTotal(row, unit)}}
                                            </ng-template>
                                        </kendo-grid-column>
                                    </ng-template>
                                </kendo-grid>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mt-3">
                <h3 translate>Dangerous Cargo List</h3>
            </div>
            <app-entity-form [options]="formOptions" [grid]="hazmatGrid" [hasButtons]="false"></app-entity-form>
            <app-grid #hazmatGrid [data]="editMode ? model.dangerousGoods : data">
                <kendo-grid-column title="{{'Sequence number' | translate}}" field="sequenceNumber" [width]="75" [sticky]="true"></kendo-grid-column>
                <kendo-grid-column title="{{'Port of Loading' | translate}}" [width]="115">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.portOfLoadingId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Port of Discharge' | translate}}" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.portOfDischargeId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Stowage Position' | translate}}" field="stowagePosition" [width]="115"></kendo-grid-column>
                <kendo-grid-column title="{{'Reference Number' | translate}}" field="bookingReferenceNumber" [width]="75"></kendo-grid-column>
                <kendo-grid-column title="{{'Marks & Numbers' | translate}}" field="transportUnitNumber" [width]="130"></kendo-grid-column>
                <kendo-grid-column title="{{'Number of Packages' | translate}}" field="numberOfPackages" [width]="90"></kendo-grid-column>
                <kendo-grid-column title="{{'Type of Packages' | translate}}" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.packagingTypeId | codelist:'PackagingType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'UoM' | translate}}" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.unitOfMeasureId | codelist:'UnitOfMeasure' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Shipping Name' | translate}}" field="shippingName" [width]="160"></kendo-grid-column>
                <kendo-grid-column title="{{'EMS' | translate}}" field="ems" [width]="60"></kendo-grid-column>
                <kendo-grid-column title="{{'DG Classification' | translate}}" field="cargoRegulationId" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Class' | translate}}" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        <span *ngIf="!['IBC', 'IMSBC'].includes(row.cargoRegulationId)">{{row.imdgClassId | codelist:'ImdgClass' | async}}</span>
                        <span *ngIf="row.cargoRegulationId === 'IBC'">{{row.ibcCodeId | codelist:'IbcCode' | async}}</span>
                        <span *ngIf="row.cargoRegulationId === 'IMSBC'">{{row.imsbcCodeId | codelist:'ImsbcCode' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'UN No.' | translate}}" field="unNumber" [width]="70"></kendo-grid-column>
                <kendo-grid-column title="{{'Packing group' | translate}}" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.imdgPackingGroupId | codelist:'ImdgPackingGroup' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Subsidiary' | translate}}" field="subsidiaryRisks" [width]="90"></kendo-grid-column>
                <kendo-grid-column title="{{'Flashpoint (&#8451;)' | translate}}" field="flashpoint" [width]="115"></kendo-grid-column>
                <kendo-grid-column title="{{'Marine Pollutant' | translate}}" [width]="120">
                    <ng-template kendoGridCellTemplate let-row>
                        <span *ngIf="row.cargoRegulationId !== 'IMDG'">{{row.marpolCategoryId | codelist:'MarpolCategory' | async}}</span>
                        <span *ngIf="row.cargoRegulationId === 'IMDG'">{{row.imdgMarinePollutantId | codelist:'ImdgMarinePollutant' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Gross Mass' | translate}}" field="grossWeight" [width]="80"></kendo-grid-column>
                <kendo-grid-column title="{{'Net Mass' | translate}}" field="netWeight" [width]="80"></kendo-grid-column>
                <kendo-grid-column title="{{'Additional Information' | translate}}" field="additionalInformation" [width]="100"></kendo-grid-column>
                <kendo-grid-column #editColumn *ngIf="canEdit && editMode" [width]="60" [sticky]="true">
                    <ng-template kendoGridCellTemplate let-row>
                        <button class="btn btn-orange btn-xs" (click)="editRow(row)">
                            <fa-icon icon="edit"></fa-icon>
                        </button>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column #removeColumn *ngIf="canEdit && editMode" [width]="60" [sticky]="true">
                    <ng-template kendoGridCellTemplate let-row>
                        <button class="btn btn-danger btn-xs" (click)="removeRow(row)">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
