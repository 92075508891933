import { NgModule } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { Routes, RouterModule } from '@angular/router';
import { EditorModule } from '@progress/kendo-angular-editor';
import { VesselPermitListComponent } from './components/vessel-permit-list/vessel-permit-list.component';
import { VesselPermitViewComponent } from './components/vessel-permit-view/vessel-permit-view.component';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { VesselPermitGridComponent } from './components/vessel-permit-grid/vessel-permit-grid.component';
import { VesselPermitPrintComponent } from './components/vessel-permit-print/vessel-permit-print.component';

export const VESSEL_PERMIT_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselPermitListComponent
    },
    {
        path: 'view/:id',
        component: VesselPermitViewComponent,
        data: {
            mode: 'view'
        }
    },
    {
        path: 'edit/:id',
        component: VesselPermitViewComponent,
        data: {
            mode: 'edit'
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'create',
        component: VesselPermitViewComponent,
        data: {
            mode: 'create'
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: '**',
        redirectTo: 'list'
    },
    {
        path: 'print/:ids',
        outlet: 'print',
        component: VesselPermitPrintComponent,
    }
];

@NgModule({
    imports: [CommonModule, EditorModule],
    declarations: [VesselPermitListComponent, VesselPermitViewComponent, VesselPermitGridComponent, VesselPermitPrintComponent],
    providers: [CanDeactivateView],
    exports: [VesselPermitGridComponent]
})
export class VesselPermitComponentModule { }

@NgModule({
    imports: [CommonModule, VesselPermitComponentModule, RouterModule.forChild(VESSEL_PERMIT_ROUTES)],
})
export class VesselPermitModule { }
