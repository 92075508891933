import { Component, Input, OnInit } from '@angular/core';
import { User } from '@common/models/User';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-attachment',
    templateUrl: './vessel-notification-attachment.component.html',
    styleUrls: ['./vessel-notification-attachment.component.scss']
})
export class VesselNotificationAttachmentComponent implements OnInit {
    @Input() attachments: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;
    @Input() user: User;
    @Input() tabs: Boolean;
    @Input() title = 'Attachments';

    miscAttachments: any;

    ngOnInit(): void {
        this.miscAttachments = _.filter(this.attachments, (a) => a.attachmentTypeId === 'MISC');
    }
}
