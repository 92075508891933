<app-action-bar *ngIf="!isModal" [items]="actionBar"></app-action-bar>
<app-content-header *ngIf="!isModal" title='Chat Notes' [breadcrumb]="breadcrumb"></app-content-header>
<div class="card mt-4" *ngIf="!isModal">
    <div class="card-header">
        <h5>
            <fa-icon icon="list-alt"></fa-icon> {{'Results' | translate}}
        </h5>
    </div>
    <div class="card-body">
        <ng-container *ngTemplateOutlet="gridTemplate"></ng-container>
    </div>
</div>
<div class="modal-header" *ngIf="isModal">
    <h4 class="modal-title" translate>Chat Notes</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="isModal">
    <div *ngIf="isModal" class="row mb-2">
        <div class="col-2">
            <button class="btn btn-success" (click)="openNoteModal(null)" translate>Add new</button>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="gridTemplate"></ng-container>
</div>
<ng-template #gridTemplate>
    <app-grid [data]="query">
        <kendo-grid-column field="id" title="" [width]="60" >
            <ng-template kendoGridCellTemplate let-row>
                <button type="button" class="btn btn-sm btn-secondary" (click)="openNoteModal(row)" translate>
                    <fa-icon icon="eye"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="{{'Message Type' | translate}}" field="typeId">
            <ng-template kendoGridCellTemplate let-row>
                {{row.typeId | codelist:'ChatNoteType' | async}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="{{'To Organization' | translate}}" field="forOrganizationId">
            <ng-template kendoGridCellTemplate let-row>
                {{row.forOrganizationId | codelist:'Organization' | async}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="{{'To User' | translate}}" field="forUserId">
            <ng-template kendoGridCellTemplate let-row>
                {{row.forUserId | codelist:'User' | async}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="note" title="Note" [width]="400" >
        </kendo-grid-column>
        <kendo-grid-column title="{{'Vessel Notification' | translate}}" field="vesselNotificationId">
            <ng-template kendoGridCellTemplate let-row>
                <a class="text-primary" [routerLink]="['/vessel-notification/view', row.vesselNotificationId]">{{row.vesselNotification?.yearNumber}}
                </a>
            </ng-template>
        </kendo-grid-column>
    </app-grid>
</ng-template>
