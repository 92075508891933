<ngb-accordion activeIds="ballast-info">
    <ngb-panel id="ballast-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Ballast Water tanks</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row mt-2">
                <div class="col">
                    <app-control label='Ballast Water management plan on board?'
                        [(ngModel)]="model.ballastWaterPlanOnBoard" [entity]="model" property="ballastWaterPlanOnBoard" type="yesno"></app-control>
                </div>
                <div class="col">
                    <app-control label='Has this been implemented?'
                        [(ngModel)]="model.hasBeenImplemented" [entity]="model" property="hasBeenImplemented" type="yesno"></app-control>
                </div>
                <div class="col">
                    <app-control label='Total number of tanks on board?'
                        [(ngModel)]="model.ballastTanksOnBoard" [entity]="model" property="ballastTanksOnBoard" type="number"></app-control>
                </div>
                <div class="col">
                    <app-control label='Number of tanks in ballast' [(ngModel)]="model.tanksInBallast" [entity]="model" property="tanksInBallast"
                        type="number"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Number of tanks exchanged' [(ngModel)]="model.tanksExchanged" [entity]="model" property="tanksExchanged"
                        type="number">
                    </app-control>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-4">
                    <app-control label='Number of tanks not exchanged'
                        [(ngModel)]="model.tanksNotExchanged" [entity]="model" property="tanksNotExchanged" type="number"></app-control>
                </div>
            </div>
            <hr>
            <h4 translate>BALLAST WATER HISTORY LIST</h4>
            <app-entity-form [options]="formOptions" [grid]="ballastWatersGrid"></app-entity-form>
            <app-grid #ballastWatersGrid [data]="model.ballastWaters">
                <kendo-grid-column [width]="100" field="ballastTankTypeId"
                    title="{{'Ballast Water Tank Type' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.ballastTankTypeId | codelist:'BallastTankType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column-group title="{{'Ballast Water Source' | translate}}" headerClass="text-center">
                    <kendo-grid-column [width]="100" field="sourceDate" format="d"
                        title="{{'Source Date (dd/mm/yyyy)' | translate}}"></kendo-grid-column>
                    <kendo-grid-column [width]="100" field="sourcePortId" title="{{'Port' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            {{row.sourcePortId | codelist:'Location' | async}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="sourceLatitude" title="{{'Latitude' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="sourceLongitude" title="{{'Longitude' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="sourceVolume" title="{{'Volume' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="unitOfMeasureSourceId"
                        title="{{'Unit of Measurment (Source)' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            {{row.unitOfMeasureSourceId | codelist:'BallastWaterUnitOfMeasure' | async}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="sourceTemperature" title="{{'Temp.' | translate}}">
                    </kendo-grid-column>
                </kendo-grid-column-group>

                <kendo-grid-column-group title="{{'Ballast Water Exchange' | translate}}" headerClass="text-center">
                    <kendo-grid-column [width]="100" field="ballastWaterExchangeId"
                        title="{{'Ballast Water Exchange' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            {{row.ballastWaterExchangeId | codelist:'BallastWaterExchange' | async}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="exchangeDate" format="d"
                        title="{{'Exchange Date (dd/mm/yyyy)' | translate}}"></kendo-grid-column>
                    <kendo-grid-column [width]="100" field="endpointLatitudeLongitude"
                        title="{{'Endpoint Lat./Long.' | translate}}"></kendo-grid-column>
                    <kendo-grid-column [width]="100" field="exchangeVolume" title="{{'Volume' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="unitOfMeasureExchangeId"
                        title="{{'Unit of Measurment' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            {{row.unitOfMeasureExchangeId | codelist:'BallastWaterUnitOfMeasure' | async}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="exchange" title="{{'Exch. %' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="seaHeight" title="{{'SEA Hgt.' | translate}}">
                    </kendo-grid-column>
                </kendo-grid-column-group>

                <kendo-grid-column-group title="{{'Ballast Water Discharge' | translate}}" headerClass="text-center">
                    <kendo-grid-column [width]="100" field="dischargeDate" format="d"
                        title="{{'Discharge Date (dd/mm/yyyy)' | translate}}"></kendo-grid-column>
                    <kendo-grid-column [width]="100" field="dischargePortId"
                        title="{{'Port of Discharge' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            {{row.dischargePortId | codelist:'Location' | async}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="dischargeLatitude" title="{{'Latitude' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="dischargeLongitude" title="{{'Longitude' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="dischargeVolume" title="{{'Volume' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="unitOfMeasureDischargeId"
                        title="{{'Unit of Measurment' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            {{row.unitOfMeasureDischargeId | codelist:'BallastWaterUnitOfMeasure' | async}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="100" field="dischargeSalinity" title="{{'Salinity' | translate}}">
                    </kendo-grid-column>
                </kendo-grid-column-group>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="ballast-exchanges">
    <ngb-panel id="ballast-exchanges">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Ballast Water conducted exchanges</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row mt-2">
                <div class="col">
                    <app-control
                        label='If exchanges were not conducted, state other control action(s) taken'
                        [(ngModel)]="model.otherControlActions" [entity]="model" property="otherControlActions" type="textarea"></app-control>
                </div>
                <div class="col">
                    <app-control label='If none, state reason why not'
                        [(ngModel)]="model.controlActionsReasonIfNo" [entity]="model" property="controlActionsReasonIfNo" type="textarea"></app-control>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="ballast-imo">
    <ngb-panel id="ballast-imo">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>IMO Guidelines</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row mt-2">
                <div class="col-3">
                    <app-control label='IMO Guidelines on board?'
                        [(ngModel)]="model.imoGuidelinesOnBoard" [entity]="model" property="imoGuidelinesOnBoard" type="yesno"></app-control>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
