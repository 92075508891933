<ngb-accordion activeIds="accompanied-info">
    <ngb-panel id="accompanied-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>{{title}}</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-entity-form [options]="accompaniedFormOptions" [grid]="accompaniedVehicleGrid"></app-entity-form>
            <app-grid #accompaniedVehicleGrid [data]="model.accompaniedVehicles">
                <kendo-grid-column field="driverName" title="{{'Driver Name' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="vehicleType" title="{{'Vehicle Type' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="vehicleBrand" title="{{'Vehicle Brand' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="vehicleNoPlate" title="{{'Vehicle No. Plate' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="weightKgs" title="{{'Weight in Kgs' | translate}}">
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="accompanied-info">
    <ngb-panel id="accompanied-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Supporting Documents</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div *ngIf="tabs">
                <app-vessel-notification-accompanied-grid [accompanied]="model.accompanied" [canEdit]="canEdit"
                    [editMode]="false" [tabs]="tabs" [user]="user"></app-vessel-notification-accompanied-grid>
            </div>
            <div *ngIf="!tabs">
                <app-vessel-notification-accompanied-grid [accompanied]="model.accompanied" [canEdit]="canEdit"
                    [editMode]="editMode" [tabs]="tabs" [user]="user"></app-vessel-notification-accompanied-grid>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
