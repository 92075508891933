import { PrintService } from '@common/services/print.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { VesselPermitPermissions } from '@common/classes/permissions';
import { VesselPermitGridComponent } from '../vessel-permit-grid/vessel-permit-grid.component';
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';

@Component({
    selector: 'app-vessel-permit-list',
    templateUrl: './vessel-permit-list.component.html',
    styleUrls: ['./vessel-permit-list.component.css'],
    providers: [BreezeViewService]
})
export class VesselPermitListComponent extends AutoFilterBreezeListComponent implements OnInit {
    queryName = 'VesselPermits';
    parentRoute = '/vessel-permit';
    exportCommand = 'ExportVesselPermits';
    createPermission = VesselPermitPermissions.Action.create;
    @ViewChild(VesselPermitGridComponent) vesselPermitGrid;
    selectedTabIndex = 0;

    breadcrumb = [
        {
            icon: 'file',
            title: 'Vessel Permits'
        }
    ];

    constructor(
        protected breezeViewService: BreezeViewService,
        private printService: PrintService
    ) {
        super(breezeViewService);

        this.actionBar.push({
            label: this.translateService.instant('Archive'),
            items : [
                {
                    label: this.translateService.instant('Archive permit'),
                    icon: 'archive',
                    isVisible: () => this.user?.hasPermission(VesselPermitPermissions.Action.archive),
                    isDisabled: () => this.selection.length <= 0 || this.selectedTabIndex === 1,
                    onClick: () => this.archivePermit()
                }
            ],
        });

    }

    print() {
        if (!this.selection.length) {
            return;
        }

        this.isBusy = true;
        this.printService.printDocument(this.parentRoute, this.selection);
        this.isBusy = false;
    }

    archivePermit() {
        this.breezeViewService.handleCommand('ArchiveVesselPermits', {selectedIds: this.selection});
        this.search();
    }

    getDefaultFilter() {
        return {
            typeId: null,
            statusId: null,
            issuedDateFrom: null,
            issuedDateTo: null,
            validFrom: null,
            validTo: null,
            number: null,
            vesselId: null,
            imoNumber: null,
            mmsi: null,
            berthId: null,
            requestingOrganizationId: null,
            archived: this.selectedTabIndex === 1
        };
    }

    canExportExcel() {
        return this.user?.hasPermission(VesselPermitPermissions.Action.export);
    }

    export(exportType) {
        return super.export(exportType, this.vesselPermitGrid.appGrid);
    }

    canExportPdf() {
        return false;
    }

    ngOnInit() {
        this.filter = super.getFilter();
        this.filter.archived = this.selectedTabIndex === 1;
        this.initialize();
    }

    onTabSelect(tab) {
        this.selectedTabIndex = tab.index;
        this.filter.archived = tab.index === 1;
        this.search();
    }
}
