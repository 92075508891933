import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@common/services/user.service';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@common/services/dialog.service';
import { UserNotificationModalComponent } from '../user-notification-modal/user-notification-modal.component';
import { UserIdleService } from 'angular-user-idle';
import { firstValueFrom } from 'rxjs';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    username: string;
    password: string;
    isBusy = false;
    errorMessage: string;
    backgroundImgs = ['bg1.webp', 'bg2.webp', 'bg3.webp', 'bg4.webp'];
    chosenImage: string;
    showChangePassword = false;
    newPassword: string;
    repeatNewPassword: string;

    constructor(private userService: UserService,
        private router: Router,
        private httpClient: HttpClient,
        private dialogService: DialogService,
        private userIdleService: UserIdleService) {
        userService.isAuthenticatedSubject.next(false);
    }

    async signIn() {
        this.isBusy = true;
        this.errorMessage = null;
        let returnUrl = this.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';
        if (returnUrl.includes('login')) returnUrl = encodeURI('/task-manager'); // If returnUrl is login, we need to overwrite it

        if (!await this.checkSession()) return;

        try {
            const value = await this.userService.login(this.username, this.password);
            if (!value) {
                this.isBusy = false;
                this.showChangePassword = true;
            } else {
                const userRoles = (await this.userService.getCurrentUser()).allRoles;
                if (userRoles?.some(x => x.includes('VTS'))) returnUrl = '/vts/dashboard';
                const result = <any>await firstValueFrom(this.httpClient.post('api:///query/HasActiveNotifications', {
                    important: true
                }));
                this.isBusy = false;
                const res = await this.router.navigateByUrl(returnUrl);
                if (result) this.dialogService.open(UserNotificationModalComponent, { size: 'xl' });
                this.userIdleService.startWatching();
            }
        } catch (error) {
            this.isBusy = false;
            this.errorMessage = error;
        }
    }

    async checkSession(): Promise<boolean> {
        const data = {
            username: this.username,
            password: this.password
        };

        try {
            const isActive = await firstValueFrom(this.httpClient.post<any>('api:///command/CheckUserSession', data));
            if (!isActive) return true;

            return await this.dialogService.openConfirmDialog('Active session',
                `User "${data.username}" is already logged in. By confirming the log in the current session will end and all the unsaved data will be lost` +
                '<br/>' +
                'Do you want to continue?');
        } catch (error) {
            this.errorMessage = error.error?.validationErrors
                ? error.error?.validationErrors[0]?.errorMessage
                : error.message;
        } finally {
            this.isBusy = false;
        }
    }

    changePassword() {
        if (this.repeatNewPassword !== this.newPassword) {
            this.errorMessage = 'The repeated password is not the same';
            return;
        }

        const returnUrl = this.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';
        this.userService.firstLogin(this.username, this.newPassword).then((value) => {
            this.isBusy = false;
            this.router.navigate([returnUrl]);
        }).catch((errorMessage) => {
            this.isBusy = false;
            this.errorMessage = errorMessage;
        });
    }

    ngOnInit() {
        this.chosenImage = `/assets/img/background/${this.backgroundImgs[Math.floor(Math.random() * this.backgroundImgs.length)]}`;
    }
}
