<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title" translate>{{commandName ? (commandName + ' Vessel') : customTitle}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ngb-accordion activeIds="vessel-actions" *ngIf="commandName">
                <ngb-panel id="vessel-actions">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>{{commandName}} List</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="vesselActionOptions" [grid]="vesselActionsGrid"></app-entity-form>
                        <app-grid #vesselActionsGrid [data]="model.actions">
                            <kendo-grid-column *ngIf="!isDetain" title="{{'Banned By' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.bannedById | codelist:'VesselActionBy' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column *ngIf="!isDetain" title="{{'Banned Date' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.bannedDate | date:'short'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column *ngIf="!isDetain" field="banRemarks" title="{{'Ban Remarks' | translate}}"></kendo-grid-column>
                            <kendo-grid-column *ngIf="!isBan" title="{{'Detained By' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.detainedById | codelist:'VesselActionBy' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column *ngIf="!isBan" title="{{'Detained Date' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.detainedDate | date:'short'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column *ngIf="!isBan" field="detainRemarks" title="{{'Detain Remarks' | translate}}"></kendo-grid-column>
                            <kendo-grid-column title="{{'Released Date' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.releasedDate | date:'short'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="releaseRemarks" title="{{'Release Remarks' | translate}}"></kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="attachments">
                <ngb-panel id="attachments">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>Attachments</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="attachmentsOptions" [grid]="attachmentsGrid"></app-entity-form>
                        <app-grid #attachmentsGrid [data]="model.attachments">
                            <kendo-grid-column title="{{'File Name' | translate}}" field="attachment.name">
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Description' | translate}}" field="remarks"></kendo-grid-column>
                            <kendo-grid-column title="{{'Uploaded By' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.createdById | codelist:'User' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [width]="100" title="{{'Uploaded Date' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.createdDate | date:'short'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [width]="105" class="overflow-unset">
                                <ng-template kendoGridCellTemplate let-row>
                                    <app-upload-action-buttons [file]="row.attachment" [disabled]="true" [multiple]="false"></app-upload-action-buttons>
                                </ng-template>
                            </kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="close()" translate>Close</button>
            <button type="button" class="btn btn-primary" [disabled]="!canConfirm()" (click)="confirm()" translate>Confirm</button>
        </div>
    </ng-template>
</app-loader>

