import { Component, Input } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'app-flag-icon',
    template: `
    <span *ngIf="code && !codelist">
        <span class="fi fi-{{getCountry()}}" [ngClass]="{ 'raised box-shadow tight': raised }"></span>
    </span>
    <span *ngIf="code && codelist">
        <span class="fi fi-{{getCountry()}}" [ngClass]="{ 'raised box-shadow tight': raised }"></span><span>&nbsp;</span><span>{{code | codelist:'' + codelist | async}}</span>
    </span>
    <span *ngIf="!code">-</span>
    `,
    styleUrls: ['flag-icon.component.scss']
})
export class FlagIconComponent {
    @Input() code: string;
    @Input() codelist: string;
    @Input() raised = false;

    constructor() { }

    getCountry() {
        return this.code ? _.toLower(this.code.substring(0, 2)) : 'xx';
    }
}
