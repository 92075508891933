<ngb-accordion activeIds="passenger-info">
    <ngb-panel id="passenger-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>IMO FAL 6 - Passenger List Information</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row" *ngIf="canSeeFilterAndExport">
                <div class="col-3">
                    <app-control label='ID/Passport Number' [maxlength]="20" [isDisabled]="false"
                        [(ngModel)]="filter.documentNumber" [entity]="filter" property="documentNumber"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Given Name' [maxlength]="100" [isDisabled]="false"
                        [(ngModel)]="filter.givenName" [entity]="filter" property="givenName"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Family Name' [maxlength]="100" [isDisabled]="false"
                        [(ngModel)]="filter.familyName" [entity]="filter" property="familyName"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Nationality' type="codelist" codelist="CountryNationality"
                        [isDisabled]="false" [(ngModel)]="filter.nationalityId" [entity]="filter" property="nationalityId"></app-control>
                </div>
            </div>
            <div class="row mt-3 mb-3" *ngIf="canSeeFilterAndExport">
                <div class="col">
                    <div class="float-left ml-1" *ngIf="canExport()">
                        <button class="btn btn-sm btn-success" (click)="export()">
                            <fa-icon class="ml-1" icon="file-excel"></fa-icon> <span class="btn-text">{{'Export' | translate}}</span>
                        </button>
                    </div>
                    <div class="float-right mr-1">
                        <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
                            <fa-icon class="ml-1" icon="trash"></fa-icon> <span class="btn-text">{{'Clear' | translate}}</span>
                        </button>
                        <button class="btn btn-sm btn-primary" (click)="search()">
                            <fa-icon class="ml-1" icon="search"></fa-icon> <span class="btn-text">{{'Search' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <app-entity-form [options]="passengerOptions" [grid]="passengersGrid"></app-entity-form>
            <app-grid #passengersGrid [data]="editMode ? model.passengers : query"
                [selectable]="{ enabled: true, checkboxOnly: true }" [selection]="selection">
                <kendo-grid-checkbox-column *ngIf="!editMode" [width]="40" [showSelectAll]="true"></kendo-grid-checkbox-column>
                <kendo-grid-column title="{{'Sq.' | translate}}" field="sequenceNumber" [width]="50"></kendo-grid-column>
                <kendo-grid-column title="{{'Given Name' | translate}}" field="givenName" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Family Name' | translate}}" field="familyName" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Nationality' | translate}}" field="nationality.name" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.nationalityId | codelist:'CountryNationality' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Date of Birth' | translate}}" field="birthDate" format="d" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Birth Place' | translate}}" field="birthPlace" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Gender' | translate}}" field="gender.name" [width]="55">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.genderId | codelist:'Gender' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Nature of ID' | translate}}" field="documentType.name" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.documentTypeId | codelist:'DocumentType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Number of ID' | translate}}" field="documentNumber" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Issuing By' | translate}}" field="documentIssuingCountry.name" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.documentIssuingCountryId | codelist:'Country' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'ID Expiry Date' | translate}}" field="documentExpirationDate" format="d" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Port of Emb.' | translate}}" field="embarkationPort.name" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.embarkationPortId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Visa Nr.' | translate}}" field="permitNumber" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Port of Disemb.' | translate}}" field="disembarkationPort.name" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.disembarkationPortId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Special Care Or Assistance Information' | translate}}" field="specialCareOrAssistanceInformation" [width]="100">
                    <ng-template  kendoGridCellTemplate let-row>
                        {{row.specialCareOrAssistanceInformation ? row.specialCareOrAssistanceInformation : '-'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Emergency Contact Number' | translate}}" field="contactNumber" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.contactNumber ? ('+' + row.contactNumber) : ''}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-checkbox-column title="{{'Transit' | translate}}" field="transit" [width]="60">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.transit"></app-checkbox>
                    </ng-template>
                </kendo-grid-checkbox-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
