<table *ngIf="vesselPermits">
    <thead>
        <tr>
            <td>
                <div class="header-space"></div>
            </td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                <div class="content positioning">
                    <div class="permits" *ngFor="let vesselPermit of vesselPermits">
                        <div class="row dataRow">
                            <div class="col">
                                <p> Permit Ref No: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.referenceNumber }} </p>
                            </div>
                            <div class="col">
                                <p> Permit type: </p>
                            </div>
                            <div class="col">
                                <p>{{ vesselPermit.type.name }} </p>
                            </div>
                            <hr />
                        </div>
                        <div class="row dataRow">
                            <div class="col">
                                <p> To: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.vessel.name }} </p>
                            </div>
                            <div class="col">
                                <p> From: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.approvedById ? (vesselPermit.approvedBy?.fullName) : (vesselPermit.rejectedBy?.fullName) }} </p>
                            </div>
                            <hr />
                        </div>
                        <div class="row dataRow">
                            <div class="col">
                                <p> Company: </p>
                            </div>
                            <div class="col">
                                <p> {{vesselPermit.requestingOrganization.name}} </p>
                            </div>
                            <div class="col">
                            </div>
                            <div class="col">
                                <p>{{'Transport Malta'}}</p>
                            </div>
                            <hr />
                        </div>
                        <div class="row dataRow">
                            <div class="col">
                                <p> Agent e-mail: </p>
                            </div>
                            <div class="col">
                                {{ vesselPermit.createdBy.email }}
                            </div>
                            <div class="col">
                                <p> Official's e-mail: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.approvedById ? vesselPermit.approvedBy?.email : vesselPermit.rejectedBy?.email }} </p>
                            </div>
                            <hr />
                        </div>
                        <div class="row dataRow">
                            <div class="col">
                                <p> Date of issue: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.validFromDate | date:'short' }} </p>
                            </div>
                            <div class="col">
                                <p> Validity of permit: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.validToDate | date:'short' }} </p>
                            </div>
                            <hr />
                        </div>
                        <div class="row dataRow">
                            <div class="col">
                                <p> Description of proposed works: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.proposedWorks }} </p>
                            </div>
                            <div class="col">
                                <p> Location: </p>
                            </div>
                            <div class="col">
                                <p> {{ vesselPermit.berth.name }} </p>
                            </div>
                            <hr />
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <p> <b> Comments: </b> </p>
                            </div>
                        </div>
                        <div class="row">
                            <p [innerHTML]="vesselPermit.description"> {{ vesselPermit.description }} </p>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p> <b> Remarks: </b> </p>
                            </div>
                        </div>
                        <div class="row">
                            <p [innerHTML]="vesselPermit.remarks"> {{ vesselPermit.remarks }} </p>
                        </div>
                        <div class="row">
                            <p [innerHTML]="vesselPermit.chargeDescription"> {{ vesselPermit.chargeDescription }} </p>
                        </div>
                        <!-- <div class="row" *ngIf="vesselPermit.chargeDescription2">
                            <div class="col">
                                <p> <b> Amendment charge: </b> </p>
                            </div>
                        </div>
                        <div class="row">
                            <p [innerHTML]="vesselPermit.chargeDescription2"> {{ vesselPermit.chargeDescription2 }} </p>
                        </div> -->
                        <div class="row" *ngIf="vesselPermit.approvedById">
                            <div class="col">
                                <p> <b> {{ vesselPermit.approvedBy.fullName }} </b> </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="vesselPermit.approvedById">
                            <div class="col">
                                <p> Port Officer Operations </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="vesselPermit.approvedById">
                            <div class="col">
                                <p> Ports And Yachting Directorate </p>
                            </div>
                        </div>
                        <div class="watermark" *ngIf="vesselPermit.statusId === 'CAN'">
                            <h1 class="cancelled-text">
                                CANCELLED
                            </h1>
                        </div>
                        <div class="watermark" *ngIf="vesselPermit.statusId === 'REV'">
                            <h1 class="cancelled-text">
                                REVOKED
                            </h1>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td>
                <div class="footer-space"></div>
            </td>
        </tr>
    </tfoot>
</table>
<div class="header positioning">
    <div class="leftHeader">
        <img id="leftLogo" alt="logo" />
    </div>
    <div class="rightHeader">
        <img id="rightLogo" alt="logo" />
    </div>
</div>
<div class="footer positioning">Malta Transport Centre, Xatt l-Ghassara ta` L-Gheneb, Marsa MRS 1917</div>
