import { Component, Input, AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, OnChanges, Output, EventEmitter, Renderer2, ApplicationRef, NgZone } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DetailTemplateDirective, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { GridEditService } from '@common/services/grid-edit.service';
import _ from 'lodash';
import { AppGridComponent } from '../app-grid/app-grid.component';

@Component({
    selector: 'app-editable-grid',
    templateUrl: './editable-grid.component.html',
    styleUrls: ['./editable-grid.component.scss'],
    providers: [GridEditService]
})
export class EditableGridComponent extends AppGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() entityName: string;
    @Input() editMode: boolean;
    @Input() canAddNew = true;
    @Input() canDelete = true;
    @Input() canEdit = true;
    @Input() pageSize;

    @Output() rowAction = new EventEmitter();
    @Output() save = new EventEmitter<SaveEvent>();
    @Output() remove = new EventEmitter<RemoveEvent>();

    constructor(changeDetectorRef: ChangeDetectorRef,
        breezeViewService: BreezeViewService,
        renderer: Renderer2,
        applicationRef: ApplicationRef,
        zone: NgZone,
        public gridEdit: GridEditService) {
        super(changeDetectorRef, breezeViewService, renderer, applicationRef, zone);
    }

    public get detailTemplate(): DetailTemplateDirective {
        return this.detailTemplateChildren?.first || undefined;
    }

    ngOnInit() {
        this.gridEdit.entityManager = this.entityManager;
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.grid.detailTemplate = this.detailTemplate;
        this.updateColumns();
    }

    updateColumns() {
        const columns = this.columns?.toArray() || [];
        if (this.editMode) {
            this.commandColumns.forEach(commandColumn => columns.push(commandColumn));
        }
        this.grid.columns.reset(columns);
        this.changeDetectorRef.detectChanges();
    }

    expandAllDetails() {
        this.gridEdit.expandAllDetails(this.grid);
    }

    collapseAllDetails() {
        this.gridEdit.collapseAllDetails(this.grid);
    }

    onSave(event: SaveEvent) {
        this.rowAction.emit();
        this.gridEdit.saveHandler(event);
        this.save.emit(event);
    }

    onRemove(event: RemoveEvent) {
        this.gridEdit.removeHandler(event);
        this.rowAction.emit();
        this.remove.emit(event);
    }
}
