import { WebsocketsService } from '@common/services/websockets.service';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivationStart } from '@angular/router';
import { UserService } from '@common/services/user.service';
import { TitleService } from '@common/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { SplitterComponent } from '@progress/kendo-angular-layout';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { User } from '@common/models/User';
import _ from 'lodash';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [BreezeViewService]
})
export class AppComponent implements AfterViewInit {
    isAuthenticated = false;
    currentUser: User;
    throttledUserActive = _.throttle(() => this.websocketService.setUserActive(this.currentUser), 60_000);
    @ViewChild('consoleSplitter', { static: false }) consoleSplitter: SplitterComponent;

    constructor(
        private router: Router,
        private userService: UserService,
        titleService: TitleService,
        private toastrService: ToastrService,
        private translate: TranslateService,
        public consoleWindowService: ConsoleWindowService,
        private websocketService: WebsocketsService) {

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd)
                titleService.setTitle(this.getDeepestTitle(this.router.routerState.snapshot.root));
        });

        router.events.pipe(filter(event =>
            event instanceof ActivationStart &&
            event.snapshot.data.permissions &&
            (!_.isArray(event.snapshot.data.permissions) || event.snapshot.data.permissions.length > 0))
        ).subscribe((event) => {
            const permissions = (event as ActivationStart).snapshot.data.permissions;

            if (!this.currentUser.hasPermission(permissions)) {
                console.dir(event);
                console.error('Authorization failed.', this.currentUser, permissions);
                this.toastrService.error(this.translate.instant('Unauthorized'));

                this.router.navigate(['/401'], { queryParams: { returnUrl: this.getResolvedUrl((event as ActivationStart).snapshot) } });
            }
        });

        this.userService.isAuthenticatedSubject.subscribe((value) => this.isAuthenticated = value);

        this.userService.currentUserSubject.subscribe((value) => {
            this.currentUser = value;

            if (this.currentUser) {
                try {
                    const lang = this.currentUser.language.id.toLowerCase();
                    this.translate.use(lang);
                } catch { }
            }
        });
    }

    @HostListener('mousemove')
    onMousemove() {
        this.throttledUserActive();
    }

    private getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot): string {
        let title = routeSnapshot.data ? routeSnapshot.data['title'] : '';

        if (routeSnapshot.firstChild)
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;

        return title;
    }

    ngAfterViewInit() {
        this.consoleWindowService.initialize(this.consoleSplitter);
    }
}
