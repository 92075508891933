<div class="modal-header">
    <h4 class="modal-title" translate>Send</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col text-center">
            <label *ngIf="model.notificationTypeId === 'A'" translate>By confirming the arrival vessel notification, the following IMO FAL Forms will be generated:</label>
            <label *ngIf="model.notificationTypeId === 'D'" translate>By confirming the departure vessel notification, the following IMO FAL Forms will be generated:</label>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <label translate>General Declaration</label>
        </div>
    </div>
    <div class="row" *ngIf="model?.dangerousGoods?.length > 0">
        <div class="col text-center">
            <label translate>Dangerous Goods Manifest</label>
        </div>
    </div>
    <div class="row" *ngIf="model?.cargo?.length > 0">
        <div class="col text-center">
            <label translate>Cargo Declaration</label>
        </div>
    </div>
    <div class="row" *ngIf="model?.storeProducts?.length > 0">
        <div class="col text-center">
            <label translate>Ship's Stores Declaration</label>
        </div>
    </div>
    <div class="row" *ngIf="model?.workers?.length > 0">
        <div class="col text-center">
            <label translate>Crew List</label>
        </div>
    </div>
    <div class="row" *ngIf="model?.workerEffects?.length > 0">
        <div class="col text-center">
            <label translate>Crew Effect's Declaration</label>
        </div>
    </div>
    <div class="row" *ngIf="model?.passengers?.length > 0">
        <div class="col text-center">
            <label translate>Passenger List</label>
        </div>
    </div>
    <div class="row" *ngIf="!!model?.mdhIssuedAt">
        <div class="col text-center">
            <label translate>Model of Maritime Declaration of Health</label>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <app-control label='Remarks' type="textarea" [(ngModel)]="remarks" [entity]="" property="remarks" [isDisabled]="false"></app-control>
        </div>
    </div>
    <div class="row mt-3" style="width: 980px;">
        <div class="col" translate>
            All the data and information that is being submitted shall be processed in line with the requirements, as transposed, of Directive 2010/65/EU on reporting formalities for ships arriving in and/or departing from ports of the Member States establishing the national single window and the “reporting only once” principle; of Directive 98/41/EC on the counting and registration of persons sailing on board passenger ships operating to or from ports of the Member States of the Community, as amended; and Directive (EU) 2019/883 on port reception facilities for the delivery of waste from ships.
            <br/><br/>
            The data and information shall be submitted to the Union Maritime Information and Exchange System, SafeSeaNet, in line with the statutory obligations of the Authority as per aforementioned Directives and shall be viewed by the European Maritime Safety Agency (EMSA) and other Member States.  Furthermore, such data and information shall be accessible to the other Maltese national entities.
            <br/><br/>
            Any commercial information will be dealt with and exchanged in a confidential manner.  Personal data will be processed in terms of the provisions of the GDPR (Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and the free movement of such data).
            <br/><br/>
            All the necessary mechanisms and precautionary measures are in place to ensure the confidentiality and protection of the data and information being submitted.
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary confirmButton" (click)="confirm()">{{'Send' | translate}}</button>
</div>
