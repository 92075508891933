import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { BaseVesselNotificationViewComponent } from '@common/classes/base-vessel-notification-view';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { ChatNotesListComponent } from 'app/chat-notes/chat-notes-list/chat-notes-list.component';
import { DetainedVesselAttachmentListModalComponent } from 'app/vessel/components/detained-vessel/detained-vessel-modal/detained-vessel-modal.component';
import _ from 'lodash';
import { ChangeAgencyModalComponent } from '../change-agency-modal/change-agency-modal.component';
import { CreateDepartureModalComponent } from '../create-departure-modal/create-departure-modal.component';

@Component({
    selector: 'app-vessel-notification-view',
    templateUrl: './vessel-notification-view.component.html',
    styleUrls: ['./vessel-notification-view.component.scss'],
    providers: [BreezeViewService]
})
export class VesselNotificationViewComponent extends BaseVesselNotificationViewComponent {
    entityName = 'VesselNotification';
    editPermission = VesselNotificationPermissions.Action.edit;
    createPermission = VesselNotificationPermissions.Action.create;
    override isShort = false;

    breadcrumb = [
        {
            icon: 'file-alt',
            title: 'Vessel Notifications',
            route: `${this.parentRoute}list/`
        },
        {
            icon: 'file-alt',
            title: 'Vessel Notification'
        }
    ];

    constructor(breezeViewService: BreezeViewService,
        dialogService: DialogService,
        location: Location) {
        super(breezeViewService, dialogService, location);

        this.editActionBarGroup.items.forEach(item => {
            const isDisabled = _.isFunction(item.isDisabled) ? item.isDisabled : () => false;
            item.isDisabled = () => isDisabled() || this.disableDetained();
        });

        this.actionBar.push({
            label: 'Create',
            items: [
                {
                    label: 'Create departure',
                    icon: 'sign-out-alt',
                    isVisible: () => this.canCreateDeparture(),
                    isDisabled: () => this.disableDetained(true),
                    onClick: () => this.createDeparture()
                },
                {
                    label: this.translateService.instant('Create Vessel Permit'),
                    icon: 'file-alt',
                    isVisible: () => this.canCreateVesselPermit(),
                    isDisabled: () => (this.model.vesselVisit ? ['CA', 'DE', 'ZX'].includes(this.model.vesselVisit.statusId) : ['X', 'Z'].includes(this.model.statusId)),
                    onClick: () => this.createVesselPermit(),
                },
                {
                    label: this.translateService.instant('Create Conveyance'),
                    icon: 'file-alt',
                    isVisible: () => this.canCreateConveyance(),
                    onClick: () => this.createConveyance(),
                },
                {
                    label: 'Create Shifting',
                    icon: 'play',
                    isVisible: () => this.canCreateVesselShift(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.createVesselShift()
                },
                {
                    label: 'Add. Agent Rights',
                    icon: 'exchange-alt',
                    isVisible: () => this.canChangeAgency(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.changeAgency()
                }
            ]
        },
        {
            label: 'Upload',
            items: [
                {
                    label: 'Excel upload',
                    icon: 'book',
                    isVisible: () => this.canExcelUpdate(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.excelUpdate(),
                }
            ]
        },
        {
            label: 'ATA',
            items: [
                {
                    label: this.translateService.instant('Insert ATA'),
                    icon: 'sign-in-alt',
                    isVisible: () => this.canArrive(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.complete('ata')
                },
            ]
        },
        {
            label: this.translateService.instant('ATD'),
            items: [
                {
                    label: 'Insert ATD',
                    icon: 'sign-out-alt',
                    isVisible: () => this.canDepart(),
                    isDisabled: () => this.disableDetained(false, this.user.isPort()),
                    onClick: () => this.complete('atd')
                }
            ]
        },
        {
            label: 'Vessel',
            items: [
                {
                    label: 'Ban Vessel',
                    icon: 'ban',
                    isVisible: () => this.canBanVessel(),
                    onClick: () => this.executeVesselAction('Ban'),
                },
                {
                    label: 'Detain Vessel',
                    icon: 'lock',
                    isVisible: () => this.canDetainVessel(),
                    onClick: () => this.executeVesselAction('Detain'),
                },
                {
                    label: 'Release Vessel',
                    icon: 'lock-open',
                    isVisible: () => this.canRealeaseVessel(),
                    onClick: () => this.executeVesselAction('Release'),
                },
                {
                    label: 'Overhang',
                    icon: 'anchor',
                    isVisible: () => this.canOverhangVessel(),
                    isDisabled: () => this.model.overhanging,
                    onClick: () => this.overhangVessel()
                },
                {
                    label: 'Lay-Up',
                    icon: 'hourglass',
                    isVisible: () => this.canLayUpVessel(),
                    isDisabled: () => this.model.laidUp,
                    onClick: () => this.layUpVessel(),
                }
            ]
        });
    }

    getIdentifier() {
        return this.model.yearNumber + (this.isBanned() ? ' (Banned)' : this.isDetained() ? ' (Detained)' : '');
    }

    // Dialog logic in child class
    confirm() {
        this.saveChanges({ redirectToList: true }, null, null, null, true);
    }

    override async initialize() {
        if (!this.cloneId && !this.cloneForDepartureId) {
            await super.initialize();
            if (this.model && this.user.isAgent() && (!this.isPrimaryAgent() ||
                (this.model.notificationTypeId === 'D' && this.changeAgencies.filter(x => x.moduleTypeId === 'DP').some(x => x.organizationToId !== this.user.organizationId)))) {
                const isAdditionalAgent = this.model.additionalAgents.some(x => x.agentId === this.user.organizationId);
                const actions = ['Edit', 'Save', 'Undo changes', 'Cancel changes', 'Remove', 'Create departure', 'Create Vessel Permit', 'Create Conveyance', 'Create Shifting', 'Add. Agent Rights'];
                this.actionBar.forEach(x => x.items.forEach(item => {
                    const res = actions.includes(`${item.label}`) &&
                        (isAdditionalAgent ? true : this.changeAgencies.some(x => this.matchAgencyType(x, item) && x.organizationToId === this.user.organizationId));
                    const itemRes = _.isFunction(item.isVisible) ? item.isVisible() : item.isVisible;
                    item.isVisible = itemRes && res;
                }));
            }
            return;
        }

        const cloneData = this.cloneData ? JSON.parse(this.cloneData) : null;
        const cloneMethodName = this.cloneId
            ? 'CloneVesselNotification'
            : 'CloneVesselNotificationForDeparture';

        const data = await this.breezeViewService.handleCommand(cloneMethodName, { id: this.cloneId || this.cloneForDepartureId, ...cloneData }, true)
            .catch(e => {
                this.toastrService.error(e?.error?.validationErrors?.[0]?.errorMessage);
                this.location.back();
            });
        if (!!this.cloneForDepartureId && !cloneData.voyage) {
            data.cargoGrossWeight = null;
            data.portOfCallEtd = null;
            // data.voyageIn = null;
            // data.voyageOut = null;
        }

        this.model = this.entityManager.createEntity(this.entityName, data);
        this.mapBreezeCloneProperties();
        this.model.entityAspect.loadNavigationProperty('vessel');
    }

    isEditDisabled() {
        return this.isDetained() &&
            this.user.isAgent() &&
            this.model.statusId !== 'D';
    }

    canEdit() {
        if (this.model.statusId === 'U') {
            return this.user.hasPermission(this.editPermission) &&
                (this.isPrimaryAgent() || this.user.isSystemUser);
        }

        if (((this.user.isAgent() && (this.isPrimaryAgent() ||
            this.model.additionalAgents.some(x => x.agentId === this.user.organizationId)))
            || this.user.isSecurity() || this.user.isInspection()) && this.model.statusId === 'W') {
            return true;
        }

        if (this.model.statusId === 'DP' && this.model.notificationTypeId === 'D')
            return true;

        if (this.model.statusId === 'AR' && this.model.notificationTypeId === 'A')
            return true;

        if (this.model.statusId === 'A') return true;

        if (!this.user.hasPermission(VesselNotificationPermissions.Action.editAllStatus) && this.model.statusId !== 'D')
            return false;

        if (['X', 'Z'].includes(this.model.statusId)) return false;

        const vnPermission = _.find(this.model.permissions, o => o.organizationId === this.user.organizationId);
        return vnPermission
            ? _.chain((<BreezeEntity>vnPermission).entityType.dataProperties)
                .filter((p) => p.name.startsWith('edit'))
                .some(p => vnPermission[p.name])
                .value()
            : this.user.hasPermission(this.editPermission);
    }

    canDiscard() {
        return this.viewMode && this.model.statusId === 'D'
            && this.user.hasPermission(VesselNotificationPermissions.Action.discard)
            && (this.isPrimaryAgent() || (this.user.isPort() || this.user.isSystemUser)); // Only primary agent can discard
    }

    canClone() {
        return this.viewMode && this.model.notificationTypeId !== 'D' &&
            (!this.user.isAgent() || this.isPrimaryAgent()) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.clone);
    }

    send72h() {
        return this.executeCommand({ commandName: 'SendPreArrivalVesselNotification', data: { id: this.model.id }, refreshData: false });
    }

    canApprove() {
        return this.viewMode &&
            this.model.statusId === 'W' &&
            (
                this.user.hasPermission(VesselNotificationPermissions.Action.approve) ||
                this.user.hasPermission(VesselNotificationPermissions.Action.approveAll)
            ) &&
            (this.user.isPort() || (this.user.isPolice() && this.model.portOfCallId !== 'MTWTW') || this.user.isSystemUser ||
                (this.user.isCustoms() && this.model.notificationTypeId === 'D') || ((this.user.isInspection() || this.user.isSecurity()) && this.model.notificationTypeId === 'A'));
    }

    disableApproveReject() {
        return (this.user.isPort() && (this.model.portApprovalDate || this.model.portRejectionDate)) ||
            (this.user.isCustoms() && this.model.customsApprovalDate) ||
            (this.user.isSecurity() && this.model.iSPSApprovalDate) ||
            (this.user.isPolice() && this.model.policeApprovalDate) ||
            (this.user.isInspection() && this.model.inspectionApprovalDate);
    }

    approve() {
        if (this.model.notificationTypeId === 'D') {
            if (this.user?.isPort() && !this.model.vesselVisit.arrivalVesselNotification.portApprovalDate ||
                this.user?.isPolice() && !this.model.vesselVisit.arrivalVesselNotification.policeApprovalDate) {
                return this.dialogService.warning('Cannot approve', `The arrival notification (${this.model.vesselVisit.arrivalVesselNotification.yearNumber}) must be approved first.`);
            }

            const conveyances = (this.model.conveyances.concat(this.model.vesselVisit.arrivalVesselNotification.conveyances)).filter(x => x.statusId === 'W');
            if (this.user?.isPort() && conveyances.some(x => !x.portApprovalDate) ||
                this.user?.isPolice() && conveyances.some(x => !x.policeApprovalDate) ||
                this.user?.isCustoms() && conveyances.some(x => !x.customsApprovalDate)) {
                return this.dialogService.warning('Cannot approve', `The departure notification (${this.model.vesselVisit.departureVesselNotification.yearNumber}) has outstanding conveyances that need approval.`);
            }
        }

        this.executeAction('Approve');
    }

    async createDeparture() {
        const yesNoRes = await this.dialogService.yesNo(
            this.translateService.instant('Create Departure Notification'),
            this.translateService.instant('Would you like to transfer the data from Arrival Notification to Departure Notification?')
        );
        if (!yesNoRes) return;

        const data = await this.dialogService.open(CreateDepartureModalComponent, { size: 'lg' });
        if (!data) return;

        this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { cloneForDeparture: this.model.id, cloneData: JSON.stringify(data) } });
    }

    canCreateDeparture() {
        const changeAgencyDeparture = this.getChangeAgency('DP');
        if (this.user.isAgent() && this.model.agentId !== this.user.organizationId && !changeAgencyDeparture) {
            const vnPermission = _.find(this.model.permissions, o => o.organizationId === this.user.organizationId);
            if (!vnPermission || !vnPermission.createDeparture) { return false; }
        }

        return this.viewMode &&
            this.model.vesselVisit?.departureVesselNotificationId == null &&
            this.model.notificationTypeId === 'A' &&
            ['A', 'W', 'AR'].includes(this.model.statusId) &&
            ((changeAgencyDeparture ? changeAgencyDeparture.organizationToId === this.user.organizationId : this.model.agentId === this.user.organizationId) || this.user.isSystemUser) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.createDeparture);
    }

    canCreateVesselPermit() {
        const changeAgencyPermit = this.getChangeAgency('VP');
        return this.user.hasPermission(VesselNotificationPermissions.Action.createVesselPermit)
            && this.viewMode
            && ((this.isPrimaryAgent() ||  changeAgencyPermit?.organizationToId === this.user.organizationId) || this.user.isSystemUser);
    }

    canCreateConveyance() {
        const changeAgencyConveyance = this.getChangeAgency('CN');
        return ['D', 'A', 'W', 'AR'].includes(this.model.statusId)
            && this.user.hasPermission(VesselNotificationPermissions.Action.createConveyance)
            && this.viewMode
            && ((this.isPrimaryAgent() || changeAgencyConveyance?.organizationToId === this.user.organizationId) || this.user.isSystemUser);
    }

    async createConveyance() {
        this.router.navigate(['conveyance/create/'], { queryParams: { vesselNotificationId: this.model.id } });
    }

    canCreateVesselShift() {
        const changeAgencyShift = this.getChangeAgency('VS');
        return this.viewMode
            && this.model.notificationTypeId === 'A'
            && ['D', 'A', 'W', 'AR'].includes(this.model.statusId)
            && this.user.hasPermission(VesselNotificationPermissions.Action.createVesselShift)
            && ((changeAgencyShift ? changeAgencyShift.organizationToId === this.user.organizationId : this.isPrimaryAgent()) || this.user.isSystemUser);
    }

    createVesselShift() {
        if (this.model.vesselShifts.filter(vs => !['C', 'X'].includes(vs.statusId)).length > 0) {
            this.toastrService.error(this.translateService.instant('Next shifting must be created from the latest shifting notification.'));
        } else {
            this.router.navigate(['vessel-shift/create/'], { queryParams: { vesselNotificationId: this.model.id } });
        }
    }

    canChangeAgency() {
        return this.viewMode &&
            ['W', 'A', 'AR'].includes(this.model.statusId) &&
            this.model.notificationTypeId === 'A' &&
            ((this.user.isAgent() &&
                (this.changeAgencies.some(x => x.organizationToId === this.user.organizationId) ||
                    (this.isPrimaryAgent() && this.changeAgencies.length < 4))) ||
                this.user.isSystemUser);
    }

    async changeAgency() {
        const result = await this.dialogService.open(ChangeAgencyModalComponent, { size: 'lg' }, dialogRef => {
            dialogRef.componentInstance.agentId = this.user.isSystemUser ? this.model.agentId : this.user.organizationId;
            dialogRef.componentInstance.changeAgenciesList = this.changeAgencies?.filter(ac => ac.active && ac.organizationToId !== this.model.agentId);
            dialogRef.componentInstance.statusId = this.model.statusId;
        });
        if (!result) return;

        await this.executeCommand({
            commandName: 'ChangeAgency', data: {
                vesselNotificationId: this.model.id,
                organizationFromId: this.user.isSystemUser ? (this.changeAgencies?.length > 0 ? this.changeAgencies.at(-1).organizationToId : this.model.agentId) : this.user.organizationId,
                organizationToId: result.organizationToId,
                moduleTypeId: result.moduleTypeId
            }
        });
        return await this.router.navigate([`${this.parentRoute}/list/`]);
    }

    canReject() {
        return this.viewMode &&
            this.model.statusId === 'W' &&
            (
                this.user.hasPermission(VesselNotificationPermissions.Action.reject) ||
                this.user.hasPermission(VesselNotificationPermissions.Action.rejectAll)
            );
    }

    canExcelUpdate() {
        return this.viewMode &&
            (['D', 'W', 'A'].includes(this.model.statusId) ||
                (this.model.statusId === 'DP' && this.model.notificationTypeId === 'D' ||
                    this.model.statusId === 'AR' && this.model.notificationTypeId === 'A')) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.excelUpdate);
    }

    override async saveChanges(options?: any, entityManager = this.entityManager, entityName = this.entityName, model = this.model, silent = false) {
        if (!silent && !await this.openEMSAWarning(this.model.ssnMessages)) { return; }
        return super.saveChanges(options, entityManager, entityName, model);
    }

    beforeSave(entities: BreezeEntity[]) {
        this.model.numberOfCrew = this.model.workers?.length || this.model.numberOfCrew;
        this.model.numberOfPax = this.model.passengers?.length || this.model.numberOfPax;
        super.beforeSave(entities);
    }

    disableDetained(arrivalDisable = false, isPort = false) {
        return (this.isDetained()) &&
            (arrivalDisable || this.model.notificationTypeId === 'D') &&
            (isPort || this.user.isAgent());
    }

    async executeVesselAction(commandName: string) {
        const res = await this.dialogService.open(DetainedVesselAttachmentListModalComponent, {
            size: 'xl'
        }, dialogRef => {
            dialogRef.componentInstance.mode = ['Detain', 'Ban'].includes(commandName) && !(this.isDetained() || this.isBanned()) ? ViewMode.create : ViewMode.edit;
            dialogRef.componentInstance.commandName = commandName;
            dialogRef.componentInstance.vesselId = this.model.vessel.currentRevisionId;
        });
        if (!res) return;

        await this.fetch();
    }

    canOverhangVessel() {
        return this.viewMode &&
            ['A', 'AR', 'DP'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.overhang);
    }

    overhangVessel() {
        this.executeCommand({
            commandName: 'CreateVtsEventFromVN', data: {
                id: this.model.id,
                notificationTypeId: this.model.notificationTypeId,
                eventType: 'OVR'
            }, refreshData: true
        });
    }

    canLayUpVessel() {
        return this.viewMode &&
            ['A', 'AR', 'DP'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.layUp);
    }

    layUpVessel() {
        this.executeCommand({
            commandName: 'CreateVtsEventFromVN', data: {
                id: this.model.id,
                notificationTypeId: this.model.notificationTypeId,
                eventType: 'LUP'
            }, refreshData: true
        });
    }

    matchAgencyType(changeAgencyItem, actionBarItem) {
        switch (_.isFunction(actionBarItem.label) ? actionBarItem.label() : actionBarItem.label) {
            case 'Create departure':
                return changeAgencyItem.moduleTypeId === 'DP';
            case 'Create Vessel Permit':
                return changeAgencyItem.moduleTypeId === 'VP';
            case 'Create Conveyance':
                return changeAgencyItem.moduleTypeId === 'CN';
            case 'Create Shifting':
                return changeAgencyItem.moduleTypeId === 'VS';
            default:
                return true;
        }
    }

    openChatNotesModal() {
        this.dialogService.open(ChatNotesListComponent, {
            windowClass: 'full-screen-modal',
            scrollable: true,
            backdrop: true
        }, dialogRef => {
            dialogRef.componentInstance.vesselNotificationId = +this.id;
            dialogRef.componentInstance.isModal = true;
        });
    }
}
