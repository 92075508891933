<nav class="navbar fixed-top navbar-toggleable-md navbar-dark nav-shadow nav-background">
    <div>
        <span *ngIf="user" class="toggle-menu mr-3" (click)="onToggleSidebar()">
            <fa-icon icon="bars" aria-hidden="true"></fa-icon>
        </span>
        <a class="navbar-brand" routerLink="{{homeUrl}}">
            <img src="/assets/img/togoLogo.png" alt="{{'PCS Togo' | translate}}">
            <span></span>
        </a>
    </div>
    <div *ngIf="environmentName" class="setting simple-pill" [ngClass]="environmentName">
        {{ environmentName }}
    </div>
    <div class="ml-auto mr-5">
        <button id="dark-theme-toggle" class="btn-link" (click)="toggleDarkMode()">
            <fa-icon icon="adjust" class="mr-1" [ngClass]="{'text-white':dark}"></fa-icon>
            <span class="text-primary">{{dark ? 'Switch to light mode' : 'Switch to dark mode'}}</span>
        </button>
    </div>
    <div *ngIf="user" ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block language mr-3">
        <button class="btn-link" id="dropdownBasic1" ngbDropdownToggle>
            <img class="languageIcon" src="/assets/img/language.svg"/>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" (click)="changeDisplayLanguage('en')">
                <img class="flag" src="/assets/img/united-states.png"/>&nbsp;EN - English
            </button>
            <div tabindex="-1" class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="changeDisplayLanguage('fr')">
                <img class="flag" src="/assets/img/frLogo.png"/>&nbsp;FR - Français
            </button>
            <div tabindex="-1" class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="changeDisplayLanguage('es')">
                <img class="flag" src="/assets/img/esLogo.png"/>&nbsp;ES - Espagnol
            </button>
        </div>
    </div>

    <div *ngIf="user">
        <div ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block user">
            <button class="btn-link user-dd-trigger" id="dropdownBasic1" ngbDropdownToggle>
                <span class="initials">{{user.fullName | initials}}</span>
                <span class="name ml-1 mr-1">{{ user.fullName ? user.fullName : user.userName }}</span>
                <span>{{ user?.organization?.name ? '(' + user.organization.name + ')' : ''}}</span>
                <fa-icon icon="angle-down" class="text-primary"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <a class="dropdown-item" routerLink="user-account"><fa-icon icon="user"></fa-icon>&nbsp;{{'Account' | translate}}</a>
                <div tabindex="-1" class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="logout()"><fa-icon icon="sign-out-alt"></fa-icon>&nbsp;{{'Sign Out' | translate}}</button>
            </div>
        </div>
    </div>
</nav>
