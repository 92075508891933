import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, EntityManager, EntityQuery, SaveOptions } from '@cime/breeze-client';
import { EntitiesErrors } from '@common/classes/entities-errors';
import { EntitiesErrorsComponent } from '@common/components/entities-errors/entities-errors.component';
import { ErrorDialogComponent } from '@common/components/error-dialog.component';
import { ViewMode } from '@common/models/view-mode';
import { BreezeService } from '@common/services/breeze.service';
import { CqrsService } from '@common/services/cqrs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ConsoleWindowService } from './console-window.service';
import { UserService } from './user.service';
import { DialogService } from './dialog.service';

@Injectable()
export class BreezeViewService {
    entityManager: EntityManager;

    constructor(entityManager: EntityManager,
        public dialogService: DialogService,
        public toastrService: ToastrService,
        public translateService: TranslateService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public breezeService: BreezeService,
        public userService: UserService,
        public consoleWindowService: ConsoleWindowService,
        private modalService: NgbModal,
        private cqrs: CqrsService,
        private injector: Injector) {
        this.entityManager = entityManager.createEmptyCopy();
    }

    isViewMode() {
        return this.activatedRoute.snapshot.data.mode === ViewMode.view;
    }

    isEditMode() {
        return !this.isViewMode();
    }

    openModal(content: any, options: NgbModalOptions = {}) {
        return this.modalService.open(content, { ...options, injector: this.injector });
    }

    handleQuery(queryName: string, postData = {}, cacheData = true) {
        const query = new EntityQuery(queryName)
            .using(this.entityManager)
            .withParameters({ $method: 'POST', $data: postData });

        return query.execute()
            .then((response) => response.results)
            .catch(e => {
                console.error(e);
                this.toastrService.error(this.translateService.instant('An error occured while saving data'));
                throw e;
            }).finally(() => !cacheData && this.entityManager.clear());
    }

    handleCommand(commandName, data, silent = false) {
        return this.cqrs.command<any>(commandName, data)
            .then((result) => {
                if (!silent) this.toastrService.success(this.translateService.instant('Success'));
                return result;
            })
            .catch(e => {
                console.error(e);
                if (!silent) this.toastrService.error(this.translateService.instant('An error occured while saving data'));
                throw e;
            });
    }

    executeCommand(commandName: string, data: any, tracking = true, errorPopup = false, silent = true) {
        let query = EntityQuery.from(commandName)
            .using(this.entityManager)
            .withParameters({ $method: 'POST', $data: data });

        if (!tracking) query = query.noTracking();

        query.dataService = new DataService({
            serviceName: `${environment.apiUrl}/command/`,
            hasServerMetadata: false
        });

        return query.execute()
            .then((result) => {
                if (!silent) this.toastrService.success(this.translateService.instant('Success'));
                return result;
            }).catch(error => {
                const valError = JSON.parse(error?.httpResponse?.data);
                if (valError && errorPopup) {
                    const dialogRef = this.modalService.open(ErrorDialogComponent, { size: 'lg' });
                    dialogRef.componentInstance.titleText = 'An error has occured';
                    dialogRef.componentInstance.isServerSideError = true;
                    dialogRef.componentInstance.setMessage(valError.errorMessage, valError.validationErrors);
                }
                const entityErrors = this.breezeService.processServerErrors(this.entityManager, error);
                if (entityErrors.length) {
                    this.consoleWindowService.open(EntitiesErrorsComponent, [
                        { provide: EntitiesErrors, useValue: new EntitiesErrors(entityErrors) },
                        { provide: BreezeViewService, useValue: this },
                    ]);
                }

                throw error;
            });
    }

    saveChanges(resourceName: string, model: any, extraData?: any) {
        const saveOptions = new SaveOptions({
            resourceName,
            tag: extraData,
            dataService: new DataService({
                serviceName: `${environment.apiUrl}/command/`
            })
        });

        const entities = this.breezeService.getEntites(model);
        _.forEach(entities, entity => this.breezeService.clearServerValidationErrors(entity.entityAspect));
        this.consoleWindowService.close();

        return this.entityManager.saveChanges(undefined, saveOptions).then((result) => result).catch((error) => {
            const entityErrors = _.chain(entities)
                .map((entity) => this.breezeService.getEntityErrors(entity))
                .flatten()
                .value();
            if (entityErrors.length) {
                this.consoleWindowService.open(EntitiesErrorsComponent, [
                    { provide: EntitiesErrors, useValue: new EntitiesErrors(entityErrors) },
                    { provide: BreezeViewService, useValue: this },
                ]);
            }

            throw error;
        });
    }

    onMultiSelectChange(ids: string[], model: any, collectionName: string, propIdName: string, entityName: string, mainEntityName: string) {
        const modelIds = _.map(model[collectionName], (o, i) => o[propIdName]);
        // Add
        _.each(_.difference(ids, modelIds), id => {
            model[collectionName].push(model.entityAspect.entityManager
                .createEntity(entityName, {
                    [propIdName]: id,
                    [mainEntityName]: model.id
                }));
            model.entityAspect.validateEntity();
        });
        // Remove
        _.each(_.difference(modelIds, ids), id => {
            const entity = _.find(model[collectionName], o => o[propIdName] === id);
            entity.entityAspect.setDeleted();
            model.entityAspect.validateEntity();
        });
    }
}
