<ngb-accordion activeIds="details">
    <ngb-panel id="details">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>A list of submitted declarations</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-grid [data]="tabs">
                <kendo-grid-column field="name" title="{{'Tab' | translate}}" [width]="150">
                    <ng-template kendoGridCellTemplate let-row>
                        <span *ngIf="row.name !== 'Crew'">
                            <a *ngIf="isTabVisible(row.index)"
                                [ngClass]="{'text-success': isFilled(row.property), 'text-warning': !isFilled(row.property)}"
                                (click)="onTabSelect(row)">{{row.name | translate}}</a>
                        </span>
                        <span *ngIf="row.name === 'Crew'">
                            <a *ngIf="isTabVisible(row.index)"
                                [ngClass]="{'text-success': isFilled('workers') && isFilled('workerEffects'), 'text-warning': !isFilled('workers') || !isFilled('workerEffects')}"
                                (click)="onTabSelect(row)">{{row.name | translate}}</a>
                        </span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="form" title="{{'IMO FAL Forms' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <span *ngIf="row.name !== 'Crew'">
                            <a [ngClass]="{'text-success': isFilled(row.property), 'text-warning': !isFilled(row.property)}"
                                (click)="viewForm(row)">{{row.form | translate}}</a>
                        </span>
                        <span *ngIf="row.name === 'Crew'">
                            <a [ngClass]="{'text-success': isFilled('workers'), 'text-warning': !isFilled('workers')}"
                                (click)="viewForm(row, 'CrewList')" translate>Crew list, </a>
                            <a [ngClass]="{'text-success': isFilled('workerEffects'), 'text-warning': !isFilled('workerEffects')}"
                                (click)="viewForm(row, 'CrewEffects')" translate>Crew Effect's Declaration</a>
                        </span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [width]="80">
                    <ng-template kendoGridCellTemplate let-row>
                        <fa-icon *ngIf="isFilled(row.property) && row.name !== 'Crew'" class="text-primary" icon="check"></fa-icon>
                        <fa-icon *ngIf="!isFilled(row.property) && row.name !== 'Crew'" class="text-primary" icon="times"></fa-icon>
                        <fa-icon *ngIf="isFilled('workers') && row.name === 'Crew'" class="text-primary" icon="check"></fa-icon>
                        <fa-icon *ngIf="!isFilled('workers') && row.name === 'Crew'" class="text-primary" icon="times"></fa-icon>
                        <fa-icon *ngIf="isFilled('workerEffects') && row.name === 'Crew'" class="text-primary ml-3" icon="check"></fa-icon>
                        <fa-icon *ngIf="!isFilled('workerEffects') && row.name === 'Crew'" class="text-primary ml-3" icon="times"></fa-icon>
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>