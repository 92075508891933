import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router, NavigationStart, NavigationError, NavigationEnd, NavigationCancel, PreloadAllModules } from '@angular/router';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DataServiceWebApiAdapter } from '@cime/breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from '@cime/breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from '@cime/breeze-client/adapter-uri-builder-json';
import { AjaxHttpClientAdapter } from '@cime/breeze-client/adapter-ajax-httpclient';
import { config, EntityManager, NamingConvention } from '@cime/breeze-client';
import { FluentValidators } from '@common/breeze-validators';

import { CommonModule } from '@common/common.module';
import { UserService } from '@common/services/user.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ROUTES } from './app.routing';

import localeMt from '@angular/common/locales/en-MT';
import { registerLocaleData } from '@angular/common';

import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en-MT/all';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';

import { AjaxPostWrapper } from '@cime/breeze-client/adapter-ajax-post';
import { mixinEntityGraph } from '@cime/breeze-client/mixin-get-entity-graph';
import { environment } from '../environments/environment';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { ConsoleContentDirective } from '@common/directives/console-content.directive';
import { DialogErrorHandler } from '@common/handlers/dialog-error-handler';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { DocumentsComponent } from './home/documents/documents.component';
import { TaskManagerComponent } from './home/task-manager/task-manager.component';
import { UserIdleModule } from 'angular-user-idle';
import { VesselNotificationComponentModule } from './vessel-notification/vessel-notification.module';
import { VesselShortNotificationComponentModule } from './vessel-short-notification/vessel-short-notification.module';
import { ConveyanceComponentModule } from './conveyance/conveyance.module';
import { VesselPermitComponentModule } from './vessel-permit/vessel-permit.module';
import { VesselShiftComponentModule } from './vessel-shift/vessel-shift.module';


// the second parameter 'fr' is optional
registerLocaleData(localeMt, 'en-MT');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

let entityManager: EntityManager;

function setupBreeze() {
    mixinEntityGraph(EntityManager);

    NamingConvention.camelCase.setAsDefault();

    const breezeMetadataUrl = 'api:///query';
    entityManager = new EntityManager(breezeMetadataUrl);
}

setupBreeze();

export function getEntityManager() {
    return entityManager;
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ConsoleContentDirective,
        DocumentsComponent,
        TaskManagerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        VesselNotificationComponentModule,
        VesselShortNotificationComponentModule,
        ConveyanceComponentModule,
        VesselPermitComponentModule,
        VesselShiftComponentModule,
        RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules }),

        IntlModule,
        ToastrModule.forRoot(),
        LoadingBarModule,

        NgbModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        CommonModule,
        UserIdleModule.forRoot({ idle: 600, timeout: 0, ping: 3600 })
    ],
    exports: [
        BrowserAnimationsModule,
        RouterModule,
        ToastrModule,
        LoadingBarModule,
        NgbModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'en-MT' },
        {
            provide: EntityManager,
            useFactory: getEntityManager,
        },
        UserService,
        ConsoleWindowService,
        { provide: ErrorHandler, useClass: DialogErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        router: Router,
        private loadingBarService: LoadingBarService,
        translate: TranslateService,
        http: HttpClient,
        modalConfig: NgbModalConfig,
        library: FaIconLibrary
    ) {
        // the order is important
        ModelLibraryBackingStoreAdapter.register();
        UriBuilderJsonAdapter.register();
        AjaxHttpClientAdapter.register(http);
        DataServiceWebApiAdapter.register();
        AjaxPostWrapper.wrapAjax(config.getAdapterInstance('ajax'));

        FluentValidators.registerAsBreezeValidators(s => translate.instant(s));

        library.addIconPacks(fas);
        library.addIconPacks(far);

        modalConfig.backdrop = 'static';

        const routerSate = this.loadingBarService.useRef('router');
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                routerSate.start();
            } else if (event instanceof NavigationError) {
                routerSate.complete();
                console.error(event.error);
            } else if (event instanceof NavigationEnd) {
                routerSate.complete();
            } else if (event instanceof NavigationCancel) {
                routerSate.complete();
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(environment.defaultLanguage);
    }
}
