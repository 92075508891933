import { Component } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

@Component({
    selector: 'app-import-ihs-vessel-modal',
    templateUrl: './import-ihs-vessel-modal.component.html',
    styleUrls: ['./import-ihs-vessel-modal.component.scss']
})
export class ImportIhsVesselModalComponent extends AbstractBreezeListComponent {
    queryName = 'IhsVessels';
    selectedVessel = null;
    filter: any = {};

    constructor(public activeModal: NgbActiveModal,
        protected breezeViewService: BreezeViewService) {
        super(breezeViewService);
    }

    initialize() {
        // disable init search
    }

    canSearch() {
        return !!(this.filter.imoNumber || this.filter.name || this.filter.mmsi || this.filter.callSign);
    }

    getQuery(state?: State) {
        return super.getQuery(state)
            .noTracking();
    }

    close() {
        this.activeModal.close(false);
    }

    canImport() {
        return !!this.selectedVessel;
    }

    async import() {
        this.isBusy = true;
        try {
            const result = await this.breezeViewService.executeCommand('ImportIhsVessel', {
                imoNumber: this.selectedVessel.ihslRorImoShipNo
            });
            this.activeModal.close(result.results[0]);
        }
        finally {
            this.isBusy = false;
        }
    }

    onSelectionChange(event: SelectionEvent) {
        this.selectedVessel = event.selectedRows.length ? event.selectedRows[0].dataItem : null;
    }
}
