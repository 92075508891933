<app-loader [isBusy]="!model || isBusy || !vesselShifts">
    <div class="row separator"></div>
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
        <div class="card mt-3">
            <div class="card-body">
                <div class="row mb-3 mr-1" *ngIf="createMode">
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto" translate>Arrival</strong>
                            <div class="col-auto" [appPill]="{ status: model.vesselNotification.statusId, module: 'vesselNotification', muted: true }">
                                {{model.vesselNotification.statusId | codelist:'VesselNotificationStatus' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong>
                                    <a [routerLink]="['/vessel-notification/view', model.vesselNotification.id]"
                                        target="_blank">{{ model.vesselNotification.yearNumber }}</a>
                                </strong>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong class="bluify" translate>Agent: </strong>
                                {{ model.vesselNotification.agentId | codelist:model.vesselNotification:'agentId'|async }}
                            </div>
                        </div>
                        <div class="row mt-2 ml-1">
                            <div class="col">
                                <strong>TM</strong>
                                <app-rej-icon [item]="model" organizationName="" moduleType="VesselShift"></app-rej-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto" translate>Shifting Details</strong>
                            <div class="col-auto" [appPill]="{ status: model.statusId, module: 'vesselShift', muted: true }">
                                {{model.statusId | codelist:'VesselShiftStatus' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong>{{ getIdentifier() }}</strong>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <strong class="bluify" translate>Berth shifting from: </strong>
                                {{model.berthShiftingFromId | codelist:'Berth' | async}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify" translate>Berth shifting to: </strong>
                                {{model.berthShiftingToId | codelist:'Berth' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <strong class="bluify" translate>Call purpose: </strong>
                                {{ model.vesselNotification.primaryCallPurposeId | codelist:model.vesselNotification:'primaryCallPurposeId'|async }}
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow" *ngIf="model.vesselNotification?.vesselVisitId">
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto" translate>Visit</strong>
                            <div class="col-auto mr-2"
                                [appPill]="{ status: model.vesselNotification?.vesselVisit.statusId, module: 'vesselVisit', muted: true }">
                                {{model.vesselNotification?.vesselVisit.statusId | codelist:'VesselVisitStatus' | async}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong class="bluify" translate> Vessel Visit: </strong>
                                <strong>
                                    <a [routerLink]="['/vessel-visit/view', model.vesselNotification?.vesselVisitId]">
                                        {{ model.vesselNotification?.vesselVisit.yearNumber }}</a>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div class="col ml-2 simple-container box-shadow inactive"
                        *ngIf="!model.vesselNotification?.vesselVisitId">
                        <strong class="orangify capitalize" translate>Visit</strong>
                    </div>
                    <div class="col ml-2 simple-container box-shadow">
                        <div class="badge box-shadow tight"
                            ngbTooltip="{{ model.vesselNotification.vessel.countryId | codelist:model.vesselNotification.vessel:'countryId'|async }}">
                            <span>{{ model.vesselNotification.vessel.countryId }}</span>
                            <app-flag-icon [code]="model.vesselNotification.vessel.countryId"></app-flag-icon>
                        </div>
                        <div class="row mt-1">
                            <strong class="orangify capitalize col-auto" translate> Vessel information </strong>
                            <strong class="col-auto">
                                <a target="_blank" [href]="['https://www.marinetraffic.com/en/ais/details/ships/imo:' + model.vesselNotification.vessel.iMONumber]">
                                    ({{ model.vesselNotification.vessel.iMONumber }})</a>
                            </strong>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                <strong>
                                    <a [routerLink]="['/vessels/view', model.vesselNotification.vesselId]"
                                        target="_blank">
                                        {{ model.vesselNotification.vesselId | codelist:'Vessel' | async }}</a>
                                </strong>
                                <fa-icon *ngIf="model.vesselNotification.vessel?.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                            </div>
                            <div *ngIf="isRestricted(model.vesselNotification.vessel?.statusId)" class="badge box-shadow tight" style="font-size: 16px;"
                                ngbTooltip="{{isRestricted(model.vesselNotification.vessel?.statusId, true)}}" placement="right">
                                <fa-icon icon="lock" class="redIcon"></fa-icon>
                            </div>
                            <div class="col-auto">
                                ({{model.vesselNotification.vessel.typeId | codelist:'VesselType' | async}})
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify" translate>MMSI: </strong>{{ model.vesselNotification.vessel.mmsi }}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify" translate>LOA: </strong>
                                {{ !!model.vesselNotification.vessel.length ?
                                    model.vesselNotification.vessel.length + ' m' : '/' }}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify" translate>Gross Tonnage: </strong>
                                {{ model.vesselNotification.vessel.grossTonnage }}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-auto">
                                <strong class="bluify" translate>Net Tonnage: </strong>
                                {{ !!model.vesselNotification.vessel.netTonnage ?
                                    model.vesselNotification.vessel.netTonnage : '/' }}
                            </div>
                        </div>
                    </div>
                </div>
                <ngb-accordion activeIds="vessel-info" *ngIf="model.vesselNotification">
                    <ngb-panel id="vessel-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Vessel information</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Vessel Name' [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotification.vessel.name" [entity]="model.vesselNotification.vessel" property="name">
                                        <div class="input-group-append">
                                            <a class="input-group-text"
                                                [routerLink]="['/vessels/view', model.vesselNotification.vessel.id]">
                                                <fa-icon icon="link"></fa-icon>
                                            </a>
                                        </div>
                                    </app-control>
                                </div>
                                <div class="col">
                                    <app-control label='IMO Number' format="'#'" [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotification.vessel.iMONumber" [entity]="model.vesselNotification.vessel" property="iMONumber"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='MMSI Number' format="'#'" [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotification.vessel.mmsi" [entity]="model.vesselNotification.vessel" property="mmsi"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Call Sign' [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotification.vessel.callSign" [entity]="model.vesselNotification.vessel" property="callSign"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Vessel Type' [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotification.vessel.typeId" [entity]="model.vesselNotification.vessel" property="typeId"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Vessel Flag' [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotification.vessel.countryId" [entity]="model.vesselNotification.vessel" property="countryId">
                                    </app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="agent-info">
                    <ngb-panel id="agent-info">
                        <ng-template ngbPanelHeader>
                            <h5 translate>Agent information</h5>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <strong translate>Please select the requesting agent for vessel shifting</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <app-control label='Requesting agent' type="codelist" codelist="Agent"
                                    [isDisabled]="user?.isAgent() || !editMode"
                                    (ngModelChange)="onRequestingAgentChange($event)"
                                        [(ngModel)]="model.requestingAgentId" [entity]="model" property="requestingAgentId"></app-control>
                                </div>
                                <div class="col-2" *ngIf="model.requestingAgent">
                                    <app-control label='Requesting agent email' [isDisabled]="true"
                                        [(ngModel)]="model.requestingAgent.email" [entity]="model.requestingAgent" property="email"></app-control>
                                </div>
                                <div class="col-2" *ngIf="model.requestingAgent">
                                    <app-control label='Requesting agent phone' pattern="[\d]"
                                        prepend="+" [(ngModel)]="model.requestingAgent.phone" [entity]="model.requestingAgent" property="phone" [isDisabled]="true">
                                    </app-control>
                                </div>
                                <div class="col-3" *ngIf="model.requestingAgent">
                                    <app-control label='Requesting agent address' [isDisabled]="true"
                                        [(ngModel)]="model.requestingAgent.address" [entity]="model.requestingAgent" property="address"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="contractor-agent-info">
                    <ngb-panel id="contractor-agent-info">
                        <ng-template ngbPanelHeader>
                            <h5 translate>Additional agent information</h5>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <strong translate>Please select the additional agent for the vessel
                                        shifting</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <app-control label='Additional agent' type="codelist" codelist="Agent"
                                        (ngModelChange)="onContractorAgentChange($event)"
                                        [(ngModel)]="model.contractorAgentId" [entity]="model" property="contractorAgentId"></app-control>
                                </div>
                                <div class="col-2" *ngIf="model.contractorAgent">
                                    <app-control label='Additional agent email' [isDisabled]="true"
                                        [(ngModel)]="model.contractorAgent.email" [entity]="model.contractorAgent" property="email"></app-control>
                                </div>
                                <div class="col-2" *ngIf="model.contractorAgent">
                                    <app-control label='Additional agent phone' prepend="+"
                                        [isDisabled]="true" [(ngModel)]="model.contractorAgent.phone" [entity]="model.contractorAgent" property="phone"></app-control>
                                </div>
                                <div class="col-3" *ngIf="model.contractorAgent">
                                    <app-control label='Additional agent address' [isDisabled]="true"
                                        [(ngModel)]="model.contractorAgent.address" [entity]="model.contractorAgent" property="address"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="vessel-visit">
                    <ngb-panel id="vessel-visit">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Vessel Notification Information</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Vessel Notification' type="codelist"
                                        codelist="DraftVesselNotification" [fetchOnOpen]="true"
                                        [fetch]="fetchNotifications" [isDisabled]="true"
                                        [(ngModel)]="model.vesselNotificationId" [entity]="model" property="vesselNotificationId">
                                    </app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="vessel-shift" *ngIf="model.vesselNotification">
                    <ngb-panel id="vessel-shift">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Shifting information</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Port of Call (Togo)' type="codelist" codelist="Location" [isDisabled]="true"
                                        [(ngModel)]="!!model.vesselNotification.vesselVisit ? (model.revisionNumber > 0 && vesselShifts ? vesselShifts[this.model.revisionNumber]?.portOfShiftingId : model.vesselNotification.vesselVisit.portId) : model.vesselNotification.portOfCallId" [entity]="!!model.vesselNotification.vesselVisit ? (model.revisionNumber > 0 && vesselShifts ? vesselShifts[this.model.revisionNumber]?.portOfShiftingId : model.vesselNotification.vesselVisit.portId) : model.vesselNotification" property="portOfCallId">
                                    </app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Berth/Area Shifting From' type="codelist" codelist="Berth" [isDisabled]="true"
                                        [(ngModel)]="model.berthShiftingFromId" [entity]="model" property="berthShiftingFromId"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Shifting From' type="datetime" [time]="true"
                                        [(ngModel)]="model.shiftingDate" [entity]="model" property="shiftingDate"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Port of Shifting (Malta)' type="codelist" codelist="LocalPort"
                                        [(ngModel)]="model.portOfShiftingId" [entity]="model" property="portOfShiftingId"
                                        (ngModelChange)="onPortOfCallChange($event)">
                                    </app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Berth/Area Shifting To' type="codelist" [fetchOnOpen]="true"
                                        [fetch]="fetchBerths" [(ngModel)]="model.berthShiftingToId" [entity]="model" property="berthShiftingToId"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Shifting To' type="datetime" [time]="true"
                                        [(ngModel)]="model.portOfCallEtd" [entity]="model" property="portOfCallEtd"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Purpose of Shifting' type="textarea"
                                        [(ngModel)]="model.purposeOfShifting" [entity]="model" property="purposeOfShifting"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Remarks' type="textarea"
                                        [(ngModel)]="model.remarks" [entity]="model" property="remarks"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Terminal Operator' type="codelist"
                                        codelist="TerminalOperator" [(ngModel)]="model.terminalOperatorId" [entity]="model" property="terminalOperatorId">
                                    </app-control>
                                </div>
                                <div class="col">
                                    <app-control label='First Bollard' [(ngModel)]="model.firstBollard" [entity]="model" property="firstBollard"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Last Bollard' [(ngModel)]="model.lastBollard" [entity]="model" property="lastBollard"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Side at Berth' type="codelist" codelist="BerthType"
                                        [(ngModel)]="model.berthTypeId" [entity]="model" property="berthTypeId">
                                    </app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <app-control label='Actual Draught Bow (metres)' type="number" format="n2"
                                        [decimals]="2" [(ngModel)]="model.actualDraughtBow" [entity]="model" property="actualDraughtBow"></app-control>
                                </div>
                                <div class="col-2">
                                    <app-control label='Actual Draught Stern (metres)' type="number" format="n2"
                                        [decimals]="2" [(ngModel)]="model.actualDraughtStern" [entity]="model" property="actualDraughtStern"></app-control>
                                </div>
                            </div>
                            <hr class="my-3" />
                            <div class="row">
                                <div class="col">
                                    <app-control label='Any DPG on board?' type="yesno"
                                        [tooltipText]="getCheckboxTooltip('hazmat')" [(ngModel)]="model.hazmat" [entity]="model" property="hazmat">
                                    </app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Any cargo on board?' type="yesno"
                                        [tooltipText]="getCheckboxTooltip('cargoOnBoard')"
                                        [(ngModel)]="model.cargoOnBoard" [entity]="model" property="cargoOnBoard"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Waste to be discharged?' type="yesno"
                                        [tooltipText]="getCheckboxTooltip('wasteToBeDischarged')"
                                        [(ngModel)]="model.wasteToBeDischarged" [entity]="model" property="wasteToBeDischarged"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Remarks' type="textarea"
                                        [(ngModel)]="model.hazmatRemarks" [entity]="model" property="hazmatRemarks"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Remarks' type="textarea"
                                        [(ngModel)]="model.cargoOnBoardRemarks" [entity]="model" property="cargoOnBoardRemarks"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Remarks' type="textarea"
                                        [(ngModel)]="model.wasteToBeDischargedRemarks" [entity]="model" property="wasteToBeDischargedRemarks"></app-control>
                                </div>
                            </div>
                            <hr class="my-3" />
                            <div class="row">
                                <div class="col" *ngIf="model.transportMaltaRemarks">
                                    <app-control label='Transport Malta Remarks' type="textarea"
                                        [isDisabled]="true" [(ngModel)]="model.transportMaltaRemarks" [entity]="model" property="transportMaltaRemarks"></app-control>
                                </div>
                                <div class="col" *ngIf="model.unconfirmRemarks">
                                    <app-control label='Unconfirm Remarks' type="textarea"
                                        [isDisabled]="true" [(ngModel)]="model.unconfirmRemarks" [entity]="model" property="unconfirmRemarks"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion *ngIf="model.vesselNotification?.vessel?.type?.tanker" activeIds="tanker-info">
                    <ngb-panel id="tanker-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Tanker Info</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <table class="table table-striped table-bordered mb-3">
                                <tbody>
                                    <tr>
                                        <td translate>Is vessel inerted?</td>
                                        <td>
                                            <app-control type="yesno" [isDisabled]="!canEditTankerInfo"
                                                [(ngModel)]="model.inertedVessel" [entity]="model" property="inertedVessel"></app-control>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>OXYGEN CONTENT (% by Volume)</td>
                                        <td>
                                            <app-control type="number" format="n2" [decimals]="2"
                                                [isDisabled]="!canEditTankerInfo" [(ngModel)]="model.inertGasSystemOxygen" [entity]="model" property="inertGasSystemOxygen"></app-control>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>MINIMUM POSITIVE PRESSURE (mmgh)</td>
                                        <td>
                                            <app-control type="number" format="n2" [decimals]="2"
                                                [isDisabled]="!canEditTankerInfo" [(ngModel)]="model.inertGasSystemMinimumPressure" [entity]="model" property="inertGasSystemMinimumPressure">
                                            </app-control>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>Is vessel GAS FREE?</td>
                                        <td>
                                            <app-control type="yesno" [isDisabled]="!canEditTankerInfo"
                                                [(ngModel)]="model.gasFree" [entity]="model" property="gasFree"></app-control>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>OXYGEN READING (% by Volume)</td>
                                        <td>
                                            <app-control type="number" format="n2" [decimals]="2"
                                                [isDisabled]="!canEditTankerInfo" [(ngModel)]="model.gasFreeOxygen" [entity]="model" property="gasFreeOxygen"></app-control>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>FLAMMABLE gases (%LFL)</td>
                                        <td>
                                            <app-control type="number" format="n2" [decimals]="2"
                                                [isDisabled]="!canEditTankerInfo" [(ngModel)]="model.flammableGasses" [entity]="model" property="flammableGasses"></app-control>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>Is vessel under vapours</td>
                                        <td>
                                            <app-control type="yesno" [isDisabled]="!canEditTankerInfo"
                                                [(ngModel)]="model.isUnderVapors" [entity]="model" property="isUnderVapors"></app-control>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="defects" *ngIf="model.vesselNotification">
                    <ngb-panel id="defects">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Ship's Defects</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-12">
                                    <app-control label='Defects' type="codelist" codelist="Defect"
                                        [multi]="true" [(ngModel)]="model.vesselNotification.defects" [entity]="model.vesselNotification" property="defects"></app-control>
                                </div>
                                <div class="col-12">
                                    <app-control label='Defect remarks' type="textarea" [isDisabled]="!editMode"
                                        [(ngModel)]="model.vesselNotification.defectRemarks" [entity]="model.vesselNotification" property="defectRemarks"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="attachments">
                    <ngb-panel id="attachments">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Supporting Documents</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-entity-form [options]="formOptions" [grid]="attachmentGrid"></app-entity-form>
                            <hr class="my-3" />
                            <app-grid #attachmentGrid [data]="model.attachments">
                                <kendo-grid-column title="{{'Name' | translate}}">
                                    <ng-template let-row kendoGridCellTemplate>
                                        <span>{{row.attachment?.name || row.name}}</span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Attachment Type' | translate}}">
                                    <ng-template kendoGridCellTemplate let-row>
                                        {{row.attachmentTypeId | codelist:'AttachmentType' | async}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Private' | translate}}">
                                    <ng-template let-row kendoGridCellTemplate>
                                        <app-checkbox [disabled]="true" [(ngModel)]="row.private"></app-checkbox>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Remarks' | translate}}" field="remarks"></kendo-grid-column>
                                <kendo-grid-column title="{{'Created Date' | translate}}">
                                    <ng-template kendoGridCellTemplate let-row>
                                        {{row.createdDate | date:'short'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Created By' | translate}}">
                                    <ng-template kendoGridCellTemplate let-row>
                                        {{row.createdById | codelist:'User' | async}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column class="overflow-unset" [hidden]="editMode" [width]="100">
                                    <ng-template kendoGridCellTemplate let-row>
                                        <app-upload-action-buttons [file]="row.attachment" [disabled]="true" [multiple]="false"></app-upload-action-buttons>
                                    </ng-template>
                                </kendo-grid-column>
                            </app-grid>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </ng-template>
</app-loader>
