import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-vessel-notification-permission',
    templateUrl: './vessel-notification-permission.component.html',
    styleUrls: ['./vessel-notification-permission.component.scss']
})
export class VesselNotificationPermissionComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    formOptions: EntityFormOptions;
    constructor(private translateService: TranslateService, private breezeViewService: BreezeViewService) {
        this.formOptions = {
            entityName: 'VesselNotificationPermission',
            canEdit: () => this.canEdit,
            beforeAdd: model => {
                model.createdDate = new Date();
                model.createdById = this.user?.id;
            },
            propertyGroups: [
                [
                    { name: 'organizationId', label: 'Organization', fetch: this.fetchAdditionalAgents, colSize: 3 }
                ],
                [
                    { name: 'viewDangerousGoods', label: 'View Dangerous Goods', type: AppControlType.Boolean },
                    { name: 'viewBunkers', label: 'View Bunkers', type: AppControlType.Boolean },
                    { name: 'viewCargo', label: 'View Cargo', type: AppControlType.Boolean },
                    { name: 'viewStoreProducts', label: 'View Store Products', type: AppControlType.Boolean },
                    { name: 'viewWorkers', label: 'View Crew List', type: AppControlType.Boolean },
                    { name: 'viewPassengers', label: 'View Passengers', type: AppControlType.Boolean }
                ],
                [
                    { name: 'viewSecurity', label: 'View Security', type: AppControlType.Boolean },
                    { name: 'viewWastes', label: 'View Wastes', type: AppControlType.Boolean },
                    { name: 'viewMdh', label: 'View Health', type: AppControlType.Boolean },
                    { name: 'viewCruiseItineraries', label: 'View Itineraries', type: AppControlType.Boolean },
                    { name: 'viewAttachments', label: 'View Attachments', type: AppControlType.Boolean },
                    { name: 'viewAccompanied', label: 'View Accompanied', type: AppControlType.Boolean }
                ],
                [
                    { name: 'viewLogs', label: 'View Logs', colSize: 2 }
                ],
                [
                    { name: 'editDangerousGoods', label: 'Edit Dangerous Goods', type: AppControlType.Boolean },
                    { name: 'editBunkers', label: 'Edit Bunkers', type: AppControlType.Boolean },
                    { name: 'editCargo', label: 'Edit Cargo', type: AppControlType.Boolean },
                    { name: 'editStoreProducts', label: 'Edit Store Products', type: AppControlType.Boolean },
                    { name: 'editWorkers', label: 'Edit Crew List', type: AppControlType.Boolean },
                    { name: 'editPassengers', label: 'Edit Passengers', type: AppControlType.Boolean }
                ],
                [
                    { name: 'editSecurity', label: 'Edit Security', type: AppControlType.Boolean },
                    { name: 'editWastes', label: 'Edit Wastes', type: AppControlType.Boolean },
                    { name: 'editMdh', label: 'Edit Health', type: AppControlType.Boolean },
                    { name: 'editCruiseItineraries', label: 'Edit Itineraries', type: AppControlType.Boolean },
                    { name: 'editAttachments', label: 'Edit Attachments', type: AppControlType.Boolean },
                    { name: 'editAccompanied', label: 'Edit Accompanied', type: AppControlType.Boolean }
                ]
            ]
        };
    }

    fetchAdditionalAgents = (filter) => {
        const additionalAgentIds = this.model.additionalAgents.map(x => x.agentId);
        return this.breezeViewService.handleQuery('AdditionalAgents', { name: filter })
            .then((data: any) => data.filter(x => additionalAgentIds.includes(x.id)).map((x: any) => ({
                value: x.id,
                label: x.name
            })));
    };
}
