import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { environment } from 'environments/environment';
import { DialogService } from '@common/services/dialog.service';
import { EntityQuery } from '@cime/breeze-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from '@common/components/error-dialog.component';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { HttpClient } from '@angular/common/http';
import { ButtonGroupComponent } from '@progress/kendo-angular-buttons';
import _ from 'lodash';
import { VesselNotificationGridComponent } from '../vessel-notification-grid/vessel-notification-grid.component';
import { UploadVesselNotificationModalComponent } from '../upload-vessel-notification-modal/upload-vessel-notification-modal.component';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-vessel-notification-list',
    templateUrl: './vessel-notification-list.component.html',
    styleUrls: ['./vessel-notification-list.component.scss'],
    providers: [BreezeViewService],
    encapsulation: ViewEncapsulation.None
})
export class VesselNotificationListComponent extends AutoFilterBreezeListComponent implements OnInit {
    queryName = 'VesselNotifications';
    exportCommand = 'ExportVesselNotifications';
    selectedButton;
    selectedTabIndex = 0;
    createPermission = VesselNotificationPermissions.Action.create;

    buttonFilters = [
        { label: 'Bar', portId: 'MEBAR', index: 0 },
        { label: 'Budva', portId: 'MEBUD', index: 1 },
        { label: 'Kotor', portId: 'MEKOT', index: 2 },
    ];

    breadcrumb = [
        {
            icon: 'ship',
            title: 'Vessel Notifications'
        }
    ];

    @ViewChild(ButtonGroupComponent) buttonGroup;
    @ViewChild(VesselNotificationGridComponent) vesselNotificationGrid;

    constructor(
        protected breezeViewService: BreezeViewService,
        private dialogService: DialogService,
        private http: HttpClient) {
        super(breezeViewService);

        this.actionBar[0].items.push({
            label: 'Upload',
            icon: 'book',
            isVisible: () => this.user?.hasPermission(VesselNotificationPermissions.Action.upload),
            onClick: () => this.upload()
        });

        this.actionBar[0].items.push({
            label: 'Download template',
            icon: 'file-excel',
            isVisible: () => this.user?.hasPermission(VesselNotificationPermissions.Action.upload),
            onClick: () => this.downloadTemplate()
        });
    }

    override ngOnInit() {
        this.filter = super.getFilter();
        this.filter.archived = this.selectedTabIndex === 1;
        this.initialize();
    }

    async downloadTemplate() {
        const excelFileName = environment.excelTemplateFile;
        const attachment = await firstValueFrom(this.http.get(`${window.location.protocol}/assets/documents/${excelFileName}`, {
            responseType: 'blob'
        }));
        UploadActionButtonsComponent.downloadBlob(excelFileName, attachment);
    }

    async upload() {
        const data = await this.dialogService.open(UploadVesselNotificationModalComponent, { size: 'md' });
        if (!data) return;

        this.isBusy = true;
        const res = await this.breezeViewService.executeCommand('UploadVesselNotification', {
            content: data.file.content,
            fileName: data.file.name,
            agentId: data.agentId,
            dutyNumber: data.dutyNumber
        }, true, true)
        .finally(() => this.isBusy = false);
        if (!res) return;

        this.router.navigate([`vessel-notification/view/${res.results[0]}`]);
    }

    override clearFilter() {
        this.buttonGroup.buttons._results.forEach(b => b.selected = false);
        this.selectedButton = null;
        super.clearFilter();
    }

    override getDefaultFilter() {
        return {
            isShort: false,
            archived: this.selectedTabIndex === 1
        };
    }

    override canExportPdf() {
        return false;
    }

    override export(exportType) {
        return super.export(exportType, this.vesselNotificationGrid.appGrid);
    }

    onButtonGroupSelect(button) {
        const buttons = this.buttonGroup.buttons._results;
        if (this.selectedButton?.index === button.index) {
            setTimeout(() => buttons[button.index].selected = false);
            this.filter.portOfCallId = null;
            this.selectedButton = null;
        } else {
            this.selectedButton = button;
            this.filter.portOfCallId = button.portId;
        }
        this.search();
    }

    searchBy(data: any) {
        if (data.propertyName === 'vesselId') this.filter = this.getDefaultFilter();
        this.filter[data.propertyName] = data.value;
        this.search();
    }

    onTabSelect(tab) {
        this.selectedTabIndex = tab.index;
        this.filter.archived = tab.index === 1;
        this.search();
    }
}
