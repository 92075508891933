import { Location } from '@angular/common';
import { NavigationExtras } from '@angular/router';
import { ValidationOptionsConfig } from '@cime/breeze-client/src/validation-options';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import _ from 'lodash';
import { ConfirmSendVesselNotificationModalComponent } from '../../vessel-notification/components/confirm-send-vessel-notification-modal/confirm-send-vessel-notification-modal.component';
import { UpdateVesselNotificationModalComponent } from '../../vessel-notification/components/update-vessel-notification-modal/update-vessel-notification-modal.component';
import { UploadVesselNotificationModalComponent } from '../../vessel-notification/components/upload-vessel-notification-modal/upload-vessel-notification-modal.component';

export abstract class BaseVesselNotificationViewComponent extends AbstractBreezeViewComponent {
    entityName = 'VesselNotification';
    cloneId: number;
    sendRemarks: string;
    cloneForDepartureId: number;
    cloneData: string;
    initialSelectedTabIndex: number;
    enableSteps: boolean;
    changeAgencies: any[] = [];
    abstract isShort: boolean;

    constructor(
        public breezeViewService: BreezeViewService,
        protected dialogService: DialogService,
        protected location: Location) {
        super(breezeViewService);
        this.cloneId = this.breezeViewService.activatedRoute.snapshot.queryParams.clone;
        this.sendRemarks = this.breezeViewService.activatedRoute.snapshot.queryParams.sendRemarks;
        this.cloneForDepartureId = this.breezeViewService.activatedRoute.snapshot.queryParams.cloneForDeparture;
        this.cloneData = this.breezeViewService.activatedRoute.snapshot.queryParams.cloneData;
        this.initialSelectedTabIndex = parseInt(this.router.routerState.snapshot.root.queryParams.vnTab, null);
        this.enableSteps = this.mode === ViewMode.create && !this.cloneId && !this.cloneForDepartureId;

        if (!this.initialSelectedTabIndex && !this.cloneId && !this.cloneForDepartureId) {
            this.router.navigate([], {
                relativeTo: this.breezeViewService.activatedRoute,
                queryParams: { vnTab: 0 },
                queryParamsHandling: 'merge'
            });
        }

        this.actionBar[0].items.push({
            label: 'Upload',
            icon: 'book',
            isVisible: () => this.canUpload(),
            onClick: () => this.upload(),
        });

        this.editActionBarGroup.items = [
            ...this.editActionBarGroup.items,
            {
                label: `Cancel${this.user.isAgent() ? ' notice' : ''}`,
                icon: 'ban',
                isVisible: () => this.canCancelNotification(),
                onClick: () => this.cancelNotification()
            },
            {
                label: 'Delete',
                icon: 'trash',
                isVisible: () => this.canDiscard(),
                onClick: () => this.discard(),
            },
            {
                label: 'Send',
                icon: 'share',
                isVisible: () => this.canSend(),
                onClick: () => this.send(),
            },
            {
                label: 'Send 72h',
                icon: 'share',
                isVisible: () => this.canSend72h(),
                onClick: () => this.send72h(),
            },
            {
                label: 'Approve',
                icon: 'check',
                isVisible: () => this.canApprove(),
                isDisabled: () => this.disableApproveReject(),
                onClick: () => this.approve(),
            },
            {
                label: 'Reject',
                icon: 'times',
                isVisible: () => this.canReject(),
                isDisabled: () => this.disableApproveReject(),
                onClick: () => this.reject(),
            },
            {
                label: 'Remove',
                icon: 'times',
                isVisible: () => this.canRemoveAgent(),
                onClick: () => this.removeAgent(),
            },
            {
                label: 'Unconfirm',
                icon: 'times',
                isVisible: () => this.canUnconfirm(),
                onClick: () => this.unconfirm(),
            },
            {
                label: 'Clone',
                icon: 'clone',
                isVisible: () => this.canClone(),
                onClick: () => this.clone(),
            },
        ];
    }

    isPrimaryAgent() {
        return [this.model.agentId, this.model.vesselVisit?.arrivalVesselNotification?.agentId].includes(this.user.organizationId);
    }

    getChangeAgency(moduleTypeId: string) {
        return this.changeAgencies?.find(ca => ca.moduleTypeId === moduleTypeId);
    }

    createEntity() {
        const entity: any = this.entityManager.createEntity(this.entityName, {
            statusId: 'D',
            agentId: this.user.isAgent() ? this.user.organizationId : null,
            cargoGrossWeight: null,
            preArrival: null,
        });
        if (entity.agentId) entity.entityAspect.loadNavigationProperty('agent');

        return entity;
    }

    override modelLoaded() {
        super.modelLoaded();
        this.changeAgencies = this.model.vesselVisit?.arrivalVesselNotification?.changeAgencies.filter(x => x.active) || this.model.changeAgencies.filter(x => x.active);
    }

    updateTabQueryParameter(selectedTabIndex) {
        this.initialSelectedTabIndex = selectedTabIndex;
        this.router.navigate([], {
            relativeTo: this.breezeViewService.activatedRoute,
            queryParams: { vnTab: selectedTabIndex },
            queryParamsHandling: 'merge'
        });
    }

    getNavigationExtras(viewMode: ViewMode): NavigationExtras {
        return {
            queryParams: { vnTab: this.router.routerState.snapshot.root.queryParams.vnTab },
            queryParamsHandling: viewMode === ViewMode.view ? null : 'merge'
        };
    }

    mapBreezeCloneProperties() {
        // Map referenced child object properties which have been ignored during cloning
        this.model.workerEffects.forEach(we => {
            if (!we.vesselNotificationWorker && !we.vesselNotificationWorkerId) {
                const worker = this.model.workers.find(w => w.givenName === we.workerGivenName && w.familyName === we.workerFamilyName);
                we.vesselNotificationWorker = worker;
            }
        });

        this.model.mdhAttachments.forEach(ma => {
            const isCrew = ma.personTypeId === 'C';
            const peopleEntities = isCrew ? this.model.workers : this.model.passengers;
            const person = peopleEntities.find(w => `${w.givenName} ${w.familyName}` === ma.personFullName);
            if (isCrew) ma.worker = person;
            else ma.passenger = person;
            ma.person = person;
        });
    }

    async sendWithRemarks(remarks) {
        if (this.editMode && this.hasChanges())
            await this.saveChanges({ silent: true, redirectToViewMode: false });

        try {
            await this.executeCommand({
                commandName: 'SendVesselNotification',
                data: { id: this.model.id, remarks },
                tracking: true, errorPopup: true, refreshData: true
            });
            await this.router.navigate([`${this.parentRoute}/list/`]);
        } catch (e) { }
    }

    canCancelNotification() {
        return this.viewMode && ['W', 'U'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.cancel);
    }

    cancelNotification() {
        return this.executeAction('Cancel');
    }

    discard() {
        this.executeAction('Discard');
    }

    clone() {
        this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { clone: this.model.id } });
    }

    canSend72h() {
        return this.viewMode &&
            this.model.statusId === 'D' && !this.model.preArrivalSentDate
            && this.model.preArrival && this.user.hasPermission(VesselNotificationPermissions.Action.send);
    }

    canArrive() {
        return this.model.statusId === 'A' && this.model.notificationTypeId === 'A' &&
            this.user.hasPermission(VesselNotificationPermissions.Action.arrive);
    }

    canDepart() {
        return this.model.statusId === 'A' && this.model.notificationTypeId === 'D' &&
            this.model.vesselVisit?.statusId !== 'EX' &&
            this.user.hasPermission(VesselNotificationPermissions.Action.depart);
    }

    async complete(prop: string) {
        const data = await this.dialogService.form(
            {
                title: `Insert ${_.upperCase(prop)}`,
                properties: [
                    {
                        name: prop,
                        type: AppControlType.DateTime,
                        time: true,
                        label: `${_.upperCase(prop)}`,
                        initialValue: new Date()
                    }
                ],
                canConfirm: (model) => model[prop],
                cancelText: 'Test'
            }, { size: 'md' });
        if (!data) return;

        data.id = this.model.id;
        await this.executeCommand({ commandName: 'CompleteVesselNotification', data });
        return await this.router.navigate([`${this.parentRoute}/list/`]);
    }

    async createVesselPermit() {
        this.router.navigate(['vessel-permit/create/'], { queryParams: { vesselNotificationId: this.model.id } });
    }

    canRemoveAgent() {
        return this.viewMode &&
            ['D', 'W'].includes(this.model.statusId) &&
            this.model.agentId !== this.user.organizationId &&
            this.user.hasPermission(VesselNotificationPermissions.Action.removeAgent) &&
            _.some(this.model.additionalAgents, o => o.agentId === this.user.organizationId);
    }

    async removeAgent() {
        const result = await this.dialogService.openConfirmDialog('Remove from Vessel Notification',
            this.translateService.instant('Are you sure you want to be removed from this notification?')
        );
        if (!result) return;

        await this.executeCommand({ commandName: 'RemoveAgentVesselNotification', data: { id: this.model.id } });
        return await this.router.navigate([`${this.parentRoute}/list/`]);
    }

    canUnconfirm() {
        return this.viewMode &&
            ['A', 'R', 'W'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.unconfirm);
    }

    unconfirm() {
        return this.executeAction('Unconfirm');
    }

    async excelUpdate() {
        const data = await this.dialogService.open(UpdateVesselNotificationModalComponent, { size: 'lg' },
            dialogRef => {
                dialogRef.componentInstance.isShort = this.model.isShort;
                dialogRef.componentInstance.isDeparture = this.model.notificationTypeId === 'D';
                dialogRef.componentInstance.isCompleted = ['AR', 'DP'].includes(this.model.statusId);
                dialogRef.componentInstance.isApproved = this.model.statusId === 'A';
            }
        );
        if (!data) return;

        return await this.executeCommand({
            commandName: this.isShort ? 'ExcelUpdateVesselShortNotification' : 'ExcelUpdateVesselNotification',
            data: { id: this.model.id, ...data, content: data.content.content }, refreshData: true, errorPopup: true
        });
    }

    override async executeAction(name: string) {
        const data = await this.dialogService.form(
            {
                title: name,
                properties: [
                    {
                        name: 'remarks',
                        type: AppControlType.TextArea,
                        label: 'Remarks',
                        initialValue: this.user.isSecurity() ? this.model.iSPSSecurityRemark : this.user.isInspection() ? this.model.inspectionRemarks : null,
                    }
                ],
                canConfirm: (model) => name === 'Reject' ? model.remarks : true
            }, { size: 'md' });
        if (!data) return;

        await this.executeCommand({
            commandName: `${name}VesselNotification`, data: {
                id: this.model.id,
                remarks: data.remarks
            }, refreshData: true
        });
    }

    override getValidationOptions(): ValidationOptionsConfig {
        return { validateOnAttach: true };
    }

    hasChanges() {
        return super.hasChanges() && this.model.vessel;
    }

    canUpload() {
        return this.user.hasPermission(VesselNotificationPermissions.Action.upload)
            && this.mode === ViewMode.create && (_.isNil(this.cloneId) && _.isNil(this.cloneForDepartureId));
    }

    async upload() {
        const data = await this.dialogService.open(UploadVesselNotificationModalComponent, { size: 'md' });
        if (!data) return;

        const res = await this.executeCommand({
            commandName: this.isShort ? 'UploadVesselShortNotification' : 'UploadVesselNotification',
            data: {
                content: data.file.content,
                fileName: data.file.name,
                agentId: data.agentId,
                dutyNumber: data.dutyNumber
            }, errorPopup: true
        });

        return await this.router.navigate([`${this.isShort ? 'short-' : ''}vessel-notification/view/${res.results[0]}`]);
    }

    canSend() {
        return (this.mode !== ViewMode.create) &&
            this.model.statusId === 'D' && (!!this.model.preArrivalSentDate || !this.model.preArrival)
            && this.user.hasPermission(VesselNotificationPermissions.Action.send)
            && ([this.model.agentId, this.model.vesselVisit?.arrivalVesselNotification?.agentId].includes(this.user.organizationId) || this.user.isSystemUser); // Only primary agent can send notification
    }

    reject() {
        this.executeAction('Reject');
    }

    async send() {
        if (!this.model.isShort && !await this.openEMSAWarning(this.model.ssnMessages)) return;

        if (this.isBanned()) {
            const bannedVessel = (await this.breezeViewService.handleQuery('DetainedVessel', { id: this.model.vessel.currentRevisionId }))[0];
            const bannedByNames = bannedVessel.actions.map(action => action.bannedBy?.name).join(', ');
            const res = await this.dialogService.openConfirmDialog('Warning', `The Vessel ${this.model.vessel.name} is served with a ban by ${bannedByNames}`,
                false, 'Cancel', 'Proceed');
            if (!res) return;
        }

        const res = await this.dialogService.open(ConfirmSendVesselNotificationModalComponent, { size: 'xl' }, dialogRef => dialogRef.componentInstance.model = this.model);
        if (!res.result) return;
        return await this.sendWithRemarks(res.remarks);
    }

    async openEMSAWarning(messages: any[]) {
        if (messages.length === 0) return true;

        const lastMessage: any = _.last(messages);

        if (lastMessage?.ssnStatusCode === 'OK') return true;

        const message = _.some(messages, mes => mes.processed === false) ?
            'This notifications has some unprocessed EMSA messages. Are you sure you want to continue with your work?' :
            'The last EMSA message contained errors. Were they corrected?';

        const res = await this.dialogService.yesNo('Warning',
            this.translateService.instant(message)
        );
        return res;
    }

    // Overriden methods below

    canDiscard() { return false; }

    send72h() { return; }

    disableApproveReject() { return false; }

    approve() { return; }

    canApprove() { return false; }

    canReject() { return false; }

    canClone() { return false; }
}
