import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';

@Component({
    selector: 'app-vessel-notification-ballast-waters',
    templateUrl: './vessel-notification-ballast-waters.component.html',
    styleUrls: ['./vessel-notification-ballast-waters.component.scss']
})
export class VesselNotificationBallastWatersComponent {
    @Input() model: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationBallastWater',
        canEdit: () => this.canEdit,
        propertyGroups: [
            [
                {
                    name: 'ballastTankTypeId',
                    label: 'Ballast Water Tank Type',
                    type: AppControlType.CodeList,
                    codelist: 'BallastTankType'
                },
                {
                    name: 'sourceDate',
                    label: 'Ballast Water Source Date (dd/mm/yyyy)',
                    type: AppControlType.DateTime,
                    time: false
                },
                {
                    name: 'sourcePortId',
                    label: 'Port',
                    type: AppControlType.CodeList,
                    codelist: 'Location'
                },
                { name: 'sourceLatitude', label: 'Latitude', maxlength: 255 }
            ],
            [
                { name: 'sourceLongitude', label: 'Longitude', maxlength: 255 },
                {
                    name: 'sourceVolume',
                    label: 'Volume',
                    type: AppControlType.Number,
                    decimals: 2
                },
                {
                    name: 'unitOfMeasureSourceId',
                    label: 'Unit of Measurment (Source)',
                    type: AppControlType.CodeList,
                    codelist: 'BallastWaterUnitOfMeasure'
                },
                { name: 'sourceTemperature', label: 'Temp.', maxlength: 255 }
            ],
            [
                {
                    name: 'ballastWaterExchangeId',
                    label: 'Ballast Water Exchange',
                    type: AppControlType.CodeList,
                    codelist: 'BallastWaterExchange'
                },
                {
                    name: 'exchangeDate',
                    label: 'Exchange Date (dd/mm/yyyy)',
                    type: AppControlType.DateTime,
                    time: false
                },
                { name: 'endpointLatitudeLongitude ', label: 'Endpoint Lat./Long.', maxlength: 255 }
            ],
            [
                {
                    name: 'exchangeVolume',
                    label: 'Exchange Volume',
                    type: AppControlType.Number,
                    decimals: 2
                },
                {
                    name: 'unitOfMeasureExchangeId',
                    label: 'Unit of Measurment (Exchange)',
                    type: AppControlType.CodeList,
                    codelist: 'BallastWaterUnitOfMeasure'
                },
                {
                    name: 'exchange',
                    label: 'Exch. %',
                    type: AppControlType.Number,
                    decimals: 2
                },
                {
                    name: 'seaHeight',
                    label: 'SEA Hgt.',
                    type: AppControlType.Number,
                    decimals: 2
                },
            ],
            [
                {
                    name: 'dischargeDate',
                    label: 'Ballast Water Discharge Date (dd/mm/yyyy)',
                    type: AppControlType.DateTime,
                    time: false
                },
                {
                    name: 'dischargePortId',
                    label: 'Port of Discharge',
                    type: AppControlType.CodeList,
                    codelist: 'Location'
                },
                { name: 'dischargeLatitude', label: 'Discharge Latitude', maxlength: 255 },
                { name: 'dischargeLongitude', label: 'Discharge Longitude', maxlength: 255 }
            ],
            [
                {
                    name: 'dischargeVolume',
                    label: 'Discharge Volume',
                    type: AppControlType.Number,
                    decimals: 2,
                    colSize: 3
                },
                {
                    name: 'unitOfMeasureDischargeId',
                    label: 'Unit of Measurment (Discharge)',
                    type: AppControlType.CodeList,
                    codelist: 'BallastWaterUnitOfMeasure',
                    colSize: 3
                },
                { name: 'dischargeSalinity', label: 'Salinity', maxlength: 255, colSize: 3 }
            ]
        ]
    };
}
