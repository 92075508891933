import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    constructor(private title: Title) { }

    setTitle(title: string) {
        this.title.setTitle(title ? `${title} · ${environment.title}` : environment.title);
    }
}
