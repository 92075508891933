<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title='Vessel Permits' [breadcrumb]="breadcrumb"></app-content-header>
<ngb-accordion activeIds="ngb-panel-0" (keyup.enter)="onAutoFilterChange($event)">
    <ngb-panel>
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel" [onlyClose]="true">
                <h5 *ngIf="panel.isOpen">
                    <fa-icon icon="filter"></fa-icon> {{'Filters' | translate}}
                </h5>
                <div *ngIf="!panel.isOpen" class="row">
                    <div class="col">
                        <app-control label='Permit Number' type="number" format="'#'" [min]="1"
                            (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.number" [entity]="filter" property="number"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Permit Type' type="codelist" codelist="VesselPermitType"
                            (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.typeId" [entity]="filter" property="typeId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Permit Status' type="codelist" codelist="VesselPermitStatus"
                            (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.statusId" [entity]="filter" property="statusId"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Issued Date From' type="datetime" [time]="true"
                            (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.issuedDateFrom" [entity]="filter" property="issuedDateFrom"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Issued Date To' type="datetime" [time]="true"
                            (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.issuedDateTo" [entity]="filter" property="issuedDateTo"></app-control>
                    </div>
                </div>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col">
                    <app-control label='Permit Type' type="codelist" codelist="VesselPermitType"
                        [(ngModel)]="filter.typeId" [entity]="filter" property="typeId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Permit Status' type="codelist" codelist="VesselPermitStatus"
                        [(ngModel)]="filter.statusId" [entity]="filter" property="statusId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Issued Date From' type="datetime" [time]="true"
                        [(ngModel)]="filter.issuedDateFrom" [entity]="filter" property="issuedDateFrom"></app-control>
                </div>
                <div class="col">
                    <app-control label='Issued Date To' type="datetime" [time]="true"
                        [(ngModel)]="filter.issuedDateTo" [entity]="filter" property="issuedDateTo"></app-control>
                </div>
                <div class="col">
                    <app-control label='Validity of Permit From' type="datetime" [time]="true"
                        [(ngModel)]="filter.validFrom" [entity]="filter" property="validFrom"></app-control>
                </div>
                <div class="col">
                    <app-control label='Validity of Permit To' type="datetime" [time]="true"
                        [(ngModel)]="filter.validTo" [entity]="filter" property="validTo"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-control label='Permit Number' type="number" format="'#'" [min]="1"
                        [(ngModel)]="filter.number" [entity]="filter" property="number"></app-control>
                </div>
                <div class="col">
                    <app-control label='Vessel Name' type="codelist" codelist="Vessel"
                        [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
                </div>
                <div class="col">
                    <app-control label='IMO Number' type="number" format="'#'" [maxlength]="7"
                        [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
                </div>
                <div class="col">
                    <app-control label='MMSI Number' type="number" format="'#'" [maxlength]="9"
                        [(ngModel)]="filter.mmsi" [entity]="filter" property="mmsi"></app-control>
                </div>
                <div class="col">
                    <app-control label='Location' type="codelist" codelist="Berth" [(ngModel)]="filter.berthId" [entity]="filter" property="berthId"></app-control>
                </div>
                <div class="col">
                    <app-control label='Agent' type="codelist" codelist="Organization"
                        [(ngModel)]="filter.requestingOrganizationId" [entity]="filter" property="requestingOrganizationId"></app-control>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="float-right">
                        <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
                            <fa-icon class="ml-1" icon="trash"></fa-icon> <span class="btn-text">{{'Clear' | translate}}</span>
                        </button>
                        <button class="btn btn-sm btn-primary" (click)="search()">
                            <fa-icon class="ml-1" icon="search"></fa-icon> <span class="btn-text">{{'Search' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="card mt-3">
    <div class="card-header">
        <h5><fa-icon icon="list-alt"></fa-icon> {{'Results' | translate}}</h5>
        <div *ngIf="nrOfActiveFilters > 0" class="filter-indicator"> Active filters: {{ nrOfActiveFilters }} </div>
    </div>
    <div class="card-body">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="{{'Vessel Permits' | translate}}" [selected]="true">
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Archived Vessel Permits' | translate}}">
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
        <app-vessel-permit-grid [query]="query" [selection]="selection"></app-vessel-permit-grid>
    </div>
</div>
