<form novalidate #form="ngForm">
    <kendo-grid class="inline-editable" [data]="internalData" [skip]="state.skip" [pageable]="pageable"
        [pageSize]="pageSize || state.take" (edit)="rowAction.emit(); gridEdit.editHandler($event, entityName)"
        [loading]="isBusy" (save)="onSave($event)" (dataStateChange)="onDataStateChanged($event)"
        (cancel)="rowAction.emit(); gridEdit.cancelHandler($event)" (remove)="onRemove($event)"
        (add)="gridEdit.addHandler($event,data, entityName); rowAction.emit();">
        <kendo-grid-command-column [width]="50" *ngIf="editMode && canEdit">
            <ng-template kendoGridCellTemplate let-isNew="isNew">
                <button kendoGridEditCommand [primary]="true" class="btn btn-primary">
                    <fa-icon icon="edit"></fa-icon>
                </button>
                <button kendoGridSaveCommand class="btn btn-primary">
                    <fa-icon *ngIf="isNew !== undefined" icon="check"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-command-column [width]="45" *ngIf="editMode && canEdit && canDelete">
            <ng-template kendoGridCellTemplate let-isNew="isNew">
                <button kendoGridRemoveCommand class="btn btn-danger">
                    <fa-icon icon="trash"></fa-icon>
                </button>
                <button kendoGridCancelCommand class="btn btn-danger">
                    <fa-icon icon="ban"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <ng-template kendoGridToolbarTemplate position="bottom">
            <div *ngIf="!!detailTemplate && data?.length > 0">
                <button type="button" class="btn btn-sm btn-primary" (click)="expandAllDetails()" translate>Expand
                    all</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="collapseAllDetails()" translate>Collapse
                    all</button>
            </div>
            <button *ngIf="editMode && canEdit && canAddNew" kendoGridAddCommand type="button"
                class="btn btn-sm btn-primary float-right" translate>Add</button>
        </ng-template>
    </kendo-grid>
</form>