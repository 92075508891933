import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { User } from '@common/models/User';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-accompanied',
    templateUrl: './vessel-notification-accompanied.component.html',
    styleUrls: ['./vessel-notification-accompanied.component.scss']
})
export class VesselNotificationAccompaniedComponent {
    @Input() model: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;
    @Input() user: User;
    @Input() tabs: Boolean;
    @Input() title = 'Accompanied';

    constructor(private translateService: TranslateService) { }

    accompaniedFormOptions: EntityFormOptions = {
        entityName: 'VesselNotificationAccompaniedVehicle',
        canEdit: () => this.canEdit,
        propertyGroups: [
            [
                { name: 'driverName', label: 'Driver Name' },
                { name: 'vehicleType', label: 'Vehicle Type' },
                { name: 'vehicleBrand', label: 'Vehicle Brand' },
                { name: 'vehicleNoPlate', label: 'Vehicle No. Plate' },
                { name: 'weightKgs', label: 'Weight in Kgs', decimals: 2}
            ]
        ]
    };
}
