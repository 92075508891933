<div class="modal-header">
    <h4 class="modal-title" translate>Excel upload</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <app-control label='File' type="file" [isDisabled]="false"
                [allowedExtensions]="['.xlsx', '.xlsm']" [(ngModel)]="model.content" [entity]="model" property="content"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-3" *ngIf="!isCompleted && !isApproved">
            <app-control label='Voyage' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.voyage" [entity]="model" property="voyage"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isShort && !isApproved">
            <app-control label='Bunkers' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.bunker" [entity]="model" property="bunker"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isShort && !isApproved">
            <app-control label='Ballast waters' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.ballastWaters" [entity]="model" property="ballastWaters"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isShort && !isApproved">
            <app-control label='DPG' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.dangerousGoods" [entity]="model" property="dangerousGoods"></app-control>
        </div>
        <div class="col-3" *ngIf="(!isShort && !isCompleted && !isApproved) || (isCompleted && isDeparture)">
            <app-control label='Cargo' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.cargo" [entity]="model" property="cargo"></app-control>
        </div>
        <div class="col-3" *ngIf="!isShort && !isDeparture && !isCompleted && !isApproved">
            <app-control label='Ship Stores' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.shipStore" [entity]="model" property="shipStore"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isApproved">
            <app-control label='Crew list' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.crew" [entity]="model" property="crew"></app-control>
        </div>
        <div class="col-3" *ngIf="!isShort && !isCompleted && !isApproved">
            <app-control label='Crew effect' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.crewEffect" [entity]="model" property="crewEffect"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Passengers' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.passenger" [entity]="model" property="passenger"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Pilots' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.pilots" [entity]="model" property="pilots"></app-control>
        </div>
        <div class="col-3" *ngIf="!isShort && !isCompleted && !isDeparture && !isApproved">
            <app-control label='Security' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.security" [entity]="model" property="security"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isDeparture && !isApproved">
            <app-control label='Waste' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.waste" [entity]="model" property="waste"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isDeparture && !isApproved">
            <app-control label='MDH' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.mdh" [entity]="model" property="mdh"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isDeparture && !isApproved">
            <app-control label='MDH Attachment' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.mdhAttachment" [entity]="model" property="mdhAttachment"></app-control>
        </div>
        <div class="col-3" *ngIf="!isCompleted && !isDeparture && !isShort && !isApproved">
            <app-control label='Cruise itinerary' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.cruiseItinerary" [entity]="model" property="cruiseItinerary"></app-control>
        </div>
        <div class="col-3" *ngIf="!isShort">
            <app-control label='Accompanied' type="boolean"
                [isDisabled]="false" [(ngModel)]="model.accompaniedVehicle" [entity]="model" property="accompaniedVehicle"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary" [disabled]="!canConfirm()" (click)="confirm()">{{'Confirm' | translate}}</button>
</div>
