import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@common/services/user.service';
import { filter } from 'rxjs/operators';
import { EntityValidator } from './entity-validator';
import _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    private fetchMetadataPromise: Promise<boolean>;

    constructor(private userService: UserService,
        private entityValidator: EntityValidator) {

        this.userService.isAuthenticatedSubject.pipe(filter(x => !x)).subscribe(() => this.fetchMetadataPromise = null);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.fetchMetadataPromise)
            this.fetchMetadataPromise = this.entityValidator.fetchUserAndMetadata(state);

        return this.fetchMetadataPromise;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }
}
