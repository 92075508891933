import { Injectable } from '@angular/core';
import { NAVIGATION } from '../../navigation';
import { PermissionType, User } from '@common/models/User';

export class MenuItem {
    readonly title: string;
    icon: string | string[] | ((user: User) => string | string[]);
    class?: any | ((user: User) => any);
    getNumber?: (user: User) => any;
    readonly children?: MenuItem[];
    readonly permissions?: PermissionType | PermissionType[];
    readonly route?: string;
    hasPermission?: boolean;
    isVisible?: (user: User) => boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _data: MenuItem[] = NAVIGATION;

    getNavigation() {
        return this._data;
    }

    append(menuItem: MenuItem) {
        this._data = [...this._data, menuItem];
    }

    prepend(menuItem: MenuItem) {
        this._data = [menuItem, ...this._data];
    }

    remove(menuItem: MenuItem) {
        this._data = this._data.filter(x => x !== menuItem);
    }
}
