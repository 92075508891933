<app-loader [isBusy]="!model || isBusy">
    <div class="row separator"></div>
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <div class="row">
            <div class="col-4">
                <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
            </div>
        </div>
        <div class="row mr-1" *ngIf="mode !== 'create'">
            <div class="col ml-2 simple-container box-shadow">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto"> Vessel permit </strong>
                    <div class="col-md-auto" [appPill]="{ status: model.statusId, module: 'vesselPermit', muted: true }">
                        {{ model.statusId | codelist:model:'statusId'|async }}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong> {{ model.referenceNumber }} </strong>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify"> Agent: </strong> {{ model.requestingOrganizationId | codelist:model:'requestingOrganizationId'|async }}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <strong>TM</strong>
                        <app-rej-icon [item]="model" organizationName="" moduleType="VesselPermit"></app-rej-icon>
                    </div>
                </div>
            </div>
            <div class="col ml-2 simple-container box-shadow">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto"> Permit details </strong>
                </div>
                <span>
                    <div class="row mt-2">
                        <div class="col-md-auto">
                            <strong class="bluify"> Permit type: </strong> {{ model.typeId | codelist:model:'typeId'|async }}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-auto">
                            <strong class="bluify"> Validity from: </strong> {{ model.validFromDate| date:'short' }}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-auto">
                            <strong class="bluify"> Validity to: </strong> {{ model.validToDate | date:'short' }}
                        </div>
                    </div>
                </span>
            </div>
            <div class="col ml-2 simple-container box-shadow" *ngIf="model.vesselNotification?.vesselVisitId">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto"> Vessel visit </strong>
                    <div class="col-md-auto" [appPill]="{ status: model.vesselNotification?.vesselVisit.statusId, module: 'vesselVisit', muted: true }">
                        {{ model.vesselNotification?.vesselVisit.statusId | codelist:model.vesselNotification?.vesselVisit:'statusId'|async }}
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong class="bluify"> Vessel Visit: </strong>
                        <strong> <a [routerLink]="['/vessel-visit/view', model.vesselNotification.vesselVisitId]" target="_blank">{{ model.vesselNotification?.vesselVisit.yearNumber }}</a></strong>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col">
                        {{ model.vesselNotification?.vesselVisit.portId | codelist:model.vesselNotification?.vesselVisit:'portId'|async }} / {{ model.vesselNotification?.vesselVisit.berthId | codelist:model.vesselNotification?.vesselVisit:'berthId'|async }}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong class="bluify"> Agent: </strong> {{ model.vesselNotification?.vesselVisit.agentId | codelist:model.vesselNotification?.vesselVisit:'agentId'|async }}
                    </div>
                </div>
            </div>
            <div class="col ml-2 simple-container box-shadow inactive" *ngIf="!model.vesselNotification?.vesselVisitId">
                <strong class="orangify capitalize">Vessel visit</strong>
            </div>
            <div class="col ml-2 simple-container box-shadow">
                <div class="badge box-shadow tight" ngbTooltip="{{ model.vessel.countryId | codelist:model.vessel:'countryId'|async }}">
                    <span>{{ model.vessel.countryId }}</span>
                    <app-flag-icon [code]="model.vessel.countryId"></app-flag-icon>
                </div>
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto">Vessel information</strong>
                    <strong class="col-md-auto"><a [href]="['https://www.marinetraffic.com/en/ais/details/ships/imo:' + model.vessel.iMONumber ]" target="_blank">({{ model.vessel.iMONumber }})</a></strong>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong><a [routerLink]="['/vessels/view', model.vesselId]" target="_blank"> {{ model.vesselId | codelist:'Vessel' | async }} </a></strong>
                        <fa-icon *ngIf="model.vessel?.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                    </div>
                    <div *ngIf="isDetained() || isBanned()" ngbTooltip="{{isDetained() ? 'Detained Vessel' : 'Banned Vessel'}}"
                        placement="right" style="font-size: 16px;">
                        <fa-icon icon="lock" class="redIcon"></fa-icon>
                    </div>
                    <div class="col-md-auto">
                        ({{ model.vessel.typeId | codelist:model.vessel:'typeId'|async }})
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">MMSI: </strong>{{ model.vessel.mmsi }}
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">LOA: </strong>{{ !!model.vessel.length ? model.vessel.length + ' m' : '/'}}
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">Gross Tonnage: </strong>{{ model.vessel.grossTonnage }}
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">Net Tonnage: </strong>{{ !!model.vessel.netTonnage ? model.vessel.netTonnage : '/' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-body">
                <ngb-accordion activeIds="vessel-visit">
                    <ngb-panel id="vessel-visit">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Vessel Notification Information</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-6">
                                    <app-control [isDisabled]="!isEditable || !isCreateMode || model.revisionNumber != null"
                                        label='Vessel Notification' type="codelist" codelist="DraftVesselNotification"
                                        [(ngModel)]="model.vesselNotificationId" [entity]="model" property="vesselNotificationId" (ngModelChange)="onVesselNotificationChange($event)"></app-control>
                                </div>
                                <div class="col-6" *ngIf="!!model.vessel">
                                    <app-control label='Vessel' [(ngModel)]="model.vessel.name" [entity]="model.vessel" property="name" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-6" *ngIf="editMode && !!model.vessel">
                                    <app-control label='IMO Number' type="number" [isDisabled]="true" [(ngModel)]="model.vessel.iMONumber" [entity]="model.vessel" property="iMONumber"></app-control>
                                </div>
                                <div class="col-6" *ngIf="editMode && !!model.vessel">
                                    <app-control label='MMSI Number' [isDisabled]="true" [(ngModel)]="model.vessel.mmsi" [entity]="model.vessel" property="mmsi"></app-control>
                                </div>
                                <div class="col-6" *ngIf="!!model.vesselVisit">
                                    <app-control label='ETA' [isDisabled]="true" [time]="true" [(ngModel)]="model.vesselVisit.eta" [entity]="model.vesselVisit" property="eta"></app-control>
                                </div>
                                <div class="col-6" *ngIf="!!model.vesselVisit">
                                    <app-control label='ATA' [isDisabled]="true" [time]="true" [(ngModel)]="model.vesselVisit.ata" [entity]="model.vesselVisit" property="ata"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="vessel-permit">
                    <ngb-panel id="vessel-permit">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Vessel Permit</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <app-control *ngIf="canEditPermitType" label='Permit Type' type="codelist"
                                        codelist="VesselPermitTypeCurrentRevision" [(ngModel)]="model.typeId" [entity]="model" property="typeId" (ngModelChange)="onTypeChange($event)"></app-control>
                                    <app-control *ngIf="!canEditPermitType" [isDisabled]="true" label='Permit Type' [(ngModel)]="model.typeId" [entity]="model" property="typeId"></app-control>
                                </div>
                                <div class="col" *ngIf="viewMode">
                                    <app-control label='Permit Status' [(ngModel)]="model.statusId" [entity]="model" property="statusId" ></app-control>
                                </div>
                                <div class="col">
                                    <app-control [isDisabled]="!isEditable" label='Location' [(ngModel)]="model.berthId" [entity]="model" property="berthId"></app-control>
                                </div>
                                <div class="col">
                                    <app-control [isDisabled]="!canEditDateTime" label='Validity From' [time]="true" [(ngModel)]="model.validFromDate" [entity]="model" property="validFromDate"></app-control>
                                </div>
                                <div class="col">
                                    <app-control [isDisabled]="!canEditDateTime" label='Validity To' [time]="true" [(ngModel)]="model.validToDate" [entity]="model" property="validToDate"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <app-control [isDisabled]="!this.user.isSystemUser || !isEditable" label='Requesting Agency or Company'
                                        [initialValue]="user.organizationId" type="codelist" codelist="Organization" [(ngModel)]="model.requestingOrganizationId" [entity]="model" property="requestingOrganizationId"></app-control>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <app-control [isDisabled]="!isEditable" label='Description of proposed works'
                                        type="textarea" [(ngModel)]="model.proposedWorks" [entity]="model" property="proposedWorks"></app-control>
                                </div>
                                <div class="col" *ngIf="model.transportMaltaRemarks">
                                    <app-control [isDisabled]="true" label='Remarks by Transport Malta'
                                        type="textarea" [(ngModel)]="model.transportMaltaRemarks" [entity]="model" property="transportMaltaRemarks"></app-control>
                                </div>
                            </div>

                            <div class="row" *ngIf="isDangerousCargo">
                                <div class="col">
                                    <app-control [isDisabled]="!isEditable" label='Number of containers in question'
                                        type="textarea" [(ngModel)]="model.numberOfContainers" [entity]="model" property="numberOfContainers"></app-control>
                                </div>
                            </div>

                            <div class="row mt-3" *ngIf="canEditDescriptions()">
                                <div class="col">
                                    <kendo-editor *ngIf="editMode" [iframe]="false" [(ngModel)]="model.description" style="height: 370px;"></kendo-editor>
                                    <div class="card" *ngIf="!editMode">
                                        <div class="card-body">
                                            <span [innerHtml]="model.description"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3" *ngIf="canEditDescriptions()">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <label class="col-form-label" for="">{{'Remarks' | translate}}</label>
                                        </div>
                                    </div>
                                    <kendo-editor *ngIf="editMode" [iframe]="false" [(ngModel)]="model.remarks" style="height: 370px;"></kendo-editor>
                                    <div class="card" *ngIf="!editMode">
                                        <div class="card-body">
                                            <span [innerHtml]="model.remarks"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3" *ngIf="canEditDescriptions() && this.model.typeId !== 5"> <!-- LBP -->
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <label class="col-form-label" for="">{{'Charge' | translate}}</label>
                                        </div>
                                    </div>
                                    <kendo-editor *ngIf="editMode" [iframe]="false" [(ngModel)]="model.chargeDescription" style="height: 370px;"></kendo-editor>
                                    <div class="card" *ngIf="!editMode">
                                        <div class="card-body">
                                            <span [innerHtml]="model.chargeDescription"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row mt-3" *ngIf="canEditDescriptions() && model.chargeDescription2">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <label class="col-form-label" for="">{{'Second Charge' | translate}}</label>
                                        </div>
                                    </div>
                                    <kendo-editor *ngIf="editMode" [iframe]="false" [(ngModel)]="model.chargeDescription2" style="height: 370px;"></kendo-editor>
                                    <div class="card" *ngIf="!editMode">
                                        <div class="card-body">
                                            <span [innerHtml]="model.chargeDescription2"></span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="attachment-info">
                    <ngb-panel id="attachment-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Attachments</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-entity-form [options]="formOptions" [grid]="attachmentGrid"></app-entity-form>
                            <hr class="my-3"/>
                            <div class="mb-3">
                                <span class="h3">{{"Attachments"|translate}}</span>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-grid #attachmentGrid [data]="model.attachments">
                                        <kendo-grid-column title="{{'Name' | translate}}">
                                            <ng-template let-row kendoGridCellTemplate>
                                                <span>{{ row.attachment?.name || row.name }}</span>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="remarks" title="{{'Remarks' | translate}}">
                                        </kendo-grid-column>
                                        <kendo-grid-column field="createdDate" title="{{'Created Date' | translate}}">
                                            <ng-template kendoGridCellTemplate let-row>
                                                {{row.createdDate | date:'short'}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="createdById" title="{{'Created By' | translate}}">
                                            <ng-template kendoGridCellTemplate let-row>
                                                {{row.createdById | codelist:row:'createdById'|async}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column [hidden]="editMode" width="100px" class="overflow-unset">
                                            <ng-template kendoGridCellTemplate let-row>
                                                <app-upload-action-buttons
                                                    [file]="row.attachment"
                                                    [disabled]="true"
                                                    [multiple]="false">
                                                </app-upload-action-buttons>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </app-grid>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </ng-template>
</app-loader>
