import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { EntityChangedEventArgs, EntityManager } from '@cime/breeze-client';
import { CallPurposeCodes } from '@common/breeze-models/call-purpose-codes';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-voyage',
    templateUrl: './vessel-notification-voyage.component.html',
    styleUrls: ['./vessel-notification-voyage.component.scss']
})
export class VesselNotificationVoyageComponent implements OnInit, AfterViewInit {
    entityManager: EntityManager;
    canceledText = 'ZZCAN - Cancelled';
    callPurposes: string[] = [];
    shortCallPurposes: string[] = [];
    defects = [];
    callActivities;
    prevPersonsOnBoard;
    otherSelected;

    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() viewMode: ViewMode;
    @Input() shortNotification = false;
    @Input() user: User;

    @ViewChild('callActivitiesControl', { static: false }) callActivitiesControl: AppControlComponent;
    @ViewChildren(AppControlComponent) appControls: AppControlComponent[];

    constructor(private breezeViewService: BreezeViewService) {
        this.entityManager = breezeViewService.entityManager;
    }

    async ngOnInit() {
        this.callPurposes = _.map(this.model.callPurposes, o => o.callPurposeId);
        this.shortCallPurposes = _.map(this.model.shortCallPurposes, o => o.callPurposeId);
        this.defects = _.map(this.model.defects, o => o.defectId);
        Promise.resolve().then(() => this.onCargoDataChange());
        this.callActivities = await this.breezeViewService.handleQuery('CallActivities');
        this.prevPersonsOnBoard = this.model.personsOnBoard;
        this.passengersChange();
        this.otherSelected = this.model.shortCallPurposes.some(x => x.callPurposeId === 40); // Id: 40 == Other

        this.entityManager.entityChanged.subscribe((entityData: EntityChangedEventArgs) => {
            if (['VesselNotificationDefect', 'VesselNotificationShortCallPurpose', 'VesselNotificationCallPurpose']
                .includes(entityData.entity.entityType.shortName)
                && entityData.entityAction.name === 'EntityStateChange') {
                this.callPurposes = this.model.callPurposes.map(a => a.callPurposeId);
                this.shortCallPurposes = this.model.shortCallPurposes.map(a => a.callPurposeId);
                this.defects = this.model.defects.map(a => a.defectId);
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.appControls.forEach(ac => ac.isDisabled ||= !this.canEdit || this.user?.isSecurity()));
    }

    onPortOfCallChange($event) {
        // Reset properties because of filter rule
        ['portFacilityId', 'berthId', 'terminalOperatorId', 'berthTypeId'].forEach(prop => this.model[prop] = null);
    }

    onPrimaryCallPurposesChange(value) {
        this.model.callActivityId = null;
        this.callActivitiesControl?.applyFilter();
    }

    onCallPurposesChange(ids) {
        const collectionName = this.model.isShort ? 'shortCallPurposes' : 'callPurposes';
        const entityName = this.model.isShort ? 'VesselNotificationShortCallPurpose' : 'VesselNotificationCallPurpose';
        this.breezeViewService.onMultiSelectChange(ids, this.model, collectionName, 'callPurposeId', entityName, 'vesselNotificationId');
        this.otherSelected = this.model.shortCallPurposes.some(x => x.callPurposeId === 40); // Id: 40 == Other
    }

    onDefectsChange(ids) {
        this.breezeViewService.onMultiSelectChange(ids, this.model, 'defects', 'defectId', 'VesselNotificationDefect', 'vesselNotificationId');
    }

    callActivityFilter(item) {
        if (['EMB', 'DMB', 'E/D'].includes(item.code)) return [2, 4, 11].includes(this.model.primaryCallPurposeId);
        const itemCallPurposeId = _.find(this.callActivities, ca => ca.id === item.code)?.callPurposeId;
        return itemCallPurposeId === this.model.primaryCallPurposeId;
    }

    fetchBerths = (filter) => this.breezeViewService.handleQuery('Berths', { portId: this.model.portOfCallId, filter })
        .then(results => results.map(x => ({
            value: x.id,
            label: `${x.name}`
        })).sort((a, b) => (a.label < b.label ? -1 : 1)));

    onBerthChange(berthId) {
        if (berthId) {
            this.model.entityAspect.loadNavigationProperty('berth');
            this.model.portFacilityId = this.model.berth.portFacilityId;
        }
    }

    getCertificateTooltip(certificateName) {
        if (this.viewMode !== ViewMode.view) return '';
        return (this.model[certificateName]) ? 'Valid' : 'N/A or Expired';
    }

    getTooltipClass(certificateName) {
        return (this.model[certificateName]) ? '' : 'error-tooltip';
    }

    shouldShowCertificates(certificate: string): boolean {
        if (this.shortNotification && ['civilLiabilityCertificate', 'maritimeLabourConventionCertificate', 'internationalOilPollutionPrevention', 'insuranceForMaritimeClaims', 'bunkerOilPollutionDamage'].includes(certificate))
            return false;

        if (this.model.notificationTypeId === 'A' || !this.model.confirmSubmittedCertificatesOnArrival)
            return true;

        return this.model.notificationTypeId === 'D' && !!this.model.confirmSubmittedCertificatesOnArrival && (!this.model[certificate] && !!this.model[`${certificate}Remarks`]);
    }

    get isEditable() {
        return this.viewMode !== ViewMode.view && !this.model.passengers.length && !this.model.workers.length && !this.model.numberOfPax && !this.model.numberOfCrew;
    }

    passengersChange(param: string = null) {
        this.model.personsOnBoard = this.isEditable && !!this.model[param]
            ? (this.prevPersonsOnBoard || 0) + this.model.numberOfStowaways
            : (this.model.passengers.length || this.model.numberOfPax) + (this.model.workers.length || this.model.numberOfCrew) + this.model.numberOfStowaways;
    }

    personsChange() {
        this.prevPersonsOnBoard = this.model.personsOnBoard;

        // Change application delay
        setTimeout(() => {
            if (this.model.personsOnBoard < this.model.numberOfStowaways)
                this.model.numberOfStowaways = this.model.personsOnBoard;
        }, 500);
    }

    onCargoDataChange() {
        const value = `${this.model.cargoDescription || ''} (${this.model.cargoGrossWeight}kg)`;
        if (value !== this.model.cargoVolumeNature && (this.model.preArrival || this.model.preArrivalSentDate))
            this.model.cargoVolumeNature = value;
    }

    disableAppControls() {
        if (!this.canEdit)
            setTimeout(() => this.appControls.forEach(ac => ac.isDisabled ||= !this.canEdit));
    }
}
