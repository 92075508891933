import { Component, Input } from '@angular/core';
import { User } from '@common/models/User';
import { ViewMode } from '@common/models/view-mode';
import { DialogService } from '@common/services/dialog.service';
import { CreateGeneralRemarkModalComponent } from '../create-general-remark-modal/create-general-remark-modal.component';

@Component({
    selector: 'app-vessel-notification-general-remarks',
    templateUrl: './vessel-notification-general-remarks.component.html',
    styleUrls: ['./vessel-notification-general-remarks.component.scss']
})
export class VesselNotificationGeneralRemarksComponent {
    @Input() model: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;
    @Input() user: User;

    constructor(private dialogService: DialogService) { }

    get remarksList() {
        return this.model.generalRemarks.filter(r => r.private === false || r.privatedById === this.user.id);
    }

    async editRemark(remark = null) {
        const viewMode = remark ? ViewMode.edit : ViewMode.create;
        const res = await this.dialogService.open(CreateGeneralRemarkModalComponent, { size: 'xl' }, dialogRef => {
            dialogRef.componentInstance.userId = this.user.id;
            dialogRef.componentInstance.mode = viewMode;
            if (viewMode === ViewMode.create)
                dialogRef.componentInstance.vesselNotificationId = this.model.id;
            else dialogRef.componentInstance.editedRemark = remark;
        });
        if (!res.result || viewMode !== ViewMode.create) return;
        res.data.remark.entityAspect.setDetached();
        this.model.generalRemarks.push(res.data.remark);
    }

    removeRemark(remark) {
        remark.entityAspect.setDeleted();
    }
}
