import { Component, Input } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatNoteModalComponent } from '../chat-note-modal/chat-note-modal.component';

@Component({
    selector: 'app-chat-notes-list',
    templateUrl: './chat-notes-list.component.html',
    styleUrls: ['./chat-notes-list.component.scss'],
    providers: [BreezeViewService, NgbActiveModal]
})
export class ChatNotesListComponent extends AbstractBreezeListComponent {
    queryName = 'ChatNotes';
    persistFilter = false;
    @Input() vesselNotificationId: number;
    @Input() isModal = false;

    breadcrumb = [
        {
            icon: 'comments',
            title: 'Chat Notes'
        }
    ];

    constructor(protected breezeViewService: BreezeViewService, private dialogService: DialogService, public activeModal: NgbActiveModal) {
        super(breezeViewService);
        this.actionBar[0].items[0].onClick = () => this.openNoteModal(null);
    }

    getDefaultFilter() {
        return {
            vesselNotificationId: this.vesselNotificationId
        };
    }

    openNoteModal(note) {
        this.dialogService.open(ChatNoteModalComponent, {
            windowClass: 'full-screen-modal',
            scrollable: true,
        }, dialogRef => {
            dialogRef.componentInstance.vesselNotificationId = this.vesselNotificationId;
            if (note) dialogRef.componentInstance.parentNote = note;
            else dialogRef.componentInstance.addNewNote();
        }).then(this.search);
    }

    close() {
        this.activeModal.close();
    }
}
