import { Component } from '@angular/core';
import { EntityQuery } from '@cime/breeze-client';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';

@Component({
    selector: 'app-detained-vessel-attachment-list-modal',
    templateUrl: './detained-vessel-modal.component.html',
    styleUrls: ['./detained-vessel-modal.component.scss'],
    providers: [BreezeViewService]
})
export class DetainedVesselAttachmentListModalComponent extends AbstractBreezeViewComponent {
    entityName = 'DetainedVessel';
    vesselId;
    commandName: string;
    customTitle: string;
    remarks: string;
    vesselActionById: string;
    isBan: boolean;
    isDetain: boolean;
    isRelease: boolean;

    constructor(breezeViewService: BreezeViewService,
        public activeModal: NgbActiveModal) {
        super(breezeViewService);
    }

    override modelLoaded() {
        super.modelLoaded();
        this.isBan = this.commandName === 'Ban' || this.model.actions.some(x => x.bannedDate);
        this.isDetain = this.commandName === 'Detain' || this.model.actions.some(x => x.detainedDate);
        this.isRelease = this.commandName === 'Release';
    }

    attachmentsOptions: EntityFormOptions = {
        entityName: 'DetainedVesselAttachment',
        getCollection: () => this.model.attachments,
        beforeAdd: (data) => {
            data.createdById = this.user?.id;
            data.createdDate = new Date();
        },
        canEdit: () => true,
        propertyGroups: [
            [
                { name: 'remarks', label: 'Description', type: AppControlType.String, colSize: 4 },
                { name: 'attachment', label: 'Attachment', type: AppControlType.File, colSize: 4 }
            ]
        ]
    };

    vesselActionOptions: EntityFormOptions = {
        entityName: 'DetainedVesselAction',
        getCollection: () => this.model.actions,
        canEdit: () => true,
        canAdd: () => !this.isRelease,
        canRemove: () => !this.isRelease,
        beforeAdd: (data) => {
            data.createdById = this.user?.id;
            data.createdDate = new Date();
        },
        onAdd: (data) => {
            if (this.isBan) data.bannedDate = new Date();
            else if (this.isDetain) data.detainedDate = new Date();
        },
        onUpdate: (data) => {
            if (this.isRelease && !data.releasedDate) {
                data.releasedDate = new Date();
                data.releasedById = data.detainedById || data.bannedById;
            }
        },
        propertyGroups: [
            [
                {
                    name: 'bannedById', label: 'Banned By',
                    type: AppControlType.CodeList, codelist: 'VesselActionBy',
                    colSize: 4, isVisible: () => this.isBan, isDisabled: () => this.isRelease
                },
                {
                    name: 'banRemarks', label: 'Ban Remarks',
                    type: AppControlType.String, maxlength: 500,
                    colSize: 4, isVisible: () => this.isBan, isDisabled: () => this.isRelease
                },
                {
                    name: 'detainedById', label: 'Detained By',
                    type: AppControlType.CodeList, codelist: 'VesselActionBy',
                    fetch: (search) => this.fetchVesselActionBy(search, true),
                    colSize: 4, isVisible: () => this.isDetain, isDisabled: () => this.isRelease
                },
                {
                    name: 'detainRemarks', label: 'Detain Remarks',
                    type: AppControlType.String, maxlength: 500,
                    colSize: 4, isVisible: () => this.isDetain, isDisabled: () => this.isRelease
                },
                {
                    name: 'releaseRemarks', label: 'Release Remarks',
                    type: AppControlType.String, maxlength: 500,
                    colSize: 4, isVisible: () => this.isRelease
                }
            ],
        ]
    };

    fetchVesselActionBy(filter, detainFilter = false) {
        return this.breezeViewService.handleQuery('VesselActionBy', { filter, detainFilter })
            .then(results => this.fetchResultHandler(results, x => `${x.id} - ${x.name}`));
    }

    override getQuery() {
        return EntityQuery.from(this.entityName)
            .withParameters({ id: this.vesselId });
    }

    canConfirm() {
        return this.hasChanges() && this.model.actions.length > 0;
    }

    async confirm() {
        await this.saveChanges({
            redirectToList: false, redirectToViewMode: false,
            tag: { statusId: this.getStatus(), vesselId: this.vesselId }
        });
        this.activeModal.close(true);
    }

    close() {
        this.activeModal.close(false);
    }

    getStatus() {
        switch (this.commandName) {
            case 'Ban':
                return 'BN';
            case 'Detain':
                return 'DT';
            case 'Release':
                return 'AC';
            default:
                return '';
        }
    }
}
