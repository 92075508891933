import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BreezeViewService } from '@common/services/breeze-view.service';

@Component({
    selector: 'app-change-agency-modal',
    templateUrl: './change-agency-modal.component.html',
    styleUrls: ['./change-agency-modal.component.scss'],
    providers: [BreezeViewService]
})
export class ChangeAgencyModalComponent {
    model = {
        organizationFromId: null,
        organizationToId: null,
        moduleTypeId: null
    };

    changeAgenciesList = [];
    agentId: number;
    statusId: string;

    constructor(public activeModal: NgbActiveModal,
        private breezeViewService: BreezeViewService) { }

    changeFromOrg(event) {
        if (!event) return;
        const existingChange = this.changeAgenciesList.find(ca => ca.moduleTypeId === event);
        this.model.organizationFromId = existingChange ? existingChange.organizationToId : this.agentId;
    }

    fetchModuleTypes = () => this.breezeViewService.handleQuery('ModuleTypes', {
        changeAgenciesListIds: this.changeAgenciesList.map(({ id }) => id),
        statusId: this.statusId
    }).then(results => results.map((x: any) => ({ value: x.id, label: `${x.id} - ${x.name}` })));

    close() {
        this.activeModal.close();
    }

    canConfirm() {
        return Object.values(this.model).every(x => x) && this.model.organizationFromId !== this.model.organizationToId;
    }

    confirm() {
        this.activeModal.close(this.model);
    }
}
