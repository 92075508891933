import { Component, OnInit } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { PrintService } from '@common/services/print.service';

@Component({
    selector: 'app-vessel-permit-print',
    templateUrl: './vessel-permit-print.component.html',
    styleUrls: ['./vessel-permit-print.component.scss'],
    providers: [BreezeViewService]
})
export class VesselPermitPrintComponent extends AbstractBreezeListComponent implements OnInit {
    queryName = 'VesselPermits';
    persistFilter = false;
    parentRoute = 'vessel-permit';
    vesselPermitIds: number[];
    vesselPermits: any[];

    constructor(public breezeViewService: BreezeViewService, private printService: PrintService) {
        super(breezeViewService);
        this.vesselPermitIds = this.breezeViewService.activatedRoute.snapshot.params.ids.split(',').map(id => +id);
    }

    getDefaultFilter(data: {}) {
        return { ids: this.vesselPermitIds };
    }

    search() {
        const query = this.getQuery();
        this.entityManager.executeQuery(query)
            .then((response) => {
                const results = response.results.filter(x => this.vesselPermitIds.includes(x.id));
                this.vesselPermits = results;
                this.printService.onPrintReady();
            })
            .catch(e => console.error(e));
        return query;
    }


}
