import {
    AfterViewInit, ApplicationRef,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input, NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList, Renderer2,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {
    ColumnBase,
    DataStateChangeEvent,
    FilterableSettings,
    GridComponent, GroupableSettings,
    ScrollMode,
    SelectionEvent,
    SortSettings,
    DetailTemplateDirective, PagerSettings, SelectableSettings, RowClassArgs, ColumnComponent, CommandColumnComponent, PageChangeEvent
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid/dist/es2015/data/data.collection';
import { State } from '@progress/kendo-data-query';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { EntityManager, EntityQuery, FilterQueryOp, Predicate, ValidationError, NavigationProperty, Entity } from '@cime/breeze-client';
import _ from 'lodash';
import { environment } from '@environments/environment';
import { BreezeViewService } from '@common/services/breeze-view.service';

type DataFunction = (state: State) => EntityQuery | any[]; // Promise<any[]>

@Component({
    selector: 'app-grid',
    templateUrl: './app-grid.component.html',
    styleUrls: ['./app-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    protected destroy$ = new Subject<boolean>();
    private rendered = false;
    private deferredColumns: { column: ColumnBase; index?: number }[] = [];
    private dragAndDropSubscription: Subscription;
    private _tooltip;
    private entitySubscription;
    private parentEntity: Entity;
    private navigationProperty: NavigationProperty;
    public id = _.uniqueId('app-grid-');
    public showTooltip = environment.settings.validation.errors.showTooltip;
    private unsubscribe = _.noop;

    internalData: Array<any> | GridDataResult | any;
    entityManager: EntityManager;
    state: State = {
        skip: 0,
        take: environment.settings.grid.pageSize
    };
    errors: ValidationError[] = [];

    onDataStateChanged: (event?: DataStateChangeEvent) => void;

    @ContentChildren(ColumnBase) protected columns = new QueryList<ColumnBase>();
    @ContentChildren(DetailTemplateDirective) public detailTemplateChildren: QueryList<DetailTemplateDirective>;

    @ViewChild(GridComponent) protected grid: GridComponent;
    @ViewChildren(CommandColumnComponent) protected commandColumns: Array<CommandColumnComponent>;

    @Input() data: Array<any> | GridDataResult | EntityQuery | DataFunction;
    @Input() sortable = environment.settings.grid.sortable as SortSettings;
    @Input() sort = [...environment.settings.grid.sort] as SortDescriptor[];
    @Input() selectable: boolean | SelectableSettings;
    @Input() scrollable: ScrollMode = 'scrollable';
    @Input() filterable: FilterableSettings;
    @Input() groupable: GroupableSettings;
    @Input() selectBy = 'id';
    @Input() pageSize: number;
    @Input() height: number;
    @Input() selection = [];
    @Input() pageable = environment.settings.grid.pageable as PagerSettings;
    @Input() onRowSwap;
    @Input() stickyHeader: boolean;
    @Input() rowClass = () => _.noop();
    @Input() isBusy = false;

    @Output() stateChange = new EventEmitter<State>();
    @Output() selectionChange = new EventEmitter<SelectionEvent>();
    @Output() pageChange = new EventEmitter<PageChangeEvent>();

    @ViewChild('tooltip', { static: false })
    set tooltip(value) {
        this._tooltip = value;
        if (value) this.showValidationErrors();
    }

    get tooltip() {
        return this._tooltip;
    }

    public get detailTemplate(): DetailTemplateDirective {
        if (this._customDetailTemplate) return this._customDetailTemplate;

        return this.detailTemplateChildren?.first;
    }

    private get fields() {
        return this.columns.filter(x => x instanceof ColumnComponent).map((x: ColumnComponent) => x.field).filter(x => x);
    }

    public set detailTemplate(detailTemplate: DetailTemplateDirective) {
        this._customDetailTemplate = detailTemplate;
    }

    private _customDetailTemplate: DetailTemplateDirective;
    private _sub;

    constructor(protected changeDetectorRef: ChangeDetectorRef,
        private breezeViewService: BreezeViewService,
        private renderer: Renderer2,
        private applicationRef: ApplicationRef,
        private zone: NgZone) {
        this.entityManager = breezeViewService.entityManager;
    }

    ngOnInit() {
        if (this.pageSize) this.state.take = this.pageSize;
        this.state.sort = this.sort;
        this.trySetupBreezeArray();
    }

    ngOnDestroy() {
        this.destroy$.next(false);
        this.destroy$.complete();

        this.unsubscribe();
        if (this.isDragAndDropEnabled()) this.dragAndDropSubscription.unsubscribe();

        if (this.entitySubscription) this.parentEntity.entityAspect.validationErrorsChanged.unsubscribe(this.entitySubscription);
    }

    private isDragAndDropEnabled() {
        return this.breezeViewService.isEditMode() && _.isFunction(this.onRowSwap);
    }

    private trySetupBreezeArray() {
        if (this.entitySubscription) {
            this.parentEntity.entityAspect.validationErrorsChanged.unsubscribe(this.entitySubscription);
            this.entitySubscription = null;
        }

        this.parentEntity = _.isArray(this.data) ? (<any>this.data)?.parentEntity : null;
        if (this.parentEntity) {
            this.navigationProperty = (<any>this.data).navigationProperty;
            this.fillValidationErrors();
            this.entitySubscription = this.parentEntity.entityAspect.validationErrorsChanged.subscribe(() => {
                this.fillValidationErrors();
                this.showValidationErrors();
            });
        } else {
            this.navigationProperty = null;
        }
    }

    private fillValidationErrors() {
        const validationErrors = _.filter(this.parentEntity.entityAspect.getValidationErrors(),
            (validatorError: ValidationError) => validatorError.propertyName === this.navigationProperty.name);

        this.errors = validationErrors;
    }

    private showValidationErrors() {
        if (this.errors.length > 0) {
            this.tooltip.ngbTooltip = _.map(this.errors, x => x.errorMessage).join('\n');
            if (this.showTooltip) this.tooltip.open();
        } else {
            this.tooltip.ngbTooltip = null;
            this.tooltip.close();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isBusy) this.isBusy = changes.isBusy.currentValue;
        if (!changes.data) return;

        const total = changes.data.currentValue?.total;
        if (!total || total < this.state.skip) this.state.skip = 0;

        // OnChanges triggers before OnInit (done for VtsEventsGrid)
        if (this.pageSize) this.state.take = this.pageSize;

        if (changes.sort) this.state.sort = this.sort;

        if (_.isFunction(this.data)) {
            this.onDataStateChanged = this.functionDataStateChanged;
        } else if (_.isArray(this.data)) {
            const array = this.data as any[];
            this.onDataStateChanged = (state) => this.arrayDataStateChanged(array, state);

            this.trySetupBreezeArray();
            this.unsubscribe();

            if ((this.data as any).arrayChanged) {
                this._sub = (this.data as any).arrayChanged.subscribe(() => this.onDataStateChanged(this.state as any));

                this.unsubscribe = () => {
                    (array as any).arrayChanged.unsubscribe(this._sub);
                    this.unsubscribe = _.noop;
                };
            }
        } else if (this.data instanceof EntityQuery) {
            const query = this.data as EntityQuery;
            this.onDataStateChanged = (state) => this.queryDataStateChanged(query as EntityQuery, state);

        } else if (this.data instanceof Object) {
            this.onDataStateChanged = (state) => this.objectDataStateChanged(this.data as GridDataResult, state);
            this.internalData = this.data;
            return;
        }

        if (this.data) this.onDataStateChanged();
        else this.internalData = [];
    }

    ngAfterViewInit() {
        if (this.isDragAndDropEnabled()) this.dragAndDropSubscription = this.handleDragAndDrop();

        this.rendered = true;
        this.grid.detailTemplate = this.detailTemplate;

        _.each(this.deferredColumns, args => this.addColumn(args.column, args.index));
        this.deferredColumns.length = 0;

        this.updateColumns();

        this.columns.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateColumns());
    }

    search() {
        this.onDataStateChanged();
    }

    addColumn(column: ColumnBase, index?: number) {
        if (!this.rendered) {
            this.deferredColumns.push({ column, index });
            return;
        }

        const columns = this.columns.toArray();
        if (index === undefined) columns.push(column);
        else columns.splice(index, 0, column);

        this.columns.reset(columns);
    }

    updateColumns() {
        this.grid.columns.reset(this.columns.toArray());
        this.changeDetectorRef.detectChanges();
    }

    private arrayDataStateChanged(array: any[], state: DataStateChangeEvent) {
        if (state) {
            this.state = state;
            this.stateChange.emit(state);
        }

        try {
            this.isBusy = true;
            this.internalData = process(array, {
                ...this.state,
                take: !this.pageable ? null : this.state.take,
            });

            if (this.isDragAndDropEnabled() && this.dragAndDropSubscription) {
                this.dragAndDropSubscription.unsubscribe();
                this.zone.onStable.pipe(take(1))
                    .subscribe(() => this.dragAndDropSubscription = this.handleDragAndDrop());
            }
        }
        catch (e) { console.error(e); }
        finally { this.isBusy = false; }
    }

    private objectDataStateChanged(dataObject: GridDataResult, state: DataStateChangeEvent) {
        if (state) {
            this.state = state;
            this.stateChange.emit(state);
        }
        this.internalData = process(dataObject.data, { ...this.state });
    }

    private functionDataStateChanged(state: DataStateChangeEvent) {
        const result = (this.data as DataFunction)(state);

        if (_.isArray(result)) {
            this.arrayDataStateChanged(result, state);
        } else if (result instanceof EntityQuery) {
            this.queryDataStateChanged(result, state);
        } else {
            throw new Error(`Unsupported data type: ${typeof result}`);
        }
    }

    private queryDataStateChanged(query: EntityQuery, state: DataStateChangeEvent) {
        if (state) this.state = state;

        try {
            this.isBusy = true;

            query = query.inlineCount(true);
            query = query.skip(this.state.skip);
            query = query.take(this.state.take);

            if (this.state.sort && this.state.sort.filter(x => x.dir).length > 0)
                query = query.orderBy(this.state.sort.filter(x => x.dir).map(x => `${x.field} ${x.dir}`).join(', '));

            const predicate = this.getCompositeFilterPredicate(this.state.filter);
            if (predicate) query = query.where(predicate);

            this.entityManager.executeQuery(query)
                .then((response) => {
                    this.internalData = {
                        total: response.inlineCount || response.results.length,
                        data: response.results
                    };
                    this.isBusy = false;
                }).catch(e => {
                    console.error(e);
                    this.isBusy = false;
                });
        } catch (e) {
            console.error(e);
            this.isBusy = false;
        }
    }

    private getCompositeFilterPredicate(filter: CompositeFilterDescriptor): Predicate {
        if (!filter) return null;

        const predicates = filter.filters.map((x) => (x as CompositeFilterDescriptor).filters ?
            this.getCompositeFilterPredicate(x as CompositeFilterDescriptor) : this.getFilterPredicate(x as FilterDescriptor));

        if (predicates.length === 0) return null;
        if (predicates.length === 1) return predicates[0];
        const logic = filter.logic === 'and' ? Predicate.and : Predicate.or;

        return logic(predicates);
    }

    private getFilterPredicate({ field, operator, value }: FilterDescriptor) {
        switch (operator) {
            case 'isnotnull': // NotNull
                return Predicate.create(field, FilterQueryOp.NotEquals, null);
            case 'isnull': // IsNull
                return Predicate.create(field, FilterQueryOp.Equals, null);
            case 'eq': // Equal
                return Predicate.create(field, FilterQueryOp.Equals, value);
            case 'neq': // NotEqual
                return Predicate.create(field, FilterQueryOp.NotEquals, value);
            case 'lt': // LessThan
                return Predicate.create(field, FilterQueryOp.LessThan, value);
            case 'lte': // LessOrEqual
                return Predicate.create(field, FilterQueryOp.LessThanOrEqual, value);
            case 'gt': // GreaterThan
                return Predicate.create(field, FilterQueryOp.GreaterThan, value);
            case 'gte': // GreaterOrEqual
                return Predicate.create(field, FilterQueryOp.GreaterThanOrEqual, value);
            case 'contains': // Contains
                return Predicate.create(field, FilterQueryOp.Contains, value);
            case 'doesnotcontain': // NotContain
                return Predicate.not(Predicate.create(field, FilterQueryOp.Contains, value));
            case 'startswith': // StartsWith
                return Predicate.create(field, FilterQueryOp.StartsWith, value);
            case 'endswith': // EndsWith
                return Predicate.create(field, FilterQueryOp.EndsWith, value);
            case 'isempty':
                return Predicate.create(field, FilterQueryOp.Equals, '');
            case 'isnotempty':
                return Predicate.create(field, FilterQueryOp.NotEquals, '');
            default:
                throw new Error(`Unknown operator for remoteFilter - ${operator}`);
        }
    }

    onSelectionChange(event: SelectionEvent) {
        this.selectionChange.emit(event);
    }

    getGrid() {
        return this.grid;
    }

    private handleDragAndDrop(): Subscription {
        const sub = new Subscription(() => {});
        let dragIndex;
        let dropIndex;
        let dragTr;
        let dropTr;
        const closestTr = (node) => {
            while (node && node.tagName.toLowerCase() !== 'tr') node = node.parentNode;
            return node;
        };

        const tableRows = Array.from(document.querySelectorAll(`#${this.id} tbody tr`));
        const tableRows2 = Array.from(document.querySelectorAll(`#${this.id}`));

        tableRows.forEach(item => {
            this.renderer.setAttribute(item, 'draggable', 'true');
            const dragStart = fromEvent<DragEvent>(item, 'dragstart');
            const dragOver = fromEvent(item, 'dragover');
            const dragEnd = fromEvent(item, 'dragend');

            sub?.add(dragStart.pipe(
                tap(({ dataTransfer }) => {
                    try {
                        const dragImgEl = document.createElement('span');
                        dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
                        document.body.appendChild(dragImgEl);
                        dataTransfer.setDragImage(dragImgEl, 0, 0);
                    } catch (err) {
                        console.error(err);
                        // IE doesn't support setDragImage
                    }
                    try {
                        // Firefox won't drag without setting data
                        dataTransfer.setData('application/json', '');
                    } catch (err) {
                        console.error(err);
                        // IE doesn't support MIME types in setData
                    }
                })
            ).subscribe(({ target }) => {
                dragTr = closestTr(target);
                this.renderer.addClass(dragTr, 'is-dragging');
                dragIndex = dragTr.rowIndex - 1;
            }));

            sub.add(dragOver.subscribe((e: any) => {
                e.preventDefault();
                if (!dragTr) return;
                dropTr = closestTr(e.target);
                tableRows.forEach(x => { this.renderer.removeClass(x, 'is-dropping'); });

                dropIndex = dropTr.rowIndex - 1;
                if (dropIndex !== dragIndex)
                    this.renderer.addClass(dropTr, 'is-dropping');
            }));

            sub.add(dragEnd.subscribe((e: any) => {
                e.preventDefault();
                tableRows.forEach(x => { this.renderer.removeClass(x, 'is-dropping'); });
                this.renderer.removeClass(dragTr, 'is-dragging');
                const draggedRow = this.internalData.data[dropIndex];
                const droppedRow = this.internalData.data[dragIndex];
                this.onRowSwap(draggedRow, droppedRow);
                dragTr = null;
                this.applicationRef.tick();
            }));
        });

        return sub;
    }
}
