import { Component, OnDestroy } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title" translate> Safety log-out </h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-auto text-danger">
                    <fa-icon [icon]="['fas', 'exclamation-circle']" [size]="'3x'"></fa-icon>
                </div>
                <div class="col" innerHTML="For your security you will be logged out automatically in {{timer}}s. Please save your work."></div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="closeModal()" translate>Dismiss</button>
        </div>
    `,
})
export class IdleWarningDialogComponent implements OnDestroy {
    intervalId: ReturnType<typeof setTimeout>;
    timer = 60;

    constructor(
        public activeModal: NgbActiveModal,
        private userService: UserService,
        private userIdleService: UserIdleService) {
        this.intervalId = setInterval(() => {
            if (--this.timer <= 0) this.logout();
        }, 1000);
    }

    closeModal() {
        this.activeModal.close();
        // Also dismiss modal on other app instances (other tabs/windows)
        new BroadcastChannel('IDLE_POPUP_CLOSED').postMessage(true);
    }

    logout() {
        this.userIdleService.stopWatching();
        this.userService.logout();
        new BroadcastChannel('IDLE_LOGOUT').postMessage(true);
        this.activeModal.close(null);
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);
    }
}
