import { Component, Input } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-notification-pilot',
    templateUrl: './vessel-notification-pilot.component.html',
    styleUrls: ['./vessel-notification-pilot.component.scss']
})
export class VesselNotificationPilotComponent {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationPilot',
        canEdit: () => this.canEdit && this.editMode,
        propertyGroups: [
            [
                { name: 'firstName', label: 'First Name', maxlength: 255 },
                { name: 'lastName', label: 'Last Name', maxlength: 255 },
                {
                    name: 'pilotOrganizationId',
                    label: 'Pilot Organization',
                    type: AppControlType.CodeList,
                    codelist: 'PilotOrganization'
                },
                {
                    name: 'pilotBoatId',
                    label: 'Pilot Boat',
                    type: AppControlType.CodeList,
                    codelist: 'PilotBoat'
                }
            ]
        ]
    };
}
