import { Component, EventEmitter, Output, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@common/models/User';
import { DialogService } from '@common/services/dialog.service';
import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() toggleSidebar = new EventEmitter();
    user: User;
    dark: boolean;
    environmentName: string;
    homeUrl = '/home';

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private userService: UserService,
        private renderer: Renderer2) {
        userService.currentUserSubject.subscribe((user) => {
            this.user = user;
            this.homeUrl =  user?.organizationId ? '/home' : '/task-manager';
        });
        this.environmentName = environment.name;
    }

    onToggleSidebar() {
        this.toggleSidebar.emit();
    }

    login() {
        return this.router.navigate(['/login']);
    }

    toggleDarkMode() {
        this.dark = !this.dark;
        if (this.dark) {
            this.renderer.removeClass(document.body, 'lightTheme');
            this.renderer.addClass(document.body, 'darkTheme');
            localStorage.setItem('dark', JSON.stringify(this.dark));
            return;
        }
        this.renderer.removeClass(document.body, 'darkTheme');
        this.renderer.addClass(document.body, 'lightTheme');
        localStorage.setItem('dark', JSON.stringify(this.dark));
    }

    logout() {
        this.dialogService.openConfirmDialog(this.translateService.instant('Sign Out') as string, this.translateService.instant('Are you sure you want to sign out?') as string)
            .then((result) => {
                if (result === true) this.userService.logout(false);
            });
    }

    ngOnInit() {
        if (JSON.parse(localStorage.getItem('dark'))) {
            document.getElementById('dark-theme-toggle').click();
        }
    }

    changeDisplayLanguage(language) {
        this.translateService.use(language);
    }
}
